import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { NotFoundCategoryImageIcon } from 'src/icons/NotFoundCategoryImageIcon';
import { MenuCategoryVm } from 'src/types/MenuCategoryVm';
import { useSelector } from 'src/utils/react/useSelector';

export function MenuCategory({ category, onClick }: Props): React.ReactElement {
    const classes = useStyles();

    const menuCategorySelected = useSelector((state) => state.app.menuCategorySelected);
    const restaurantColors = useSelector((state) => state.app.restaurant?.restaurantColors ?? []);

    const isCategorySelected = category.menuCategoryId === menuCategorySelected?.menuCategoryId;
    const selectedColor = isCategorySelected ? (restaurantColors[0] ?? '#64d6b3') : 'transparent';
    const textColor = isCategorySelected ? (restaurantColors[0] ?? '#64d6b3') : '#2C374A';

    return (
        <Button text classes={{ button: classes.container }} onClick={() => onClick(category.menuCategoryId)}>
            {category.imageUrl && <img src={category.imageUrl} alt='category' className={classes.image} style={{ border: `4px solid ${selectedColor}` }} />}
            {!category.imageUrl && (
                <div className={classes.noImageContainer} style={{ border: `4px solid ${selectedColor}` }}>
                    <NotFoundCategoryImageIcon title={'category'} />
                </div>
            )}
            <div className={classes.name} style={{ color: textColor }}>
                {category.name}
            </div>
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: theme.typography.semiBold,
        cursor: 'pointer',
        width: 150,
        overflow: 'hidden',
        marginBottom: 32,
        '&:focus': {
            margin: 0,
            border: 0,
            marginBottom: 32,
        },
    },
    image: {
        width: '100%',
        aspectRatio: 1,
        borderRadius: 12,
        pointerEvents: 'none',
        objectFit: 'cover',
    },
    noImageContainer: {
        borderRadius: 12,
        width: '100%',
        aspectRatio: 1,
        backgroundColor: '#e5e5e5',
        padding: 12,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
    },
    categorySelected: {
        backgroundColor: theme.palette.primary.main,
    },
    name: {
        fontSize: '2.8vw',
        maxWidth: '100%',
        marginTop: 0,
        lineHeight: 1.2,
        textAlign: 'center',
        fontFamily: theme.typography.bold,
        color: '#2C374A',
        wordBreak: 'normal',
    },
}));

type Props = {
    category: MenuCategoryVm;
    onClick: any;
};
