import { createSlice } from 'redux-starter-kit';
import { OrderVm } from 'src/api/pidedirectokiosk/types/OrderVm';
import { PaymentTerminalPaymentResultMock } from 'src/components/dialog/deprecated/MockPaymentTerminalResponseDialog';
import { Day, Days } from 'src/constants/Day';
import { OrderStep, OrderSteps } from 'src/constants/OrderStep';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import type { AppContextVm } from 'src/types/AppContextVm';
import type { CartItemVm } from 'src/types/CartItemVm';
import { CustomerEmployeeVm } from 'src/types/CustomerEmployeeVm';
import { DeviceGroupVm } from 'src/types/DeviceGroupVm';
import { CompanyId, MenuItemId, PromotionId, type EmailAddress, type QpayTerminalInstructionId, type RestaurantId } from 'src/types/Id';
import { MenuCategoryVm } from 'src/types/MenuCategoryVm';
import type { MenuItemVm } from 'src/types/MenuItemVm';
import { MenuVm } from 'src/types/MenuVm';
import type { OrderPaymentVm } from 'src/types/OrderPaymentVm';
import { PaymentTerminalVm } from 'src/types/PaymentTerminalVm';
import { PromotionVm } from 'src/types/PromotionVm';
import { RestaurantMenusVm } from 'src/types/RestaurantMenusVm';
import type { RestaurantVm } from 'src/types/RestaurantVm';
import { createCartItemKey } from 'src/utils/cart/createCartItemKey';
import { refreshPaymentState } from 'src/utils/cart/refreshPaymentState';
import { getItemFormatted } from 'src/utils/order/getItemFormatted';

export const appReducer = createSlice({
    name: 'appReducer',
    initialState: {
        showDeveloperModeAvailableLabel: false,
        developerMode: false,
        snackbar: {
            isOpen: false,
            title: '',
            message: '',
            actionText: undefined,
            vertical: 'bottom',
            horizontal: 'center',
            autoHideDuration: 4000,
            onActionClick: undefined,
            onClose: undefined,
            extendedAction: undefined,
            dontCloseOnClickAway: undefined,
        },
        restaurantId: undefined,
        remainingDays: undefined,
        subscriptionAccess: undefined,
        restaurant: undefined,
        orderType: undefined,
        payment: undefined,
        itemSelected: undefined,
        paymentMethod: undefined,
        orderPayment: undefined,
        qpayTerminalInstructionId: undefined,
        menuCategorySelected: undefined,
        kioskMenu: undefined,
        employeeNumber: '',
        firstName: '',
        lastName: '',
        paying: false,
        mobileNumber: '',
        email: undefined,
        sendTicketOnline: false,
        items: [],
        promotions: [],
        orders: undefined,
        customerEmployee: undefined,
        orderStep: OrderSteps.START,
        selectedDay: Days.MONDAY,
        selectedMenu: undefined,
        selectedMenuItemQuantity: 0,
        mockPaymentTerminalResponseDialog: {
            open: false,
            onSelectPaymentTerminalResponse: () => {},
            onCloseDialog: () => {},
        },
        selectPaymentTerminalDialog: {
            header: undefined,
            error: undefined,
            amount: undefined,
            open: false,
            onSelectPaymentTerminal: () => {},
            onCloseDialog: () => {},
        },
    } as AppState,
    reducers: {
        setShowDeveloperModeAvailableLabel: (state: AppState, { payload: showDeveloperModeAvailableLabel }: { payload: boolean }) => {
            state.showDeveloperModeAvailableLabel = showDeveloperModeAvailableLabel;
        },

        setDeveloperMode: (state: AppState, { payload: developerMode }: { payload: boolean }) => {
            state.developerMode = developerMode;
        },

        showSnackbar: (
            state: AppState,
            {
                payload: config,
            }: {
                payload: {
                    title: string;
                    message: string;
                    actionText?: string;
                    vertical?: 'bottom' | 'top';
                    horizontal?: 'center' | 'left' | 'right';
                    autoHideDuration?: number;
                    onActionClick?: Function;
                    onClose?: Function;
                    dontCloseOnClickAway?: boolean;
                };
            },
        ) => {
            state.snackbar = {
                ...state.snackbar,
                ...config,
                vertical: config.vertical ?? 'bottom',
                horizontal: config.horizontal ?? 'center',
                isOpen: true,
            };
        },

        hideSnackbar: (state: AppState) => {
            state.snackbar.isOpen = false;
            state.snackbar.message = '';
            state.snackbar.actionText = undefined;
            state.snackbar.vertical = 'bottom';
            state.snackbar.horizontal = 'center';
            state.snackbar.dontCloseOnClickAway = undefined;
        },

        selectKioskItem: (
            state: AppState,
            {
                payload: item,
            }: {
                payload: MenuItemVm;
            },
        ) => {
            state.itemSelected = item;
        },

        setAppContext: (
            state: AppState,
            {
                payload: appContext,
            }: {
                payload: AppContextVm;
            },
        ) => {
            state.restaurant = appContext.restaurant;
            state.restaurant.restaurantType = appContext.restaurantType;
            state.companyId = appContext.companyId;
            state.restaurantId = appContext.restaurantId;
            state.remainingDays = appContext.remainingDays;
            state.subscriptionAccess = appContext.subscriptionAccess;
            state.deviceGroup = appContext.deviceGroup;
            state.paymentTerminals = appContext.paymentTerminals;
        },

        setOrderStep: (
            state: AppState,
            {
                payload: kioskOrderStep,
            }: {
                payload: OrderStep;
            },
        ) => {
            state.orderStep = kioskOrderStep;
        },

        setKioskOrderType: (
            state: AppState,
            {
                payload: orderType,
            }: {
                payload: 'SELF_SERVICE_ORDER' | 'TAKE_AWAY_ORDER';
            },
        ) => {
            state.orderType = orderType;
            state.orderStep = OrderSteps.CREATE_ORDER;
        },

        addKioskItem: (
            state: AppState,
            {
                payload: item,
            }: {
                payload: CartItemVm;
            },
        ) => {
            const itemFormatted = getItemFormatted(item);

            const kioskItem = { ...itemFormatted, key: createCartItemKey(itemFormatted) };

            const existingKioskItem = state.items.find((current) => current.key === kioskItem.key);
            if (existingKioskItem) {
                existingKioskItem.quantity = existingKioskItem.quantity + 1;
            } else {
                state.items.push(kioskItem);
            }

            state.itemSelected = undefined;
            refreshPaymentState(state);
        },

        removeOneKioskItem: (
            state: AppState,
            {
                payload: item,
            }: {
                payload: CartItemVm;
            },
        ) => {
            const itemFormatted = getItemFormatted({ ...item, quantity: 1 });

            const kioskItem = { ...itemFormatted, key: createCartItemKey(itemFormatted) };

            const existingKioskItem = state.items.find((current) => current.key === kioskItem.key);
            if (!existingKioskItem) return;

            if (existingKioskItem.quantity === 1) {
                state.items = state.items.filter((item) => item.key !== existingKioskItem?.key);
            } else {
                existingKioskItem.quantity = existingKioskItem.quantity - 1;
            }

            state.itemSelected = undefined;
            refreshPaymentState(state);
        },

        removeKioskItem: (
            state: AppState,
            {
                payload: item,
            }: {
                payload: CartItemVm;
            },
        ) => {
            const itemFormatted = getItemFormatted({ ...item, quantity: 1 });

            const kioskItem = { ...itemFormatted, key: createCartItemKey(itemFormatted) };

            const existingKioskItem = state.items.find((current) => current.key === kioskItem.key);
            if (!existingKioskItem) return;

            state.items = state.items.filter((item) => item.key !== existingKioskItem?.key);

            state.itemSelected = undefined;
            refreshPaymentState(state);
        },

        openMockPaymentTerminalResponseDialog: (
            state: AppState,
            {
                payload: { onSelectPaymentTerminalResponse, onCloseDialog },
            }: { payload: { onSelectPaymentTerminalResponse: (mock: PaymentTerminalPaymentResultMock) => void; onCloseDialog: () => void } },
        ) => {
            state.mockPaymentTerminalResponseDialog.open = true;
            state.mockPaymentTerminalResponseDialog.onSelectPaymentTerminalResponse = onSelectPaymentTerminalResponse;
            state.mockPaymentTerminalResponseDialog.onCloseDialog = onCloseDialog;
        },

        closeMockPaymentTerminalResponseDialog: (state: AppState) => {
            state.mockPaymentTerminalResponseDialog.open = false;
            state.mockPaymentTerminalResponseDialog.onSelectPaymentTerminalResponse = () => {};
            state.mockPaymentTerminalResponseDialog.onCloseDialog = () => {};
        },

        openSelectPaymentTerminalDialog: (
            state: AppState,
            {
                payload: { header, error, amount, onSelectPaymentTerminal, onCloseDialog },
            }: { payload: { header?: string; error?: string; amount: string; onSelectPaymentTerminal: (paymentTerminal: PaymentTerminalVm) => void; onCloseDialog: () => void } },
        ) => {
            state.selectPaymentTerminalDialog.header = header;
            state.selectPaymentTerminalDialog.error = error;
            state.selectPaymentTerminalDialog.amount = amount;
            state.selectPaymentTerminalDialog.open = true;
            state.selectPaymentTerminalDialog.onSelectPaymentTerminal = onSelectPaymentTerminal;
            state.selectPaymentTerminalDialog.onCloseDialog = onCloseDialog;
        },

        closeSelectPaymentTerminalDialog: (state: AppState) => {
            state.selectPaymentTerminalDialog.header = undefined;
            state.selectPaymentTerminalDialog.error = undefined;
            state.selectPaymentTerminalDialog.amount = undefined;
            state.selectPaymentTerminalDialog.open = false;
            state.selectPaymentTerminalDialog.onSelectPaymentTerminal = () => {};
            state.selectPaymentTerminalDialog.onCloseDialog = () => {};
        },

        goToPreviousKioskOrderStep: (state: AppState) => {
            if (state.orderStep === OrderSteps.CREATE_ORDER) state.orderStep = OrderSteps.SELECT_ORDER_TYPE;
            if (state.orderStep === OrderSteps.PRODUCT_DETAIL) state.orderStep = OrderSteps.CREATE_ORDER;
            if (state.orderStep === OrderSteps.CHECKOUT) state.orderStep = OrderSteps.CREATE_ORDER;
            if (state.orderStep === OrderSteps.MODIFIERS) state.orderStep = OrderSteps.PRODUCT_DETAIL;
            if (state.orderStep === OrderSteps.CUSTOMER_INFO) state.orderStep = OrderSteps.CHECKOUT;
            if (state.orderStep === OrderSteps.SELECT_PAYMENT_METHOD) state.orderStep = OrderSteps.CUSTOMER_INFO;
            if (state.orderStep === OrderSteps.CARD_PAYMENT) state.orderStep = OrderSteps.SELECT_PAYMENT_METHOD;
            if (state.orderStep === OrderSteps.COMPLETED) state.orderStep = OrderSteps.SELECT_PAYMENT_METHOD;
        },

        setKioskPaymentMethod: (
            state: AppState,
            {
                payload: paymentMethod,
            }: {
                payload: PaymentMethod;
            },
        ) => {
            state.paymentMethod = paymentMethod;
        },

        setOrderPayment: (
            state: AppState,
            { payload: { paymentTerminalId, paymentTerminalPaymentId, paymentTerminalProvider, paymentMethod, amount, cardNumber, cardType } }: { payload: OrderPaymentVm },
        ) => {
            state.orderPayment = {
                paymentTerminalId,
                paymentTerminalPaymentId,
                paymentTerminalProvider,
                paymentMethod,
                amount,
                cardNumber,
                cardType,
            };
        },
        setKioskQpayTerminalInstructionId: (
            state: AppState,
            {
                payload: qpayTerminalInstructionId,
            }: {
                payload: QpayTerminalInstructionId;
            },
        ) => {
            state.qpayTerminalInstructionId = qpayTerminalInstructionId;
        },

        clearKiosk: (state: AppState) => {
            state.orderType = undefined;
            state.kioskMenu = undefined;
            state.payment = undefined;
            state.itemSelected = undefined;
            state.paymentMethod = undefined;
            state.menuCategorySelected = undefined;
            state.firstName = '';
            state.lastName = '';
            state.mobileNumber = '';
            state.email = undefined;
            state.sendTicketOnline = false;
            state.items = [];
            state.customerEmployee = undefined;
            state.orders = undefined;
            state.paying = false;
            state.orderPayment = undefined;
            state.orderStep = OrderSteps.START;
            state.selectedDay = Days.MONDAY;
            state.selectedMenu = undefined;
        },

        setKioskCustomerInfo: (
            state: AppState,
            {
                payload: { firstName, lastName, mobileNumber, email, sendTicketOnline, employeeNumber },
            }: {
                payload: {
                    firstName: string;
                    lastName: string;
                    employeeNumber?: string;
                    mobileNumber: string;
                    sendTicketOnline: boolean;
                    email?: EmailAddress;
                };
            },
        ) => {
            state.firstName = firstName;
            state.lastName = lastName;
            state.employeeNumber = employeeNumber;
            state.mobileNumber = mobileNumber;
            state.email = email;
            state.sendTicketOnline = sendTicketOnline;
            state.orderStep = OrderSteps.SELECT_PAYMENT_METHOD;
        },

        setKioskMenu: (
            state: AppState,
            {
                payload: kioskMenu,
            }: {
                payload: RestaurantMenusVm;
            },
        ) => {
            state.kioskMenu = kioskMenu;
        },

        selectMenuCategory: (
            state: AppState,
            {
                payload: menuCategory,
            }: {
                payload: MenuCategoryVm;
            },
        ) => {
            state.menuCategorySelected = menuCategory;
        },

        setSelectedDay: (state: AppState, { payload: day }: { payload: Day }) => {
            state.selectedDay = day;
        },

        setCustomerEmployee: (state: AppState, { payload: customerEmployee }: { payload: CustomerEmployeeVm }) => {
            state.customerEmployee = customerEmployee;
        },

        setOrders: (state: AppState, { payload: orders }: { payload: Array<OrderVm> }) => {
            state.orders = orders;
        },

        setSelectedMenu: (state: AppState, { payload: selectedMenu }: { payload: MenuVm }) => {
            state.selectedMenu = selectedMenu;
        },

        setPaying: (state: AppState, { payload: paying }: { payload: boolean }) => {
            state.paying = paying;
        },

        setPromotions: (state: AppState, { payload: promotions }: { payload: Array<PromotionVm> }) => {
            state.promotions = promotions;
        },

        setSelectedMenuItemQuantity: (state: AppState, { payload: selectedMenuItemQuantity }: { payload: number }) => {
            state.selectedMenuItemQuantity = selectedMenuItemQuantity;
        },
    },
});

export type AppState = {
    showDeveloperModeAvailableLabel: boolean;
    developerMode: boolean;
    snackbar: {
        isOpen: boolean;
        title: string;
        message: string;
        actionText?: string;
        vertical: 'bottom' | 'top';
        horizontal: 'center' | 'left' | 'right';
        autoHideDuration?: number;
        onActionClick?: Function;
        onClose?: Function;
        extendedAction?: Function;
        dontCloseOnClickAway?: boolean;
    };
    restaurantId: RestaurantId | undefined;
    remainingDays: number | undefined;
    subscriptionAccess: boolean | undefined;
    restaurant: RestaurantVm | undefined;
    orderType: 'SELF_SERVICE_ORDER' | 'TAKE_AWAY_ORDER' | undefined;
    items: Array<CartItemVm>;
    promotions: Array<PromotionVm>;
    orderStep: OrderStep;
    customerEmployee?: CustomerEmployeeVm;
    itemSelected: MenuItemVm | undefined;
    paymentMethod: PaymentMethod | undefined;
    qpayTerminalInstructionId?: QpayTerminalInstructionId;
    kioskMenu: RestaurantMenusVm | undefined;
    menuCategorySelected?: MenuCategoryVm;
    firstName: string;
    lastName: string;
    selectedDay?: Day;
    selectedMenu?: MenuVm;
    selectedMenuItemQuantity: number | undefined;
    employeeNumber: string | undefined;
    mobileNumber: string;
    orders?: Array<OrderVm>;
    email: EmailAddress | undefined;
    sendTicketOnline: boolean;
    paying: boolean;
    companyId?: CompanyId;
    payment:
        | {
              subtotal: string;
              productDiscount?: string;
              promotionsDiscount?: string;
              total: string;
              usedPromotions: Array<{
                  promotionId: PromotionId;
                  menuItemId: MenuItemId;
                  cartItemKey?: string;
              }>;
          }
        | undefined;
    orderPayment: OrderPaymentVm | undefined;
    deviceGroup?: DeviceGroupVm;
    paymentTerminals?: Array<PaymentTerminalVm>;
    mockPaymentTerminalResponseDialog: {
        open: boolean;
        onSelectPaymentTerminalResponse: (mock: PaymentTerminalPaymentResultMock) => void;
        onCloseDialog: () => void;
    };
    selectPaymentTerminalDialog: {
        header?: string;
        error?: string;
        amount?: string;
        open: boolean;
        onSelectPaymentTerminal: (paymentTerminal: PaymentTerminalVm) => void;
        onCloseDialog: () => void;
    };
};
