import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { Text } from 'src/components/app/Text';
import { translate } from 'src/i18n/translate';
import { MoneyIcon } from 'src/icons/MoneyIcon';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { useSelector } from 'src/utils/react/useSelector';
import { sum } from 'src/utils/reduce/sum';

export function PaymentSummary(): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const total = useSelector((state) => state.app.payment?.total);
    const days = useSelector((state) => state.app.customerEmployee?.days);

    const totalCredits = days?.reduce(
        (totalCredits, day) =>
            sum(
                totalCredits,
                BigNumber(day.gift?.credits ?? 0)
                    .minus(day.gift?.usedCredits ?? 0)
                    .toNumber(),
            ),
        BigNumber(0),
    );

    return (
        <div className={classes.card}>
            <MoneyIcon />
            <div>
                <Text size={'large'} variant={'bold'} className={classes.text}>
                    {formatAsCurrencyNumber(total)}
                </Text>
                <Text>{translate('Total to pay')}</Text>
            </div>
            <div className={classes.divider}></div>
            <div>
                <Text size={'large'} variant={'bold'} className={classes.text}>
                    {formatAsCurrencyNumber(totalCredits?.toNumber() ?? 0)}
                </Text>
                <Text>{translate('Available Credits')}</Text>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    card: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1vh 3vw',
        borderRadius: 20,
        minHeight: '30%',
        backgroundColor: '#F6F8F9',
        textAlign: 'center',
        gap: 50,
    },
    divider: {
        width: '90%',
        borderBottom: '2px solid #D9D9D9',
    },
    text: {
        color: '#000',
    },
}));
