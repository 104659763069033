import { AppBar, makeStyles, Toolbar } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button } from 'src/components/Button';
import { OrderSteps } from 'src/constants/OrderStep';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { useIsPickupStationUser } from 'src/services/useIsPickupStationUser';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { sum } from 'src/utils/reduce/sum';

export function Cart(): React.ReactElement {
    const classes = useStyles();
    const isPickupStationUser = useIsPickupStationUser();

    const [quantity, setQuantity] = useState(0);

    const items = useSelector((state) => state.app.items);
    const total = useSelector((state) => state.app.payment?.total);
    const orderStep = useSelector((state) => state.app.orderStep);

    const setOrderStep = useAction(appReducer.actions.setOrderStep);

    useEffect(() => {
        calculateQuantity();
    }, [items]);

    const calculateQuantity = () => {
        setQuantity(items?.reduce((totalItems, item) => sum(totalItems, item.quantity), BigNumber(0)).toNumber());
    };

    const handleContinue = () => {
        if (isPickupStationUser) {
            setOrderStep(OrderSteps.ORDER_SUMMARY);
            return;
        }

        setOrderStep(OrderSteps.CHECKOUT);
    };

    const handlePay = () => {
        if (!items?.length) return;
        setOrderStep(OrderSteps.CHECKOUT);
    };

    return (
        <AppBar position='fixed' color='primary' className={classes.bar}>
            <Toolbar>
                <div className={classes.container}>
                    <div>
                        <h1 className={classes.title}>{translate('My Order')}</h1>
                        {items.length !== 0 && (
                            <p className={classes.text}>
                                {translate('Total')} <strong>${total}</strong> / {translate('Items')}: <strong>{quantity}</strong>
                            </p>
                        )}
                    </div>
                    {!!items?.length && orderStep !== OrderSteps.ORDER_SUMMARY && (
                        <Button classes={{ button: classes.button }} onClick={handleContinue}>
                            {translate('Continue')}
                        </Button>
                    )}
                    {isPickupStationUser && orderStep === OrderSteps.ORDER_SUMMARY && (
                        <Button classes={{ button: classes.button }} onClick={handlePay} disabled={!items.length}>
                            {translate('Pay')}
                        </Button>
                    )}
                </div>
            </Toolbar>
        </AppBar>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        padding: '4vw 3vw',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    bar: {
        backgroundColor: theme.palette.primary.main,
        height: '15vh',
        borderTopRightRadius: 20,
        borderTopLeftRadius: 20,
        bottom: 0,
        left: 0,
        top: 'auto',
    },
    title: {
        fontSize: '6vw',
        margin: 0,
        marginBottom: '1vh',
        fontFamily: theme.typography.bold,
    },
    text: {
        fontSize: '4vw',
        margin: 0,
        fontFamily: theme.typography.regular,
    },
    button: {
        background: 'white',
        fontSize: '4vw',
        fontFamily: theme.typography.regular,
        color: theme.palette.primary.main,
        paddingTop: '1.5vh',
        paddingBottom: '1.5vh',
        paddingLeft: '2.5vh',
        paddingRight: '2.5vh',
        borderRadius: 12,
        fontWeight: 'bold',
    },
}));
