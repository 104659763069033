import * as React from 'react';

export function CheckOutlineIcon({ title, width, height, color }: Props): React.ReactElement {
    return (
        <svg width={width ?? '27'} height={height ?? '24'} viewBox='0 0 27 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <title>{title}</title>
            <path d='M2.3894 13.1118L11.2783 22.0007L24.6116 2.00073' stroke={color || 'currentColor'} strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    );
}

type Props = {
    title?: string;
    color?: string;
    width?: string;
    height?: string;
};
