import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import * as React from 'react';
import { useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { Input } from 'src/components/Input';
import { translate } from 'src/i18n/translate';
import { getError } from 'src/utils/form/getError';

export function FormPasswordField({
    name,
    label,
    placeholder,
    helperText,
    defaultValue = null,
    disabled,
    required,
    minLength,
    maxLength,
    pattern,
    validate,
    onClickShowPassword,
    tooltip,
    classes,
}: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const inputRef = useRef<HTMLInputElement>(null);
    const [showPassword, setShowPassword] = useState(false);

    return (
        <Controller
            render={({ value, onChange, ...props }) => {
                const { ref, ...restProps } = props;
                return (
                    <Input
                        {...restProps}
                        value={value}
                        type={showPassword ? 'text' : 'password'}
                        onChange={onChange}
                        label={label}
                        placeholder={placeholder}
                        helperText={getError(errors, name)?.message ?? helperText}
                        disabled={isSubmitting || disabled}
                        required={(required as any)?.value || required}
                        error={!!getError(errors, name)}
                        inputRef={inputRef}
                        endAdornment={
                            <Button
                                icon
                                onClick={() => {
                                    onClickShowPassword?.();
                                    setShowPassword(!showPassword);
                                }}
                            >
                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </Button>
                        }
                        tooltip={tooltip}
                        classes={classes}
                    />
                );
            }}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
                minLength: {
                    value: (minLength as any)?.value ?? minLength,
                    message: (minLength as any)?.message ?? translate('Password must be at least @number characters', { number: (minLength as any)?.value ?? minLength }),
                },
                maxLength: {
                    value: (maxLength as any)?.value ?? maxLength,
                    message: (maxLength as any)?.message ?? translate('Password must be at most @number characters', { number: (maxLength as any)?.value ?? maxLength }),
                },
                pattern: {
                    value: (pattern as any)?.value ?? pattern,
                    message: (pattern as any)?.message ?? translate('This field is in incorrect format'),
                },
                validate,
            }}
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

export type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    minLength?:
        | number
        | {
              value: number;
              message: string;
          };
    maxLength?:
        | number
        | {
              value: number;
              message: string;
          };
    pattern?:
        | RegExp
        | {
              value: RegExp;
              message: string;
          };
    validate?: any;
    InputProps?: any;
    inputProps?: any;
    onClickShowPassword?: any;
    tooltip?: string;
    classes?: any;
};
