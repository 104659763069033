import moment from 'moment-timezone';
import { type Day } from 'src/constants/Day';
import { TimeZone, TimeZones } from 'src/constants/TimeZone';
import type { CartItemVm } from 'src/types/CartItemVm';
import { OrderItemVm } from 'src/types/OrderItemVm';
import { getDayFromNumberDay } from 'src/utils/date/getDayFromNumberDay';

export function getDaysFromOrderItems(orderItems: Array<CartItemVm | OrderItemVm> | void, timeZone?: TimeZone): Array<Day | void> {
    const days: Array<Day> = [];

    if (!orderItems) return days;

    orderItems?.forEach((item) => {
        if (!item.pickupTime) return;

        const dayName: Day = getDayFromNumberDay(moment.tz(item.pickupTime, timeZone ?? TimeZones.AMERICA_MONTERREY).isoWeekday());
        if (!days.includes(dayName)) {
            days.push(dayName);
        }
    });

    return days;
}
