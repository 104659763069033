import { makeStyles } from '@material-ui/core';
import moment from 'moment-timezone';
import * as React from 'react';
import { Text } from 'src/components/app/Text';
import { Button } from 'src/components/Button';
import { OrderSteps } from 'src/constants/OrderStep';
import { TimeZones } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import { CheckIcon } from 'src/icons/CheckIcon';
import { ErrorIcon } from 'src/icons/ErrorIcon';
import { appReducer } from 'src/reducers/appReducer';
import { getDayFromNumberDay } from 'src/utils/date/getDayFromNumberDay';
import { getDaysFromOrderItems } from 'src/utils/order/getDaysFromOrderItems';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function SuccessOrders(): React.ReactElement {
    const classes = useStyles();

    const orders = useSelector((state) => state.app.orders);
    const items = useSelector((state) => state.app.items);
    const timeZone = useSelector((state) => state.app.restaurant?.timeZone);

    const setOrderStep = useAction(appReducer.actions.setOrderStep);

    const daysFromItems = getDaysFromOrderItems(items, timeZone);
    const daysFromOrderItems = getDaysFromOrderItems(orders?.length ? orders?.flatMap((order) => order.orderItems) : undefined, timeZone);

    const handleTryAgain = () => {
        setOrderStep(OrderSteps.CHECKOUT);
    };

    if (!daysFromOrderItems?.length) {
        return (
            <div className={classes.container}>
                <Text title>{translate('Oops! Something went wrong')}</Text>
                <ErrorIcon />
                <Text>{translate('We had problems creating your order')}</Text>
                <Button color={'#F64F4F'} secondary classes={{ button: classes.button }} onClick={handleTryAgain}>
                    {translate('Try again')}
                </Button>
            </div>
        );
    }

    if (daysFromItems?.length === 1 && orders?.length === 1) {
        const order = orders?.[0];

        return (
            <div className={classes.container}>
                <Text title>{translate('Scheduled order')}</Text>
                <CheckIcon />
                <Text className={classes.bold}>{translate('Order #@dailyOrderId', { dailyOrderId: order?.dailyOrderId })}</Text>
                <Text>{translate('Your order will be delivered according to the scheduled day.')}</Text>
                <Text>{translate('Enjoy your order!')}</Text>
            </div>
        );
    }

    return (
        <div className={classes.container}>
            <Text title>{translate('Scheduled orders')}</Text>
            <CheckIcon />
            <div>
                {daysFromItems?.map((day) => {
                    const orderByDay = orders?.find((order) =>
                        order.orderItems.some((orderItem) => getDayFromNumberDay(moment.tz(orderItem.pickupTime, timeZone ?? TimeZones.AMERICA_MONTERREY).isoWeekday()) === day),
                    );
                    if (!!orderByDay) {
                        return <Text className={classes.bold}>{`${translate('Order #@dailyOrderId', { dailyOrderId: orderByDay.dailyOrderId })} (${translate(`Days.${day}`)})`}</Text>;
                    }
                    return (
                        <>
                            <Text error>{`${translate('Error')} ${translate(`${day}`)}`}</Text>
                            <Text error>{translate('Error programming order, try again later')}</Text>
                        </>
                    );
                })}
            </div>
            <Text>{translate('Your orders will be delivered according to the scheduled days.')}</Text>
            <Text>{translate('Enjoy your order!')}</Text>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '80%',
        height: '50vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        textAlign: 'center',
        gap: 40,
    },
    bold: {
        fontWeight: 'bold',
    },
    button: {
        width: '100%',
        height: '4vh',
        borderRadius: '0.5vw',
        fontSize: '3vw',
    },
}));
