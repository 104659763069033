import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { SubscribedPage } from 'src/components/page/SubscribedPage';
import { OrderSteps } from 'src/constants/OrderStep';
import { translate } from 'src/i18n/translate';
import touch from 'src/images/touch.svg';
import { appReducer } from 'src/reducers/appReducer';
import { useIsPickupStationUser } from 'src/services/useIsPickupStationUser';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function StartPage(): React.ReactElement {
    const classes = useStyles();

    const restaurant = useSelector((state) => state.app.restaurant);
    const isPickupStationUser = useIsPickupStationUser();

    const setOrderStep = useAction(appReducer.actions.setOrderStep);

    const handleClick = () => (isPickupStationUser ? setOrderStep(OrderSteps.VALIDATE_EMPLOYEE) : setOrderStep(OrderSteps.SELECT_ORDER_TYPE));

    return (
        <SubscribedPage title={'Kiosk start'}>
            <div className={classes.container} onClick={handleClick}>
                {restaurant?.logoImageUrl && <img alt={translate('Restaurant logo')} src={restaurant?.logoImageUrl} className={classes.logo} />}
                <p className={classes.title}>
                    {!isPickupStationUser && (
                        <>
                            <strong>{translate('Verb.Order')}</strong> {translate('&')} <strong>{translate('Pay Here')}</strong>
                        </>
                    )}
                </p>
                <img alt={translate('touch icon')} src={touch} className={classes.icon} />
                <p className={classes.text}>{isPickupStationUser ? translate('Play to start') : translate('Tap to order')}</p>
            </div>
        </SubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        fontFamily: theme.typography.regular,
        fontSize: '20vw',
        textAlign: 'center',
        color: 'white',
        width: '90%',
        lineHeight: 1,
        margin: 0,
        marginBottom: '8vh',
    },
    text: {
        fontFamily: theme.typography.bold,
        fontSize: '3vh',
        textAlign: 'center',
        color: 'white',
    },
    icon: {
        height: '25vw',
        pointerEvents: 'none',
    },
    logo: {
        width: '16vw',
        marginBottom: '8vh',
        marginTop: 0,
        pointerEvents: 'none',
    },
}));
