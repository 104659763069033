import * as React from 'react';

export function ExitDoorIcon({ title, width, height }: Props): React.ReactElement {
    return (
        <svg width={width ?? '28'} height={height ?? '31'} viewBox='0 0 28 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <title>{title}</title>
            <path
                id='Vector'
                d='M17.0838 11.0419V5.46899C17.0838 4.58218 16.7315 3.73168 16.1044 3.10461C15.4773 2.47753 14.6268 2.12524 13.74 2.12524H4.82336C3.93655 2.12524 3.08605 2.47753 2.45898 3.10461C1.8319 3.73168 1.47961 4.58218 1.47961 5.46899V25.5315C1.47961 26.4183 1.8319 27.2688 2.45898 27.8959C3.08605 28.523 3.93655 28.8752 4.82336 28.8752H13.74C14.6268 28.8752 15.4773 28.523 16.1044 27.8959C16.7315 27.2688 17.0838 26.4183 17.0838 25.5315V19.9586M21.5421 19.9586L26.0004 15.5002M26.0004 15.5002L21.5421 11.0419M26.0004 15.5002H7.05253'
                stroke='white'
            />
        </svg>
    );
}

type Props = {
    title?: string;
    width?: string;
    height?: string;
};
