import MomentUtils from '@date-io/moment';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment-timezone';
import { ChangeEvent, HTMLInputTypeAttribute, useContext, useEffect, useState } from 'react';
import * as React from 'react';
import { PageContext } from 'src/components/Page';
import { Tooltip } from 'src/components/Tooltip';
import { translate } from 'src/i18n/translate';
import { SearchIcon } from 'src/icons/SearchIcon';
import { createUserTypedInputLogEvent } from 'src/services/logEvent/createUserTypedInputLogEvent';
import { classNames } from 'src/utils/react/classNames';

export function Input<T extends number | Date | string>({
    onChange,
    disabled,
    type,
    classes: classesProp,
    placeholder,
    label,
    name,
    tooltip,
    defaultValue,
    value: valueProp,
    maxDate,
    minDate,
    validation,
    required,
    minValue,
    step,
    dataTestId,
    onSetError,
    autoComplete,
    helperText,
    inputRef,
    endAdornment,
    error,
}: Props<T>): React.ReactElement {
    const classes = useStyles();
    const pageContext = useContext(PageContext);

    const [value, setValue] = useState('');
    const [date, setDate] = useState(moment().startOf('day').toDate());
    const [dateTime, setDateTime] = useState(moment().startOf('day').toDate());
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [validationError, setValidationError] = useState({ showError: false, errorMessage: translate('There is a format error') });

    const isSearchType = type === 'search';
    const isDateType = type === 'date';
    const isDateTimeType = type === 'datetime-local';

    const hasError = validationError.showError || error;

    useEffect(() => {
        if (defaultValue && isDateType) setDate(defaultValue as Date);
    }, []);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setValue(value);
        onChange?.(value as T, event);
        setValidationError(validation?.(value) ?? { ...validationError, showError: false });
        onSetError?.(required && !value ? translate('This field is required') : validation?.(value)?.errorMessage);
    };

    const handleChangeDate = (date: any) => {
        setValidationError(validation?.(date) ?? { ...validationError, showError: false });
        setDate(date);
        onChange?.(date.toDate() as T);
    };

    const handleChangeDateTime = (dateTime: any) => {
        setDateTime(dateTime);
        onChange?.(dateTime.toDate() as T);
    };

    const handleInputBlur = () => {
        let valueMessage = valueProp || value;
        if (valueMessage instanceof Date) valueMessage = valueMessage.toString();
        createUserTypedInputLogEvent({ pageContext, label, name, valueMessage: `${valueMessage}` });
        pageContext.clearStackTrace();
        setIsInputFocused(false);
    };

    return (
        <div className={classNames(classes.inputWrapper, classesProp?.container)}>
            {!!label && (
                <label htmlFor={`${name}-input`} className={classNames(classes.label, classesProp?.label)}>
                    {required ? `${label} *` : label}
                </label>
            )}
            {!isDateType && !isDateTimeType && (
                <div className={classNames(classes.inputContainer, classesProp?.inputContainer, { [classes.inputContainerFocused]: isInputFocused, [classes.inputContainerError]: hasError })}>
                    {isSearchType && (
                        <div className={classes.icon}>
                            <SearchIcon color={'#616B79'} />
                        </div>
                    )}
                    <input
                        step={step}
                        id={`${name}-input`}
                        type={type || 'text'}
                        min={minValue}
                        className={classNames(classes.input, classesProp?.input)}
                        name={name}
                        onChange={handleChange}
                        placeholder={placeholder}
                        aria-describedby={`${name}-tooltip`}
                        defaultValue={defaultValue?.toString()}
                        value={(valueProp ?? value)?.toString()}
                        required={required}
                        disabled={disabled}
                        onFocus={() => setIsInputFocused(true)}
                        onBlur={handleInputBlur}
                        autoComplete={autoComplete || 'off'}
                        data-testid={dataTestId}
                        ref={inputRef}
                    />
                    {!!endAdornment && endAdornment}
                </div>
            )}
            {isDateType && (
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <div className={classNames(classes.datePicker, hasError && classes.datePickerError, classesProp?.input)}>
                        <DatePicker
                            id={`${name}-input`}
                            variant='inline'
                            name={name}
                            format='ll'
                            autoOk
                            onChange={handleChangeDate}
                            value={valueProp ?? date}
                            maxDate={maxDate}
                            minDate={minDate}
                            fullWidth
                        />
                    </div>
                </MuiPickersUtilsProvider>
            )}
            {isDateTimeType && (
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <div className={classNames(classes.datePicker, hasError && classes.datePickerError, classesProp?.input)}>
                        <DateTimePicker
                            id={`${name}-input`}
                            variant='inline'
                            name={name}
                            format='lll'
                            autoOk
                            onChange={handleChangeDateTime}
                            value={dateTime}
                            maxDate={maxDate}
                            minDate={minDate}
                            fullWidth
                        />
                    </div>
                </MuiPickersUtilsProvider>
            )}
            {tooltip && !validationError.showError && (
                <div className={classes.tooltip}>
                    <Tooltip text={tooltip} classes={{ text: classes.tooltipText }} id={`${name}-tooltip`} />
                </div>
            )}
            {!helperText && validationError.showError && (
                <div className={classes.tooltipWarning}>
                    <Tooltip text={validationError.errorMessage} classes={{ text: classes.tooltipErrorText }} id={`${name}-tooltip`} type={'error'} />
                </div>
            )}
            {!!helperText && hasError && <span className={classNames(classes.helperText, classesProp?.helperText, { [classes.helperTextError]: hasError })}>{helperText}</span>}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    inputWrapper: {
        position: 'relative',
        width: '220px',
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        fontFamily: theme.typography.light,
        color: '#2E3748',
        fontSize: 14,
        paddingBottom: 5,
    },
    inputContainer: {
        height: '42px',
        padding: 15,
        width: '100%',
        outline: '1px solid #D9D9D9',
        backgroundColor: 'transparent',
        borderRadius: '4px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
    inputContainerFocused: {
        outline: '2px solid #1A73F9',
    },
    inputContainerError: {
        outline: '2px solid red',
    },
    input: {
        boxSizing: 'border-box',
        color: '#191919',
        width: '100%',
        fontFamily: theme.typography.light,
        border: 0,
        backgroundColor: 'transparent',
        outline: 0,
    },
    icon: {
        color: '#6C7076',
        width: 20,
        height: 20,
    },
    tooltip: {
        position: 'absolute',
        width: '20px',
        height: '20px',
        left: '90%',
        top: '40%',
        color: '#6C7076',
        zIndex: 100000,
    },
    tooltipWarning: {
        position: 'absolute',
        width: '20px',
        height: '20px',
        left: '88%',
        top: '50%',
        color: '#6C7076',
        zIndex: 500,
    },
    tooltipText: {
        backgroundColor: '#616B79',
        fontWeight: 'bold',
    },

    tooltipErrorText: {
        backgroundColor: '#E32F45',
        fontWeight: 'bold',
    },
    datePicker: {
        backgroundColor: 'transparent',
        borderRadius: '4px',
        padding: '5px 0 5px 15px',
        border: '1px solid #ccc',

        '& .MuiInputBase-root': {
            fontFamily: `${theme.typography.regular} !important`,
            fontSize: 14,
            borderRadius: '4px',
            backgroundColor: 'transparent',
        },
        '& .MuiInputBase-root::before': {
            display: 'none',
        },
        '& .MuiInputBase-root::after': {
            display: 'none',
        },
    },
    datePickerError: {
        border: '1px solid #E32F45',
    },
    helperText: {
        fontFamily: theme.typography.light,
        color: theme.palette.secondary.contrastText,
        wordBreak: 'break-all',
    },
    helperTextError: {
        fontFamily: theme.typography.light,
        color: 'red',
    },
}));

type Props<T extends number | Date | string> = {
    name: string;
    type?: HTMLInputTypeAttribute;
    value?: T;
    placeholder?: string;
    label?: string;
    autoComplete?: string;
    tooltip?: string;
    required?: boolean;
    disabled?: boolean;
    defaultValue?: T;
    minValue?: number;
    step?: number;
    maxDate?: Date;
    minDate?: Date;
    errorMessage?: string;
    classes?: {
        inputContainer?: string;
        container?: string;
        label?: string;
        input?: string;
        tooltipText?: string;
        helperText?: string;
    };
    onChange?: (value: T, event?: ChangeEvent<HTMLInputElement>) => any;
    onSetError?: any;
    validation?: any;
    dataTestId?: string;
    helperText?: string;
    inputRef?: any;
    endAdornment?: React.ReactNode;
    error?: boolean;
};
