import { makeStyles } from '@material-ui/core';
import { Text } from '@pidedirecto/ui';
import { useTheme } from '@pidedirecto/ui/hooks';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button } from 'src/components/Button';
import { GoBackButton } from 'src/components/button/GoBackButton';
import { ItemQuantitySpinner } from 'src/components/button/ItemQuantitySpinner';
import { SubscribedPage } from 'src/components/page/SubscribedPage';
import { OrderSteps } from 'src/constants/OrderStep';
import { translate } from 'src/i18n/translate';
import noImageIcon from 'src/images/icon-no-photo.png';
import { appReducer } from 'src/reducers/appReducer';
import { useAddItem } from 'src/services/order/useAddItem';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { CartItemVm } from 'src/types/CartItemVm';
import { ModifierGroupVm } from 'src/types/ModifierGroupVm';
import { removeDuplicates } from 'src/utils/array/removeDuplicates';
import { calculateOrderItemPrice } from 'src/utils/order/calculateOrderItemPrice';
import { calculateOrderItemPriceWithPromo } from 'src/utils/order/calculateOrderItemPriceWithPromo';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { removeNulls } from 'src/utils/removeNulls';

export function ProductDetailPage(): React.ReactElement | null {
    const classes = useStyles();
    const theme = useTheme();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [addItem] = useAddItem();

    const [quantity, setQuantity] = useState(1);
    const [showCustomize, setShowCustomize] = useState(false);

    const item = useSelector((state) => state.app.itemSelected);
    const modifierGroups = useSelector((state) => state.app.kioskMenu?.modifierGroups);
    const selectedMenu = useSelector((state) => state.app.selectedMenu);
    const menuCategorySelected = useSelector((state) => state.app.menuCategorySelected);
    const menuCategories = useSelector((state) => state.app.kioskMenu?.menuCategories);
    const promotions = useSelector((state) => state.app.promotions);

    const setOrderStep = useAction(appReducer.actions.setOrderStep);
    const setSelectedMenuItemQuantity = useAction(appReducer.actions.setSelectedMenuItemQuantity);

    const menuCategoryItemBelongs = menuCategories?.find((menuCategory) => menuCategory.menuCategoryId === item?.menuCategoryId);
    const promotion = promotions?.find((promotion) => promotion.menuItemIds.includes(item?.menuItemId!));

    useEffect(() => {
        if (item) {
            const modifierGroups = getModifierGroups();
            if (modifierGroups?.length > 0) {
                setShowCustomize(true);
            }
        }
    }, [item, quantity]);

    const getMenuItemTotalPrice = () => {
        if (!item) return 0;

        const cartItem: CartItemVm = {
            menuItemId: item.menuItemId,
            menuItemType: item.menuItemType,
            name: item.name,
            unitPrice: item.price,
            promoUnitPrice: item.promoPrice,
            promoText: item.promoText,
            size: item.size,
            imageUrl: item.imageUrl,
            quantity,
            modifierGroups: [],
            pickupTime: selectedMenu?.pickupTime,
        };

        let promotionDiscount = 0;

        if (!!promotion && !!promotion.menuItemsQuantityToGet) {
            const freeItemsPerUsage = BigNumber(promotion.menuItemsQuantityToGet)
                .minus(promotion.menuItemsQuantityToPay ?? 0)
                .toNumber();
            const timesPromotionUsed = Math.floor(quantity / promotion.menuItemsQuantityToGet);

            promotionDiscount = BigNumber(cartItem.unitPrice).multipliedBy(timesPromotionUsed).multipliedBy(freeItemsPerUsage).toNumber();
        }

        return BigNumber(calculateOrderItemPrice(cartItem)).minus(promotionDiscount).toNumber();
    };

    const getMenuItemTotalPromoPrice = () => {
        if (!item) return 0;

        const cartItem: CartItemVm = {
            menuItemId: item.menuItemId,
            menuItemType: item.menuItemType,
            name: item.name,
            unitPrice: item.price,
            promoUnitPrice: item.promoPrice,
            promoText: item.promoText,
            size: item.size,
            imageUrl: item.imageUrl,
            quantity,
            modifierGroups: [],
            pickupTime: selectedMenu?.pickupTime,
        };

        let promotionDiscount = 0;

        if (!!promotion && !!promotion.menuItemsQuantityToGet) {
            const timesPromotionUsed = Math.floor(quantity / promotion.menuItemsQuantityToGet);
            promotionDiscount = BigNumber(cartItem.promoUnitPrice ?? 0)
                .multipliedBy(timesPromotionUsed)
                .toNumber();
        }

        return BigNumber(calculateOrderItemPriceWithPromo(cartItem)).minus(promotionDiscount).toNumber();
    };

    const getModifierGroups = (): Array<ModifierGroupVm> => {
        const menuCategoryModifierGroupIds = menuCategoryItemBelongs?.modifierGroupIds ?? menuCategorySelected?.modifierGroupIds ?? [];
        const modifierGroupIds = removeDuplicates([...(item?.modifierGroupIds ?? []), ...menuCategoryModifierGroupIds]);
        return removeNulls(
            modifierGroupIds?.map((modifierGroupId) => {
                const modifierGroup = modifierGroups?.find((modifierGroup) => modifierGroup.modifierGroupId === modifierGroupId);
                if (!modifierGroup) return null as any;
                return modifierGroup;
            }) ?? [],
        );
    };

    const addOneItem = () => {
        setQuantity(quantity + 1);
    };

    const removeOneItem = () => {
        if (quantity !== 0) setQuantity(quantity - 1);
    };

    const handleAddKioskItem = async () => {
        if (!item || quantity === 0) return;

        const hasRequiredModifierGroups = getModifierGroups()?.some((modifier) => {
            return modifier.requiredMin;
        });

        if (hasRequiredModifierGroups) {
            setOrderStep(OrderSteps.MODIFIERS);
            setSelectedMenuItemQuantity(quantity);
            return;
        }

        await addItem({
            menuItemId: item.menuItemId,
            menuItemType: item.menuItemType,
            menuCategoryId: menuCategorySelected?.menuCategoryId,
            name: item.name,
            unitPrice: item.price,
            promoUnitPrice: item.promoPrice,
            promoText: item.promoText,
            size: item.size,
            imageUrl: item.imageUrl,
            quantity,
            modifierGroups: [],
            pickupTime: selectedMenu?.pickupTime,
        });

        setOrderStep(OrderSteps.CREATE_ORDER);
    };

    const goToModifiersPage = () => {
        setOrderStep(OrderSteps.MODIFIERS);
        setSelectedMenuItemQuantity(quantity);
    };

    if (!item) return null;

    return (
        <SubscribedPage title={'Kiosk product details'}>
            <div className={classes.productDetail}>
                <GoBackButton />
                <div className={classes.productContainer}>
                    {item.imageUrl && (
                        <div className={classes.imageContainer}>
                            <img src={item.imageUrl} alt={`image for ${item.name}`} className={classes.image} />
                        </div>
                    )}
                    {!item.imageUrl && (
                        <div className={classes.noImageContainer}>
                            <img src={noImageIcon} alt='no image icon' className={classes.noImage} />
                        </div>
                    )}
                    <div className={classes.productInfo}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1vh' }}>
                            <div className={classes.nameContainer}>
                                <div className={classes.name}>{item.name}</div>
                                {!!promotion && (
                                    <Text className={classes.promotionName} style={{ color: theme.palette?.text?.brand }}>
                                        {promotion.name}
                                    </Text>
                                )}
                            </div>
                            {!item.promoPrice && <div className={classes.price}>{formatAsCurrencyNumber(getMenuItemTotalPrice())}</div>}
                            {item.promoPrice && (
                                <div style={{ display: 'flex' }}>
                                    <div className={classes.lastPrice}>{formatAsCurrencyNumber(getMenuItemTotalPrice())}</div>
                                    <span className={classes.price}>{formatAsCurrencyNumber(getMenuItemTotalPromoPrice())}</span>
                                </div>
                            )}
                        </div>
                        <div className={classes.description}>{item.description}</div>
                        <ItemQuantitySpinner quantity={quantity} onAdd={addOneItem} onSubtract={removeOneItem} classes={{ button: classes.spinnerButton }} />
                        {showCustomize && (
                            <Button outlined onClick={goToModifiersPage} classes={{ button: classes.customizeButton }}>
                                {translate('Customize')}
                            </Button>
                        )}
                    </div>
                </div>
                <div className={classes.buttonsContainer}>
                    <Button disabled={!quantity} onClick={handleAddKioskItem} classes={{ button: classes.addButton }}>
                        {translate('Add')}
                    </Button>
                </div>
            </div>
        </SubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    productDetail: {
        width: '100%',
        height: '90vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'space-between',
        padding: '5vw',
    },
    productContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        height: 'fit-content',
    },
    productInfo: {
        width: '100%',
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '4vw',
    },
    imageContainer: {
        borderRadius: 20,
        width: '40%',
        aspectRatio: 1,
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
    },
    image: {
        width: '100%',
        aspectRatio: 1,
        objectFit: 'cover',
        flexShrink: 0,
        borderRadius: 20,
    },
    noImageContainer: {
        borderRadius: 20,
        width: '40%',
        aspectRatio: 1,
        backgroundColor: theme.palette.secondary.dark,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        padding: '1vw',
    },
    noImage: {
        width: '50%',
        aspectRatio: 1,
        objectFit: 'cover',
    },
    title: {
        fontSize: '6vw',
        alignSelf: 'center',
        textAlign: 'center',
        color: '#2C374A',
        fontFamily: theme.typography.bold,
        marginBottom: '5vh',
    },
    name: {
        fontSize: '4vw',
        color: '#2C374A',
        fontFamily: theme.typography.semiBold,
    },
    price: {
        fontSize: '4vw',
        color: '#2C374A',
        fontFamily: theme.typography.semiBold,
        minWidth: 'max-content',
    },
    lastPrice: {
        textDecoration: 'line-through',
        fontFamily: theme.typography.semiBold,
        color: theme.palette.text.disabled,
        fontSize: '4vw',
        margin: 0,
        marginRight: 16,
    },
    description: {
        fontSize: '3vw',
        color: '#75858E',
        fontFamily: theme.typography.regular,
        textAlign: 'left',
        marginBottom: '1vh',
    },
    buttonsContainer: {
        marginTop: 60,
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'flex-end',
    },
    addButton: {
        marginTop: '3vh',
        fontSize: '4vw',
        fontFamily: theme.typography.regular,
        paddingTop: '1.5vh',
        paddingBottom: '1.5vh',
        paddingLeft: '2.5vh',
        paddingRight: '2.5vh',
        borderRadius: 12,
    },
    customizeButton: {
        fontSize: '3vw',
        width: '30vw',
        borderRadius: 14,
        marginTop: '1vh',
    },
    spinnerButton: {
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    promotionName: {
        fontSize: '3vw',
    },
    nameContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
}));
