import * as React from 'react';
import { Environments } from 'src/constants/Environment';
import { MINUTES } from 'src/constants/TimeUnit';
import { envENVIRONMENT } from 'src/env/envENVIRONMENT';
import { formatTimeSecondsString } from 'src/utils/date/formatTimeSecondsString';
import { useInterval } from 'src/utils/react/useInterval';

export function CheckForUpdates(): React.ReactElement | null {
    useInterval(() => checkForUpdate(), 1 * MINUTES, []);

    const checkForUpdate = async () => {
        if (envENVIRONMENT() === Environments.LOCALHOST) {
            return console.info(`Skipping service worker update check since environment is localhost`);
        }
        try {
            console.log(`Checking for updates ${formatTimeSecondsString(new Date())}`);
            if ('serviceWorker' in navigator) {
                if (navigator.serviceWorker?.ready) {
                    const registration = await navigator.serviceWorker.ready;
                    await registration.update();
                } else {
                    console.log(`Skipped checking for updates since navigator.serviceWorker.ready is not true`);
                }
            } else {
                console.error('Service worker is not supported by the browser');
            }
        } catch (e: any) {
            console.error('Failed to check if service worker is updated', e);
        }
    };

    return null;
}
