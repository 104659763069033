import * as React from 'react';

export function ServiceErrorIcon({ title, width, height }: Props): React.ReactElement {
    return (
        <svg width={width ?? '364'} height={height ?? '356'} viewBox='0 0 364 356' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <title>{title}</title>
            <rect y='0.5' width='364' height='355' rx='177.5' fill='#F6F8F9' />
            <path
                d='M197.888 233.538C190.213 235.822 182.127 236.611 174.121 235.857C166.114 235.104 158.352 232.823 151.304 229.154C144.256 225.485 138.069 220.503 133.116 214.51C128.164 208.516 124.548 201.636 122.489 194.285C119.797 184.692 119.836 174.598 122.603 165.025C125.37 155.452 130.765 146.74 138.243 139.773L159.296 119.875L232.738 189.286M100 255.5L135.875 221.594M212.75 100.5L176.875 134.406M264 148.937L228.125 182.844M223 216.75L264 255.5M264 216.75L223 255.5'
                stroke='#8A95A5'
                strokeWidth='12'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}

type Props = {
    title?: string;
    width?: string;
    height?: string;
};
