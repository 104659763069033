import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { Text } from 'src/components/app/Text';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { translate } from 'src/i18n/translate';
import { CircleCloseIcon } from 'src/icons/CircleCloseIcon';
import { appReducer } from 'src/reducers/appReducer';
import { PaymentTerminalItem } from 'src/scenes/PaymentTerminalItem';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { PaymentTerminalId } from 'src/types/Id';
import { PaymentTerminalVm } from 'src/types/PaymentTerminalVm';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function SelectPaymentTerminalDialog(): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [selectedPaymentTerminalId, setSelectedPaymentTerminalId] = useState<PaymentTerminalId | undefined>(undefined);

    const isOpenSelectPaymentTerminalDialog = useSelector((state) => state.app.selectPaymentTerminalDialog.open);
    const onSelectPaymentTerminal = useSelector((state) => state.app.selectPaymentTerminalDialog.onSelectPaymentTerminal);
    const onCloseDialog = useSelector((state) => state.app.selectPaymentTerminalDialog.onCloseDialog);
    const header = useSelector((state) => state.app.selectPaymentTerminalDialog.header);
    const error = useSelector((state) => state.app.selectPaymentTerminalDialog.error);
    const amount = useSelector((state) => state.app.selectPaymentTerminalDialog.amount);
    const paymentTerminals = useSelector((state) => state.app.paymentTerminals);
    const deviceGroup = useSelector((state) => state.app.deviceGroup);
    const devicePaymentTerminals = paymentTerminals?.filter((paymentTerminal) => deviceGroup?.paymentTerminalIds?.includes(paymentTerminal.paymentTerminalId));
    const closeSelectPaymentTerminalDialog = useAction(appReducer.actions.closeSelectPaymentTerminalDialog);

    useEffect(() => {
        setSelectedPaymentTerminalId(undefined);
    }, [isOpenSelectPaymentTerminalDialog]);

    const handleClose = () => {
        closeSelectPaymentTerminalDialog();
        onCloseDialog();
    };

    const handleSelectPaymentTerminal = (paymentTerminal: PaymentTerminalVm) => {
        setSelectedPaymentTerminalId(paymentTerminal.paymentTerminalId);
        closeSelectPaymentTerminalDialog();
        onSelectPaymentTerminal(paymentTerminal);
    };

    return (
        <Dialog
            open={isOpenSelectPaymentTerminalDialog}
            onClose={handleClose}
            title={header ?? translate('Select a terminal to pay @amount', { amount: formatAsCurrencyNumber(amount) })}
            classes={{ dialog: classes.dialog, dialogContainer: classes.dialogContainer }}
        >
            {error && <h3>{error}</h3>}
            <div className={classes.dialogContent}>
                <div style={{ width: '100%' }}>
                    <Text variant='medium' size='large' style={{ marginBottom: 12 }}>
                        {translate('Terminals')}
                    </Text>
                    <div className={classes.paymentTerminalsContainer}>
                        {devicePaymentTerminals?.map((paymentTerminal: any) => {
                            const isSelected = paymentTerminal.paymentTerminalId === selectedPaymentTerminalId;
                            return (
                                <PaymentTerminalItem
                                    key={paymentTerminal.paymentTerminalId}
                                    paymentTerminal={paymentTerminal}
                                    isSelected={isSelected}
                                    onSelectPaymentTerminal={handleSelectPaymentTerminal}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
            <Button onClick={handleClose} secondary classes={{ button: classes.closeButton }}>
                <CircleCloseIcon />
            </Button>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        zIndex: 2000,
    },
    dialog: {
        height: 'fit-content',
        width: '900px',
    },
    dialogContent: {
        display: 'flex',
        gap: 24,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    paymentTerminalsContainer: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
        gap: 12,
    },
    closeButton: {
        position: 'absolute',
        top: 30,
        right: 30,
        border: 0,
        '&:hover': {
            border: 0,
        },
    },
}));
