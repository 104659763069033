import { IconButton, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Image } from '@pidedirecto/ui';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { ItemQuantitySpinner } from 'src/components/button/ItemQuantitySpinner';
import { translate } from 'src/i18n/translate';
import { NotFoundCategoryImageIcon } from 'src/icons/NotFoundCategoryImageIcon';
import { appReducer } from 'src/reducers/appReducer';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { CartItemVm } from 'src/types/CartItemVm';
import { calculateOrderItemPrice } from 'src/utils/order/calculateOrderItemPrice';
import { calculateOrderItemPriceWithPromo } from 'src/utils/order/calculateOrderItemPriceWithPromo';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';

export function CheckoutItem({ item }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [quantity, setQuantity] = useState(1);

    const addKioskItem = useAction(appReducer.actions.addKioskItem);
    const removeOneKioskItem = useAction(appReducer.actions.removeOneKioskItem);
    const removeKioskItem = useAction(appReducer.actions.removeKioskItem);

    useEffect(() => {
        if (item) setQuantity(item.quantity);
    }, [item]);

    const handleRemoveItem = () => {
        if (quantity !== 0) removeOneKioskItem(item);
    };
    return (
        <div className={classes.container}>
            {!item.imageUrl && (
                <div className={classes.noImageContainer}>
                    <NotFoundCategoryImageIcon title={'product'} />
                </div>
            )}
            {item.imageUrl && <Image src={item.imageUrl} width={250} height={250} className={classes.image} alt={translate('Image of @cartItem', { cartItem: item.name })} />}
            <div className={classes.itemInfoContainer}>
                <div style={{ display: 'flex', flexDirection: item.promoUnitPrice ? 'column' : 'row', width: '100%', justifyContent: 'space-between' }}>
                    <p className={classes.name}>{item.name}</p>
                    <div style={{ display: 'flex', gap: 12 }}>
                        <span className={classNames(classes.price, item.promoUnitPrice ? classes.priceWithDiscount : '')}>{formatAsCurrencyNumber(calculateOrderItemPrice(item))}</span>
                        {item.promoUnitPrice && <span className={classes.promoText}>{formatAsCurrencyNumber(calculateOrderItemPriceWithPromo(item))}</span>}
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                        <ItemQuantitySpinner quantity={quantity} onSubtract={handleRemoveItem} onAdd={() => addKioskItem(item)} classes={{ button: classes.spinnerButton }} />
                        <IconButton onClick={() => removeKioskItem(item)}>
                            <DeleteIcon className={classes.icon} />
                        </IconButton>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {item.modifierGroups.map((modifierGroup) =>
                            modifierGroup.modifiers.map((modifier) => (
                                <span key={modifier.name} className={classes.modifier}>
                                    + {modifier.quantity} {modifier.name}
                                </span>
                            )),
                        )}
                    </div>
                    {item.note && (
                        <p className={classes.name}>
                            {translate('Note: ')}
                            {item.note && item.note.length > 20 ? `${item.note?.slice(0, 20) ?? ''}...` : item.note}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexShrink: 0,
        gap: 20,
    },
    image: {
        width: 'clamp(100px, 250px, 25vw)',
        height: 'clamp(100px, 250px, 25vw)',
        aspectRatio: 1,
        objectFit: 'cover',
        borderRadius: 12,
    },
    noImageContainer: {
        borderRadius: 12,
        width: 250,
        height: 250,
        aspectRatio: 1,
        backgroundColor: theme.palette.secondary.dark,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        padding: 24,
    },
    name: {
        fontFamily: theme.typography.semiBold,
        fontSize: '3vw',
        width: '100%',
        margin: 0,
        color: '#2C374A',
    },
    price: {
        fontFamily: theme.typography.semiBold,
        fontSize: '4vw',
        color: '#2C374A',
        width: 'max-content',
    },
    priceWithDiscount: {
        fontSize: '4vw',
        textDecoration: 'line-through',
        color: theme.palette.text.disabled,
    },
    promoText: {
        fontFamily: theme.typography.semiBold,
        fontSize: '4vw',
        margin: 0,
        marginLeft: 12,
        color: '#2C374A',
    },
    icon: {
        fontSize: 60,
        color: '#75828C',
        display: 'none',
    },
    modifier: {
        fontSize: '3vw',
        color: '#75828C',
        marginBottom: '0.5vh',
    },
    spinnerButton: {
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    itemInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        width: '100%',
    },
}));

type Props = {
    item: CartItemVm;
};
