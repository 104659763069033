import { Days, type Day } from 'src/constants/Day';

export function getDayFromNumberDay(day: number): Day {
    if (day === 0) return Days.SUNDAY;
    if (day === 1) return Days.MONDAY;
    if (day === 2) return Days.TUESDAY;
    if (day === 3) return Days.WEDNESDAY;
    if (day === 4) return Days.THURSDAY;
    if (day === 5) return Days.FRIDAY;
    if (day === 6) return Days.SATURDAY;

    return Days.MONDAY;
}
