import moment from 'moment-timezone';
import type { TimeZone } from 'src/constants/TimeZone';

/**
 * Use this helper method instead of pure moment: moment.tz('13:15', 'HH:mm', TimeZone.AMERICA_MONTERREY)
 * cause pure moment solution does not handle time zones in constructor if format is not stantard format
 * see https://github.com/moment/moment-timezone/issues/761
 */
export function momentFromTimeString(time: string, timeZone: TimeZone, pickupTimeOrder: Date): moment.Moment {
    const split = time.split(':');
    const hour = parseInt(split[0]);
    const minute = parseInt(split[1]);

    const currentDate = moment(pickupTimeOrder);

    return currentDate.hour(hour).minute(minute);
}
