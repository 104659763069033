import { AppBar, makeStyles, Toolbar } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { Text } from 'src/components/app/Text';
import { Button } from 'src/components/Button';
import { OrderSteps } from 'src/constants/OrderStep';
import { translate } from 'src/i18n/translate';
import { BackIcon } from 'src/icons/BackIcon';
import { ExitDoorIcon } from 'src/icons/ExitDoorIcon';
import { UserIcon } from 'src/icons/UserIcon';
import { appReducer } from 'src/reducers/appReducer';
import { useClearKiosk } from 'src/services/useClearKiosk';
import { useConfirmDialog } from 'src/services/useConfirmDialog';
import { useIsPickupStationUser } from 'src/services/useIsPickupStationUser';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useIsSmallScreen } from 'src/utils/react/window/useIsSmallScreen';

export function HeaderBar(): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useIsSmallScreen();

    const isPickupStationUser = useIsPickupStationUser();
    const confirmDialog = useConfirmDialog();

    const customerEmployee = useSelector((state) => state.app.customerEmployee);
    const orderStep = useSelector((state) => state.app.orderStep);
    const items = useSelector((state) => state.app.items);

    const clearKiosk = useClearKiosk();
    const setOrderStep = useAction(appReducer.actions.setOrderStep);

    const isCreatedOrderStep = orderStep === OrderSteps.CREATE_ORDER;
    const isOrderSummaryStep = orderStep === OrderSteps.ORDER_SUMMARY;
    const isCheckoutStep = orderStep === OrderSteps.CHECKOUT;

    const handleCustomerLogOut = async () => {
        const logOut = await confirmDialog({
            title: translate('Are you sure you want logged out?'),
            content: !!items?.length ? translate('You have items in your order, if you log out now the information will be lost.') : undefined,
            buttonText: translate('Log out'),
            cancelButtonText: translate('Cancel'),
            variant: 'warning',
        });

        if (!logOut) return;

        clearKiosk();
    };

    const handleGoBack = () => {
        if (isOrderSummaryStep) return setOrderStep(OrderSteps.CREATE_ORDER);
        if (isCheckoutStep) return setOrderStep(OrderSteps.ORDER_SUMMARY);
    };

    if (!isPickupStationUser) return <></>;
    if (!isCreatedOrderStep && !isOrderSummaryStep && !isCheckoutStep) return <></>;

    return (
        <AppBar position='static' style={{ backgroundColor: theme.palette.primary.main }}>
            <Toolbar>
                <div className={classes.employeeInfoContainer}>
                    <div className={classes.employeeInfo}>
                        {isCreatedOrderStep && !!customerEmployee && (
                            <div className={classes.employeeNumber}>
                                <UserIcon title={translate('Employee number')} width={isSmallScreen ? '20' : '50'} height={isSmallScreen ? '20' : '50'} />
                                <Text title className={classes.text}>
                                    {customerEmployee?.employeeNumber}
                                </Text>
                            </div>
                        )}
                        {!isCreatedOrderStep && (
                            <Button text classes={{ button: classes.button }} onClick={handleGoBack}>
                                <BackIcon width={isSmallScreen ? '20' : '30'} height={isSmallScreen ? '20' : '30'} />
                                <p>{translate('Go back')}</p>
                            </Button>
                        )}
                        <Button text classes={{ button: classes.button }} onClick={handleCustomerLogOut}>
                            <p>{translate('Log out')}</p>
                            <ExitDoorIcon width={isSmallScreen ? '20' : '40'} height={isSmallScreen ? '20' : '40'} />
                        </Button>
                    </div>
                    {isCreatedOrderStep && !!customerEmployee && (
                        <Text variant={'bold'} className={classes.title}>
                            {customerEmployee?.name}
                        </Text>
                    )}
                    {isOrderSummaryStep && (
                        <Text variant={'bold'} className={classes.title}>
                            {translate('My Order')}
                        </Text>
                    )}
                    {isCheckoutStep && (
                        <Text variant={'bold'} className={classes.title}>
                            {translate('Pay')}
                        </Text>
                    )}
                </div>
            </Toolbar>
        </AppBar>
    );
}

const useStyles = makeStyles((theme) => ({
    text: {
        color: 'white',
        fontSize: '3vw',
    },
    title: {
        color: 'white',
        fontSize: '3vw',
    },
    employeeInfoContainer: {
        width: '100%',
        display: 'flex',
        minHeight: '10vh',
        flexDirection: 'column',
        padding: '1.5vw 1vw',
        color: 'white',
    },
    button: {
        fontSize: '3vw',
        marginTop: theme.spacing(1),
        padding: '5px 8px',
        borderRadius: 6,
        color: '#FFF',
        height: 28,
        gap: 25,
        alignItems: 'center',
        justifyContent: 'center',
    },
    employeeInfo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    employeeNumber: {
        display: 'flex',
        gap: 20,
        alignItems: 'center',
    },
}));
