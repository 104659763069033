import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useState } from 'react';
import { Button } from 'src/components/Button';
import { ItemQuantitySpinner } from 'src/components/button/ItemQuantitySpinner';
import { ModifierTypes } from 'src/constants/ModifierType';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { ModifierGroupVm } from 'src/types/ModifierGroupVm';
import { ModifierVm } from 'src/types/ModifierVm';
import { classNames } from 'src/utils/react/classNames';
import { usePrevious } from 'src/utils/react/usePrevious';

export function Modifier({ modifierGroup, modifier, selected, addModifier, modifiersInGroup }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [quantity, setQuantity] = useState(0);

    const prevQuantity = usePrevious(quantity);

    const isModifierMultiple = modifier.modifierType === ModifierTypes.MULTIPLE;

    const handleChangeQuantity = (newQuantity: number) => {
        if (newQuantity < 0) return;

        if (modifierGroup.requiredMax !== undefined) {
            if (modifiersInGroup === modifierGroup.requiredMax && newQuantity > prevQuantity) {
                return;
            }
        }

        setQuantity(newQuantity);
        addModifier(newQuantity);
    };

    return (
        <div>
            {!isModifierMultiple && (
                <Button classes={{ button: classNames(classes.modifier, { [classes.modifierSelected]: selected, [classes.modifierUnselected]: !selected }) }} onClick={() => addModifier()}>
                    <span className={classes.modifierName}>{modifier.name}</span>
                    <div>{BigNumber(modifier.price ?? 0).isGreaterThan(0) && <span className={classes.modifierPrice}>+{formatAsCurrencyNumber(modifier.price)}</span>}</div>
                </Button>
            )}
            {isModifierMultiple && (
                <div className={classes.modifierMultiple}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1vh' }}>
                        <span className={classes.modifierName}>{modifier.name}</span>
                        <div>{BigNumber(modifier.price ?? 0).isGreaterThan(0) && <span className={classes.modifierPrice}>+{formatAsCurrencyNumber(modifier.price)}</span>}</div>
                    </div>
                    <ItemQuantitySpinner onAdd={() => handleChangeQuantity(quantity + 1)} onSubtract={() => handleChangeQuantity(quantity - 1)} quantity={quantity} />
                </div>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    modifier: {
        width: '100%',
        borderRadius: 15,
        minHeight: 70,
        border: `1px solid ${theme.palette.primary.main}`,
        flexShrink: 0,
        margin: 10,
        marginLeft: 0,
        cursor: 'pointer',
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '3vw',
        backgroundColor: 'white',
    },
    modifierUnselected: {
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    modifierSelected: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        '& span': {
            color: 'white',
        },
    },
    modifierMultiple: {},
    modifierName: {
        fontFamily: theme.typography.regular,
        fontSize: '3vw',
        color: '#2C374A',
        paddingRight: '1.5vw',
    },
    modifierPrice: {
        fontFamily: theme.typography.bold,
        fontSize: '3vw',
        color: '#2C374A',
    },
}));

type Props = {
    modifierGroup: ModifierGroupVm;
    modifier: ModifierVm;
    selected: boolean;
    addModifier: any;
    modifiersInGroup: number;
};
