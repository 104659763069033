import { makeStyles } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import { Authenticator, ConfirmSignIn, ConfirmSignUp, ForgotPassword, Greetings, SignIn, SignUp, TOTPSetup, VerifyContact } from 'aws-amplify-react';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { ensureManagerCognitoUserExistsInDevEnvironmentApi } from 'src/api/pidedirectokiosk/authentication/ensureManagerCognitoUserExistsInDevEnvironmentApi';
import { Button } from 'src/components/Button';
import { Form } from 'src/components/form/Form';
import { FormPasswordField } from 'src/components/form/FormPasswordField';
import { FormUserNameField } from 'src/components/form/FormUserNameField';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { Environments } from 'src/constants/Environment';
import { envENVIRONMENT } from 'src/env/envENVIRONMENT';
import { translate } from 'src/i18n/translate';
import { PideDirectoSvgIcon } from 'src/icons/PideDirectoSvgIcon';
import { AwsCognitoUsername } from 'src/types/Id';
import { isProductionEnvironment } from 'src/utils/environment/isProductionEnvironment';
import { openTermsAndConditionsPageInNewTab } from 'src/utils/window/openTermsAndConditionsPageInNewTab';

export function SignInPage(): React.ReactElement {
    const form = useForm();
    const classes = useStyles();

    const [webView, setWebView] = useState(false);
    const [loading, setloading] = useState(false);

    useEffect(() => {
        isWebView();
    }, [webView]);

    const onSubmit = async (form: any) => {
        try {
            setloading(true);

            if (!isProductionEnvironment() && envENVIRONMENT() !== Environments.TEST) {
                await ensureManagerCognitoUserExistsInDevEnvironmentApi({ username: form.username as AwsCognitoUsername });
            }

            await Auth.signIn(form.username.toLowerCase(), form.password);
            setloading(false);
        } catch (error: any) {
            setloading(false);
        }
    };

    const isWebView = () => {
        const standalone = (window.navigator as any).standalone;
        const userAgent = window.navigator.userAgent.toLowerCase();
        const safari = /safari/.test(userAgent);
        const ios = /iphone|ipod|ipad/.test(userAgent);

        if (ios) {
            if (!standalone && safari) {
                setWebView(false);
            } else if (!standalone && !safari) {
                setWebView(true);
            }
            return;
        }
        if (userAgent.includes('wv')) {
            setWebView(true);
        } else {
            setWebView(false);
        }
    };

    return (
        // @ts-ignore TODO: removes Property children does not exist on type aws-amplify-react error, upgrade to use latest aws lib to get rid of error
        <Authenticator
            hide={[
                Greetings,
                SignIn,
                ConfirmSignIn,
                // RequireNewPassword,
                SignUp,
                ConfirmSignUp,
                VerifyContact,
                ForgotPassword,
                TOTPSetup,
            ]}
            theme={{
                button: { backgroundColor: '#00C8DC' },
                hint: { display: 'none' },
                sectionFooterSecondaryContent: { display: 'none' },
            }}
        >
            {/* Dont remove fragment <> below, its a fix for console errors, see https://github.com/aws-amplify/amplify-js/issues/234#issuecomment-541340399 */}
            <>
                <div className={classes.container}>
                    <div className={classes.signin}>
                        <UpdatingContentProgress bottom left loading={loading} />
                        <PideDirectoSvgIcon title={'pidedirecto-logo'} width={40} height={40} />
                        <h1 className={classes.title}>{translate('Enter to AmbitOne')}</h1>
                        <Form className={classes.formContainer} form={form} onSubmit={onSubmit}>
                            <div className={classes.input}>
                                <FormUserNameField name={'username'} label={translate('Username')} placeholder={translate('Enter your username or email')} classes={{ container: classes.input }} />
                            </div>
                            <div className={classes.input}>
                                <FormPasswordField name={'password'} label={translate('Password')} placeholder={translate('Enter your password')} classes={{ container: classes.input }} />
                            </div>
                            <Button type={'submit'} disabled={loading} classes={{ button: classes.containerButton }}>
                                {translate('Sign in')}
                            </Button>
                        </Form>
                        <div className={classes.termsContainer}>
                            <p>{translate('By logging in you accept our')}</p>
                            <span className={classes.conditionsContainer} onClick={openTermsAndConditionsPageInNewTab}>
                                {translate('Terms of use and privacy policy.')}
                            </span>
                        </div>
                    </div>
                </div>
            </>
        </Authenticator>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100vw',
        height: '100vh',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    signin: {
        minWidth: 500,
        width: '30%',
        height: '70%',
        backgroundColor: 'white',
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '540px',
        boxShadow: '0px 8px 20px -4px rgba(23, 24, 24, 0.12), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        maxHeight: 600,
        position: 'relative',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            minWidth: '100%',
            borderRadius: 0,
            height: '100%',
            padding: 0,
        },
    },
    title: {
        color: theme.palette.secondary.contrastText,
        marginBottom: 5,
        fontFamily: theme.typography.semiBold,
        fontSize: 26,
    },
    formContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        gap: 20,
        margin: '20px 0',
        padding: '0 10%',
    },
    input: {
        width: '100%',
    },
    icon: {
        width: 30,
        marginRight: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    containerButton: {
        width: '100%',
        backgroundColor: '#8d25ff',
        '&:hover': {
            backgroundColor: '#470f84',
        },
        '&:focus': {
            outline: '2px solid #d9d9d9',
        },
    },
    button: {
        width: '80%',
        height: 45,
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontWeight: 'bold',
        border: '0',
        outline: 'none',
        borderRadius: 5,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        marginTop: 10,
        boxShadow: '5px 5px 10px rgba(100, 214, 179, 0.4)',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#509984',
        },
    },
    termsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '80%',
        marginTop: 10,
        marginBottom: 15,
        fontFamily: theme.typography.regular,
        fontSize: 12,
        textAlign: 'center',
    },
    conditionsContainer: {
        color: '#1a8074',
        textDecoration: 'underline',
        cursor: 'pointer',
        display: 'inline-block',
        textAlign: 'center',
    },
    downloadApp: {
        marginLeft: '10%',
        flexDirection: 'column',
        width: '10%',
        alignItems: 'center',
        justifyContent: 'center',
        height: '50%',
        textAlign: 'center',
        border: 'none',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            paddingTop: '10%',
            marginLeft: '0%',
        },
    },
    downloadButton: {
        border: 'none',
    },
}));
