import { makeStyles, Theme } from '@material-ui/core/styles';
import { useContext } from 'react';
import * as React from 'react';
import { PageContext } from 'src/components/Page';
import { createUserClickedButtonLogEvent } from 'src/services/logEvent/createUserClickedButtonLogEvent';
import { classNames } from 'src/utils/react/classNames';
import { convertReactNodeToString } from 'src/utils/react/convertReactNodeToString';

/**
 * @callback onClick
 */

/**
 *
 * @param {React.Node} children - content inside the button
 * @param {boolean} [disabled] - button disabled control
 * @param {boolean} [secondary] - renders button secondary version
 * @param {boolean} [outlined] - renders button outlined version
 * @param {boolean} [text] - renders button text version
 * @param {boolean} [icon] - renders button icon version
 * @param {string} [type] - html button type [docs]{@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/button}
 * @param {string} [id] - html button id
 * @param {string} [color] - specifies the color variant for the secondary button
 * @param {string} [ ariaLabel] - html button aria-label [docs]{@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-label}
 * @param {Object} [classes] - classes object to override the default styles
 * @param {string} [classes.button] - class to override button style
 * @param {string} [classes.disabled] - class to override button disabled state style
 * @param {onClick} [onClick] - function to be called when the button is clicked
 * @returns {React.Node}
 */

export function Button({ children, disabled, secondary, outlined, text, icon, type, id, name, color, ariaLabel, classes: classesProp, onClick, larger }: Props): React.ReactElement {
    const classes = useStyles({ color });
    const pageContext = useContext(PageContext);

    const getClassNames = () => {
        let buttonClasses = classes.button;

        if (secondary) buttonClasses = classNames(buttonClasses, classes.secondaryButton);
        if (outlined) buttonClasses = classNames(buttonClasses, classes.outlinedButton);
        if (text) buttonClasses = classNames(buttonClasses, classes.textButton);
        if (icon) buttonClasses = classNames(buttonClasses, classes.iconButton);
        if (larger) buttonClasses = classNames(buttonClasses, classes.largerButton);

        if (disabled) buttonClasses = classNames(buttonClasses, getDisabledClass());

        if (classesProp?.button) buttonClasses = classNames(buttonClasses, classesProp?.button);
        if (classesProp?.disabled && disabled) buttonClasses = classNames(buttonClasses, classesProp?.disabled);

        return buttonClasses;
    };

    const getDisabledClass = () => {
        if (secondary) return classes.secondaryDisabled;
        if (outlined) return classes.outlinedDisabled;
        if (text) return classes.textButtonDisabled;
        return classes.disabled;
    };

    const handleClick = (e: any) => {
        const buttonName = convertReactNodeToString(children);
        createUserClickedButtonLogEvent({ pageContext, buttonName });
        pageContext.clearStackTrace();

        e.currentTarget.blur();

        if (onClick) onClick?.(e);
    };

    return (
        <button type={type ?? 'button'} className={getClassNames()} onClick={handleClick} disabled={disabled} id={id} name={name} aria-label={ariaLabel}>
            {children}
        </button>
    );
}

const useStyles = makeStyles<Theme, { color?: string }>((theme) => ({
    button: {
        border: 0,
        padding: '8px 12px',
        width: 'fit-content',
        borderRadius: 6,
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.regular,
        cursor: 'pointer',
        transition: 'background-color 0.2s linear',
        boxSizing: 'border-box',
        transformOrigin: 'center',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        minHeight: 42,
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        '&:focus': {
            outline: '2px solid #1A73F9',
        },
        textTransform: 'none',
    },
    largerButton: {
        height: 52,
        fontSize: 15,
    },
    secondaryButton: (props) => ({
        border: `1px solid ${props.color || '#D9D9D9'}`,
        backgroundColor: 'transparent',
        color: `${props.color || '#616B79'}`,
        padding: '9px 13px',
        '&:hover': {
            border: `1px solid ${props.color || '#8A95A5'}`,
            backgroundColor: 'transparent',
        },
        '&:focus': {
            border: '1px solid transparent',
            outline: '2px solid #1A73F9',
        },
    }),
    outlinedButton: {
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
        padding: '9px 13px',
        '&:hover': {
            backgroundColor: theme.palette.outlined?.mainHover,
        },
        '&:focus': {
            border: 0,
            outline: '2px solid #1A73F9',
        },
    },
    textButton: {
        border: '0px',
        backgroundColor: 'transparent',
        color: '#324054',
        padding: '6px 8px',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
        '&:focus': {
            outline: '1px solid #1A73F9',
        },
    },
    disabled: {
        backgroundColor: '#F1F1F1',
        color: '#8C9196',
        cursor: 'default',
        '&:hover': {
            backgroundColor: '#F1F1F1',
        },
    },
    secondaryDisabled: {
        backgroundColor: '#F1F1F1',
        color: '#8C9196',
        cursor: 'default',
        '&:hover': {
            backgroundColor: '#F1F1F1',
            border: '1px solid #D9D9D9',
        },
    },
    outlinedDisabled: {
        backgroundColor: '#F1F1F1',
        color: '#8C9196',
        border: '1px solid #D9D9D9',
        cursor: 'default',
        '&:hover': {
            backgroundColor: '#F1F1F1',
            border: '1px solid #D9D9D9',
        },
    },
    textButtonDisabled: {
        color: 'rgba(0, 0, 0, 0.26)',
        cursor: 'default',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    iconButton: {
        backgroundColor: 'transparent',
        width: 48,
        height: 48,
        borderRadius: 48,
        color: '#393B41',
        '&:hover': {
            backgroundColor: '#00000011',
        },
        '&:focus': {
            border: 'none',
        },
    },
}));

type Props = {
    children: any;
    primary?: boolean;
    secondary?: boolean;
    outlined?: boolean;
    text?: boolean;
    icon?: boolean;
    larger?: boolean;
    type?: 'submit' | 'reset' | 'button' | undefined;
    id?: string;
    name?: string;
    color?: string;
    ariaLabel?: string;
    disabled?: boolean;
    classes?: {
        button?: string;
        disabled?: string;
    };
    onClick?: any;
};
