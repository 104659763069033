import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useRef } from 'react';
import * as React from 'react';
import { Tooltip } from 'src/components/Tooltip';
import { translate } from 'src/i18n/translate';
import { ModifierGroupVm } from 'src/types/ModifierGroupVm';
import { classNames } from 'src/utils/react/classNames';

export function ModifierGroupHeader({ modifierGroup, missingSectionName }: Props): React.ReactElement | null {
    const classes = useStyles();
    const container = useRef<HTMLDivElement>(null);

    const isMissing = missingSectionName === modifierGroup.name;

    useEffect(() => {
        if (missingSectionName === modifierGroup.name) {
            container.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [modifierGroup, missingSectionName]);

    const required = !!modifierGroup.requiredMin && modifierGroup.requiredMin === modifierGroup.requiredMax;
    const showMinBadge = !required && !!modifierGroup.requiredMin && modifierGroup.requiredMin >= 1;
    const showMaxBadge = !required && !!modifierGroup.requiredMax && modifierGroup.requiredMax >= 1;

    return (
        <div style={{ marginLeft: '1.5vw' }} ref={container}>
            <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                <h2 className={classes.sectionName}>{modifierGroup.name}</h2>
                {required && (
                    <div className={classNames(classes.chip, isMissing ? classes.missingTitle : '')}>
                        {modifierGroup.requiredMin === 1 ? translate('REQUIRED') : `${String(modifierGroup.requiredMin)} ${translate('REQUIRED')}`}
                    </div>
                )}
                <div style={{ display: 'flex' }}>
                    {showMinBadge && <div className={classNames(classes.chip, isMissing ? classes.missingTitle : '')}>{`${translate('MIN')} ${String(modifierGroup.requiredMin)}`}</div>}
                    {showMaxBadge && <div className={classNames(classes.chip, isMissing ? classes.missingTitle : '')}>{`${translate('MAX')} ${String(modifierGroup.requiredMax)}`}</div>}
                    {!!modifierGroup.freeModifiersQuantity && (
                        <Tooltip text={translate('If it exceeds the free quantity, the higher value modifiers will be added')}>
                            <div className={classNames(classes.chip, isMissing ? classes.missingTitle : '')}>
                                {translate('@freeModifiersQuantity Modifiers free', {
                                    freeModifiersQuantity: modifierGroup.freeModifiersQuantity,
                                })}
                            </div>
                        </Tooltip>
                    )}
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#222',
    },
    sectionName: {
        fontFamily: theme.typography.regular,
        fontSize: '4vw',
    },
    headerText: {
        alignSelf: 'center',
        fontSize: 16,
        color: 'white',
        fontWeight: 'bold',
    },
    missingTitle: {
        animation: '$vibration 700ms ease-out, $color 700ms ease-out',
    },
    chip: {
        backgroundColor: '#EAEAEA',
        color: '#83868D',
        fontSize: '3vw',
        padding: '1vw',
        borderRadius: 12,
        marginLeft: '2vw',
    },
    '@keyframes vibration': {
        '0%': {
            transform: 'translateX(0px)',
        },
        '25%': {
            transform: 'translateX(15px)',
        },
        '50%': {
            transform: 'translateX(0px)',
        },
        '75%': {
            transform: 'translateX(15px)',
        },
        '100%': {
            transform: 'translateX(0px)',
        },
    },
    '@keyframes color': {
        '0%': {
            backgroundColor: '#BB0C21',
            color: 'white',
        },
        '100%': {
            backgroundColor: '#BB0C21',
            color: 'white',
        },
    },
}));

type Props = {
    modifierGroup: ModifierGroupVm;
    missingSectionName: string;
};
