import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { GoBackButton } from 'src/components/button/GoBackButton';
import { Form } from 'src/components/form/Form';
import { FormEmailField } from 'src/components/form/FormEmailField';
import { FormPhoneNumberField } from 'src/components/form/FormPhoneNumberField';
import { FormTextField } from 'src/components/form/FormTextField';
import { SubscribedPage } from 'src/components/page/SubscribedPage';
import { TermsAndConditions } from 'src/components/TermsAndConditions';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { isMexico } from 'src/utils/country/isMexico';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function CustomerInfoPage(): React.ReactElement {
    const classes = useStyles();
    const form = useForm();

    const [sendTicketOnline, setSendTicketOnline] = useState(false);

    const firstName = useSelector((state) => state.app.firstName);
    const lastName = useSelector((state) => state.app.lastName);
    const mobileNumber = useSelector((state) => state.app.mobileNumber);
    const email = useSelector((state) => state.app.email);
    const restaurant = useSelector((state) => state.app.restaurant);

    const { country } = restaurant ?? {};

    const setKioskCustomerInfo = useAction(appReducer.actions.setKioskCustomerInfo);

    useEffect(() => {
        if (firstName || lastName || mobileNumber || email) {
            form.reset({ firstName, lastName, mobileNumber, email });
        }
    }, [firstName, lastName, mobileNumber, email]);

    const onSubmit = (form: any) => {
        setKioskCustomerInfo({ firstName: form.firstName, lastName: form.lastName, mobileNumber: form.mobileNumber, email: form.email, sendTicketOnline });
    };

    return (
        <SubscribedPage title={'Kiosk customer info'}>
            <Form className={classes.container} form={form} onSubmit={onSubmit}>
                <GoBackButton />
                <h1 className={classes.title}>{translate('We will notify you when the order is ready')}</h1>
                <FormTextField
                    name='firstName'
                    required
                    label={translate('First Name')}
                    classes={{ input: classes.input, label: classes.label, helperText: classes.helperText, container: classes.inputWrapper, inputContainer: classes.inputContainer }}
                />
                <FormTextField
                    name='lastName'
                    label={translate('Last Name')}
                    classes={{ input: classes.input, label: classes.label, helperText: classes.helperText, container: classes.inputWrapper, inputContainer: classes.inputContainer }}
                />
                <FormPhoneNumberField
                    name='mobileNumber'
                    label={translate('Phone Number')}
                    required
                    classes={{
                        input: classes.input,
                        label: classes.label,
                        icon: classes.icon,
                        helperText: classes.helperText,
                        container: classes.inputWrapper,
                        inputContainer: classes.inputContainer,
                    }}
                />
                <FormEmailField
                    name='email'
                    label={translate('Email')}
                    required={sendTicketOnline}
                    classes={{
                        input: classes.input,
                        label: classes.label,
                        icon: classes.icon,
                        container: classes.inputWrapper,
                        inputContainer: classes.inputContainer,
                        helperText: classes.helperText,
                    }}
                />
                <div className={classes.alignLeftContainer} style={{ marginTop: 40 }}>
                    <Button classes={{ button: classes.primaryButton }} type='submit'>
                        {translate('Continue')}
                    </Button>
                </div>
                {isMexico(country) && <TermsAndConditions />}
            </Form>
        </SubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        padding: '0 10vw',
    },
    title: {
        fontFamily: theme.typography.bold,
        fontSize: '7vw',
        textAlign: 'center',
        width: '90%',
        margin: '0 auto',
        textTransform: 'none',
        marginBottom: '5vh',
    },
    input: {
        width: '80vw',
        height: '4vh',
        borderWidth: '2px',
        fontSize: '3.5vw',
        padding: '2.5vh 2vw',
    },
    label: {
        fontSize: '3.5vw',
        marginBottom: '1vh',
    },
    checkboxContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '80%',
        margin: '0 auto',
        marginBottom: '3vh',
    },
    checkbox: {
        border: `2px solid ${theme.palette.primary.main}`,
        width: '5vw',
        height: '5vw',
        borderRadius: '1vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    checkboxLabel: {
        fontFamily: theme.typography.medium,
        fontSize: '3.5vw',
        margin: 0,
        marginLeft: '2vw',
    },
    checkboxChecked: {
        backgroundColor: theme.palette.primary.main,
        width: '3vw',
        height: '3vw',
        borderRadius: '0.6vw',
    },
    icon: {
        fontSize: '3.5vw',
    },
    alignLeftContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row',
    },
    buttonRoot: {
        padding: 0,
        width: 'fit-content',
        minWidth: 0,
        borderRadius: 20,
    },
    primaryButton: {
        background: theme.palette.primary.main,
        color: 'white',
        marginTop: 0,
        fontSize: '4vw',
        fontFamily: theme.typography.regular,
        paddingTop: '1.5vh',
        paddingBottom: '1.5vh',
        paddingLeft: '2.5vh',
        paddingRight: '2.5vh',
        borderRadius: 12,
        fontWeight: 'bold',
        width: '100%',
    },
    helperText: {
        fontSize: '3vw',
    },
    inputWrapper: {
        marginBottom: '3vh',
        width: '100%',
    },
    inputContainer: {
        width: '100%',
        height: '4vh',
    },
}));
