import * as React from 'react';
import { OrderSteps } from 'src/constants/OrderStep';
import { MenuPage } from 'src/scenes/MenuPage';
import { ModifiersPage } from 'src/scenes/ModifiersPage';
import { OrderCompletedSummaryPage } from 'src/scenes/OrderCompletedSummaryPage';
import { OrderSummaryPage } from 'src/scenes/OrderSummaryPage';
import { PaymentPage } from 'src/scenes/PaymentPage';
import { ProductDetailPage } from 'src/scenes/ProductDetailPage';
import { StartPage } from 'src/scenes/StartPage';
import { ValidateEmployeePage } from 'src/scenes/ValidateEmployeePage';
import { useSelector } from 'src/utils/react/useSelector';

export function PickUpStationSteps(): React.ReactElement {
    const orderStep = useSelector((state) => state.app.orderStep);

    return (
        <>
            {orderStep === OrderSteps.START && <StartPage />}
            {orderStep === OrderSteps.VALIDATE_EMPLOYEE && <ValidateEmployeePage />}
            {orderStep === OrderSteps.CREATE_ORDER && <MenuPage />}
            {orderStep === OrderSteps.PRODUCT_DETAIL && <ProductDetailPage />}
            {orderStep === OrderSteps.MODIFIERS && <ModifiersPage />}
            {orderStep === OrderSteps.ORDER_SUMMARY && <OrderSummaryPage />}
            {orderStep === OrderSteps.CHECKOUT && <PaymentPage />}
            {orderStep === OrderSteps.COMPLETED && <OrderCompletedSummaryPage />}
        </>
    );
}
