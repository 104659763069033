import { MuiThemeProvider } from '@material-ui/core/styles';
import { useTheme } from '@pidedirecto/ui/hooks';
import * as React from 'react';
import { createRestaurantMuiTheme } from 'src/theme/createRestaurantMuiTheme';
import { muiTheme } from 'src/theme/muiTheme';
import { useSelector } from 'src/utils/react/useSelector';

export function ThemeProvider({ children }: Props): React.ReactElement {
    const restaurant = useSelector((state) => state.app.restaurant);

    const colors = restaurant?.restaurantColors;

    useTheme({
        palette: {
            surface: {
                brand: colors?.[0] ? `${colors?.[0]}33` : '#1a9aa033',
                brandContrast: colors?.[0] ? `${colors?.[0]}aa` : '#1a9aa0aa',
                brandHeavyContrast: colors?.[0] ?? '#1a9aa0',
            },
            text: {
                brand: colors?.[0] ?? '#1a9aa0',
            },
            border: {
                brand: colors?.[0] ?? '#1a9aa0',
                brandContrast: colors?.[0] ?? '#1a9aa0',
            },
        },
    });

    return <MuiThemeProvider theme={restaurant?.restaurantColors ? createRestaurantMuiTheme(restaurant?.restaurantColors) : muiTheme}>{children}</MuiThemeProvider>;
}

type Props = {
    children: React.ReactElement;
};
