import type { CartItemVm, ModifierGroupVm as CartModifierGroupVm } from 'src/types/CartItemVm';
import type { ModifierGroupVm, OrderItemVm } from 'src/types/OrderItemVm';

export function mapCartItemToOrderItem(cartItem: CartItemVm): OrderItemVm {
    return {
        menuItemId: cartItem.menuItemId,
        menuCategoryId: cartItem.menuCategoryId,
        name: cartItem.name,
        unitPrice: cartItem.unitPrice,
        promoUnitPrice: cartItem.promoUnitPrice,
        promoText: cartItem.promoText,
        modifierGroups: cartItem.modifierGroups.map(mapCartModifierGroupToOrderModifierGroup),
        note: cartItem.note,
        quantity: Number(cartItem.quantity),
        pickupTime: cartItem.pickupTime,
        addedAt: cartItem.addedAt,
        removedAt: cartItem.removedAt,
        salesUnit: cartItem.salesUnit,
        isSoldByWeight: cartItem.isSoldByWeight,
    };
}

function mapCartModifierGroupToOrderModifierGroup(modifierGroup: CartModifierGroupVm): ModifierGroupVm {
    return {
        name: modifierGroup.name,
        showModifierGroupNameInCommand: modifierGroup.showModifierGroupNameInCommand,
        freeModifiersQuantity: modifierGroup.freeModifiersQuantity,
        modifiers: modifierGroup.modifiers,
    };
}
