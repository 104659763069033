import { pidedirectokioskApiMethod } from 'src/api/config/pidedirectokioskApiMethod';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { App } from 'src/constants/App';
import type { DeviceId, RestaurantId } from 'src/types/Id';

export async function syncDeviceApi(request: SyncDeviceApiRequest): ApiSauceResponse<void> {
    return pidedirectokioskApiMethod('device/syncDeviceApi', request);
}

type SyncDeviceApiRequest = {
    deviceId: DeviceId;
    restaurantId?: RestaurantId;
    pideDirectoKioskVersion: string;
    app: App;
    languageTag?: string;
    platform?: string;
    screenWidth?: number;
    screenHeight?: number;
    webSoundPermissionAllowed?: boolean;
};
