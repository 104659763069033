import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { GoBackButton } from 'src/components/button/GoBackButton';
import { SubscribedPage } from 'src/components/page/SubscribedPage';
import { OrderSteps } from 'src/constants/OrderStep';
import { PaymentMethod, PaymentMethods } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';
import { CashIcon } from 'src/icons/CashIcon';
import { CreditCardIcon } from 'src/icons/CreditCardIcon';
import { MasterCardIcon } from 'src/icons/MasterCardIcon';
import { VisaIcon } from 'src/icons/VisaIcon';
import { appReducer } from 'src/reducers/appReducer';
import { useClearKiosk } from 'src/services/useClearKiosk';
import { useConfirmDialog } from 'src/services/useConfirmDialog';
import { RestaurantPaymentMethodVm } from 'src/types/RestaurantPaymentMethodVm';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { isCashPayment } from 'src/utils/paymentMethod/isCashPayment';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { isPidedirectoPayment } from 'src/utils/restaurantPaymentMethod/isPidedirectoPayment';

export function SelectRestaurantPaymentMethodPage(): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();
    const confirmDialog = useConfirmDialog();

    const restaurant = useSelector((state) => state.app.restaurant);
    const kioskPayWithCreditCardInCounterEnabled = useSelector((state) => state.app.restaurant?.kioskPayWithCreditCardInCounterEnabled);
    const newDeviceManagementEnabled = useSelector((state) => state.app.restaurant?.newDeviceManagementEnabled);
    const paymentTerminals = useSelector((state) => state.app.paymentTerminals) ?? [];
    const orderType = useSelector((state) => state.app.orderType);

    const setKioskPaymentMethod = useAction(appReducer.actions.setKioskPaymentMethod);
    const setOrderStep = useAction(appReducer.actions.setOrderStep);
    const clearKiosk = useClearKiosk();

    const cashPaymentMethod = restaurant?.restaurantPaymentMethods?.find(
        (restaurantPaymentMethod) => isPidedirectoPayment(restaurantPaymentMethod.restaurantPaymentMethodType) && isCashPayment(restaurantPaymentMethod.paymentMethod),
    );
    const creditCardPaymentMethod = restaurant?.restaurantPaymentMethods?.find(
        (restaurantPaymentMethod) => isPidedirectoPayment(restaurantPaymentMethod.restaurantPaymentMethodType) && isCardPayment(restaurantPaymentMethod.paymentMethod),
    );
    const terminalPaymentMethod = restaurant?.restaurantPaymentMethods?.find(
        (restaurantPaymentMethod) => isPidedirectoPayment(restaurantPaymentMethod.restaurantPaymentMethodType) && restaurantPaymentMethod.paymentMethod === PaymentMethods.PAYMENT_TERMINAL,
    );

    const selectPaymentMethod = async (payWithQpay: boolean) => {
        setKioskPaymentMethod(PaymentMethods.CREDIT_CARD);
        if (!payWithQpay) {
            setOrderStep(OrderSteps.COMPLETED);
            return;
        }
        setOrderStep(OrderSteps.CARD_PAYMENT);
    };

    const onSelectCashPaymentMethod = () => {
        setKioskPaymentMethod(PaymentMethods.CASH);
        setOrderStep(OrderSteps.COMPLETED);
    };

    const handleStartOver = async () => {
        const confirmStartOver = await confirmDialog({
            title: translate('Are you sure you want to start over?'),
            content: translate('You have items in your order, if you start over they will be lost'),
            cancelButtonText: translate('Cancel'),
            buttonText: translate('Yes, start over'),
        });

        if (!confirmStartOver) return;

        clearKiosk();
    };

    const getPaymentMethodText = (paymentMethod?: PaymentMethod) => {
        if (isCardPayment(paymentMethod)) {
            return translate('Pay with credit card in the counter');
        }

        if (isCashPayment(paymentMethod)) {
            return translate('Pay with cash in the counter');
        }
    };

    const getPaymentMethodIcon = (paymentMethod?: PaymentMethod) => {
        if (isCardPayment(paymentMethod)) {
            return <CreditCardIcon color={theme.palette.primary.main ?? '#FFFFFF'} width={'120'} height={'80'} />;
        }
        return <CashIcon width={'100'} height={'60'} />;
    };

    const isRestaurantPaymentMethodEnabled = (restaurantPaymentMethod?: RestaurantPaymentMethodVm) => {
        if (!orderType || !restaurantPaymentMethod?.enabled) return false;
        if (isCardPayment(restaurantPaymentMethod?.paymentMethod))
            return (kioskPayWithCreditCardInCounterEnabled || !restaurant?.kioskQpayTerminalDeviceId) && restaurantPaymentMethod?.orderTypes?.includes(orderType);
        if (restaurantPaymentMethod.paymentMethod === PaymentMethods.PAYMENT_TERMINAL)
            return !!restaurant?.kioskQpayTerminalDeviceId && !!newDeviceManagementEnabled && paymentTerminals.length > 0 && restaurantPaymentMethod?.orderTypes?.includes(orderType);
        return restaurantPaymentMethod?.orderTypes?.includes(orderType);
    };

    return (
        <SubscribedPage title={'Kiosk select payment method'} className={classes.container}>
            <GoBackButton />
            <h1 className={classes.title}>
                {translate(
                    isRestaurantPaymentMethodEnabled(cashPaymentMethod) || isRestaurantPaymentMethodEnabled(creditCardPaymentMethod) || isRestaurantPaymentMethodEnabled(terminalPaymentMethod)
                        ? translate('Where would you like to pay?')
                        : translate('No payment methods enabled'),
                )}
            </h1>
            <div className={classes.buttonsContainer}>
                {isRestaurantPaymentMethodEnabled(cashPaymentMethod) && (
                    <Button outlined classes={{ button: classes.button }} onClick={onSelectCashPaymentMethod}>
                        <div className={classes.iconsContainer}>{getPaymentMethodIcon(cashPaymentMethod?.paymentMethod)}</div>
                        <div className={classes.textContainer}>
                            <p className={classes.text}>{getPaymentMethodText(cashPaymentMethod?.paymentMethod)}</p>
                        </div>
                    </Button>
                )}
                {isRestaurantPaymentMethodEnabled(creditCardPaymentMethod) && (
                    <Button outlined classes={{ button: classes.button }} onClick={onSelectCashPaymentMethod}>
                        <div className={classes.iconsContainer}>{getPaymentMethodIcon(creditCardPaymentMethod?.paymentMethod)}</div>
                        <div className={classes.textContainer}>
                            <p className={classes.text}>{getPaymentMethodText(creditCardPaymentMethod?.paymentMethod)}</p>
                        </div>
                    </Button>
                )}
                {isRestaurantPaymentMethodEnabled(terminalPaymentMethod) && isRestaurantPaymentMethodEnabled(creditCardPaymentMethod) && (
                    <Button outlined classes={{ button: classes.button }} onClick={async () => await selectPaymentMethod(true)}>
                        <div className={classes.iconsContainer}>
                            <VisaIcon disabled={!isRestaurantPaymentMethodEnabled(terminalPaymentMethod)} />
                            <MasterCardIcon disabled={!isRestaurantPaymentMethodEnabled(terminalPaymentMethod)} />
                        </div>
                        <div className={classes.textContainer}>
                            <p className={classNames(classes.text, !isRestaurantPaymentMethodEnabled(terminalPaymentMethod) ? classes.textDisabled : '')}>{translate('Pay Here')}</p>
                            <p className={classNames(classes.text, !isRestaurantPaymentMethodEnabled(terminalPaymentMethod) ? classes.textDisabled : '')} style={{ marginTop: 0 }}>
                                {translate('(Only Card)')}
                            </p>
                        </div>
                    </Button>
                )}
            </div>
            <Button onClick={handleStartOver} outlined classes={{ button: classes.textButton }}>
                {translate('Start over')}
            </Button>
        </SubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        fontFamily: theme.typography.bold,
        fontSize: '6vh',
        textAlign: 'center',
        width: '90%',
        margin: '0 auto',
        textTransform: 'none',
        color: '#2C374A',
    },
    text: {
        fontFamily: theme.typography.semiBold,
        fontSize: '3.5vw',
        textAlign: 'center',
        marginTop: '1vh',
        textTransform: 'none',
        marginBottom: 0,
        color: theme.palette.primary.main,
    },
    textDisabled: {
        color: theme.palette.text.disabled,
    },
    buttonsContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '8vh',
    },
    buttonDisabled: {
        border: `2px solid ${theme.palette.text.disabled}`,
    },
    button: {
        padding: 0,
        width: '50%',
        minWidth: 0,
        borderRadius: '2vw',
        margin: '0 2vw',
        height: '30vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        border: `2px solid ${theme.palette.primary.main}`,
        position: 'relative',
        paddingTop: 20,
    },
    icon: {
        width: '100%',
    },
    iconsContainer: {
        width: '90%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        margin: '0 5%',
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    textButton: {
        margin: 50,
        padding: '40px 30px',
        color: theme.palette.text.secondary,
        fontSize: '3vw',
    },
}));
