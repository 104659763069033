/* eslint-disable react-hooks/rules-of-hooks */
import { BigNumber } from 'bignumber.js';
import { DiscountType } from 'src/constants/DiscountType';
import { OrderType, OrderTypes } from 'src/constants/OrderType';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import { RewardTypes } from 'src/constants/RewardType';
import type { CartItemVm } from 'src/types/CartItemVm';
import { MenuItemId, PromotionId, type CustomPaymentMethod } from 'src/types/Id';
import type { PromoCodeVm } from 'src/types/PromoCodeVm';
import { PromotionVm } from 'src/types/PromotionVm';
import { calculateBuyOneGetOnePromotionDiscount } from 'src/utils/cart/promotion/calculateBuyOneGetOnePromotionDiscount';
import { calculateOrderItemProductDiscount } from 'src/utils/order/calculateOrderItemProductDiscount';
import { calculateOrderItemSubtotalPrice } from 'src/utils/order/calculateOrderItemSubtotalPrice';
import { isSelfServiceOrder } from 'src/utils/order/isSelfServiceOrder';
import { calculatePromoCodeDiscount } from 'src/utils/promoCode/calculatePromoCodeDiscount';
import { isBuyOneGetOnePromotion } from 'src/utils/promotion/isBuyOneGetOnePromotion';
import { zeroStringToUndefined } from 'src/utils/string/zeroStringToUndefined';

export function createPaymentDistribution(request: Request): Payment {
    const payment = createEmptyPayment();
    calculateSubtotal(payment, request);
    calculateProductDiscount(payment, request);
    calculatePromotionsDiscount(payment, request);
    calculateTax(payment, request);
    usePromoCode(payment, request);
    useCustomerCredits(payment, request);

    cleanPayment(payment);
    return payment;
}

function createEmptyPayment(): Payment {
    return {
        subtotal: '0',
        total: '0',
        usedPromotions: [],
    };
}

function calculateSubtotal(payment: Payment, request: Request): void {
    const { orderItems } = request;
    orderItems.forEach((orderItem) => {
        const orderItemSubtotal = calculateOrderItemSubtotalPrice(orderItem);
        payment.subtotal = BigNumber(payment.subtotal).plus(orderItemSubtotal).toString();
        payment.total = payment.subtotal;
    });
}

function calculateProductDiscount(payment: Payment, request: Request): void {
    const { orderItems } = request;
    orderItems.forEach((orderItem) => {
        const orderItemProductDiscount = calculateOrderItemProductDiscount(orderItem);
        payment.productDiscount = BigNumber(payment.productDiscount ?? 0)
            .plus(orderItemProductDiscount)
            .toString();
        payment.total = BigNumber(payment.total)
            .minus(orderItemProductDiscount ?? 0)
            .toString();
    });
}

function calculatePromotionsDiscount(payment: Payment, request: Request): void {
    if (!request.promotions?.length) return;

    const validPromotions = request.promotions.filter((promotion) => promotion.orderTypes.includes(isSelfServiceOrder(request.orderType) ? OrderTypes.TABLE_ORDER : request.orderType!));

    for (const promotion of validPromotions) {
        if (isBuyOneGetOnePromotion(promotion.promotionType)) calculateBuyOneGetOnePromotionDiscount(payment, request, promotion);
    }
}

function usePromoCode(payment: Payment, request: Request): void {
    const { promoCode, orderType, paymentMethod, orderItems } = request;
    if (!promoCode) return;

    payment.promoCodeSubtotal = payment.total;
    const promoCodeDiscount = calculatePromoCodeDiscount(promoCode, orderType, paymentMethod, payment.total, orderItems);
    if (promoCode.rewardType === RewardTypes.CREDITS) {
        payment.promoCodeCredits = promoCodeDiscount;
    } else {
        payment.promoCodeDiscount = promoCodeDiscount;
        payment.total = BigNumber(payment.total)
            .minus(payment.promoCodeDiscount ?? 0)
            .toString();
    }
    payment.promoCode = promoCode.code;
}

function calculateTax(payment: Payment, request: Request): void {
    const { taxRate } = request;
    if (!taxRate) return;

    payment.tax = BigNumber(payment.total)
        .multipliedBy(taxRate ?? 0)
        .dividedBy(100)
        .decimalPlaces(2)
        .toString();
    payment.total = BigNumber(payment.total)
        .plus(payment.tax ?? 0)
        .toString();
}

function useCustomerCredits(payment: Payment, request: Request): void {
    const { usedCustomerCredits } = request;

    if (!usedCustomerCredits) return;

    let creditsToUse = BigNumber(usedCustomerCredits ?? 0)
        .minus(payment.usedNonGiftCredits ?? 0)
        .toString();

    if (BigNumber(creditsToUse).isZero()) return;
    if (BigNumber(payment.total).isZero() && !BigNumber(creditsToUse).isNegative()) return;
    if (BigNumber(creditsToUse).isGreaterThanOrEqualTo(payment.total)) {
        creditsToUse = payment.total;
    }

    payment.usedNonGiftCredits = BigNumber(payment.usedNonGiftCredits ?? 0)
        .plus(creditsToUse)
        .toString();
    payment.usedLetsEatCredits = BigNumber(payment.usedLetsEatCredits ?? 0)
        .plus(creditsToUse)
        .toString();
    payment.usedCredits = BigNumber(payment.usedCredits ?? 0)
        .plus(creditsToUse)
        .toString();

    payment.total = BigNumber(payment.total).minus(creditsToUse).toString();
}

function cleanPayment(payment: Payment): void {
    payment.productDiscount = zeroStringToUndefined(payment.productDiscount);
    payment.promoCodeSubtotal = zeroStringToUndefined(payment.promoCodeSubtotal);
    payment.promoCodeDiscount = zeroStringToUndefined(payment.promoCodeDiscount);
    payment.promoCodeCredits = zeroStringToUndefined(payment.promoCodeCredits);
    payment.promoCode = zeroStringToUndefined(payment.promoCode);
    payment.usedNonGiftCredits = zeroStringToUndefined(payment.usedNonGiftCredits);
    payment.usedLetsEatCredits = zeroStringToUndefined(payment.usedLetsEatCredits);
    payment.usedCredits = zeroStringToUndefined(payment.usedCredits);
}

export type Request = {
    orderItems: Array<CartItemVm>;
    discount?: {
        discountType: DiscountType;
        discount: string;
        discountPercentage?: string;
        notes?: string;
    };
    taxRate?: string;
    promoCode?: PromoCodeVm;
    promotions?: Array<PromotionVm>;
    orderType?: OrderType;
    paymentMethod?: PaymentMethod | CustomPaymentMethod;
    usedCustomerCredits?: string;
};

export type Payment = {
    subtotal: string;
    total: string;
    tax?: string;
    productDiscount?: string;
    posDiscount?: string;
    posDiscountPercentage?: string;
    promoCodeSubtotal?: string;
    promoCodeDiscount?: string;
    promoCodeCredits?: string;
    promoCode?: string;
    promotionsDiscount?: string;
    usedNonGiftCredits?: string;
    usedLetsEatCredits?: string;
    usedCredits?: string;
    usedPromotions: Array<{
        promotionId: PromotionId;
        menuItemId: MenuItemId;
        cartItemKey?: string;
    }>;
};
