import { makeStyles } from '@material-ui/core';
import { useEffect } from 'react';
import * as React from 'react';
import { CircularLoadingIndicator } from 'src/components/page/CircularLoadingIndicator';
import { SubscribedPage } from 'src/components/page/SubscribedPage';
import { SECONDS } from 'src/constants/TimeUnit';
import { SuccessOrders } from 'src/scenes/SuccessOrders';
import { useClearKiosk } from 'src/services/useClearKiosk';
import { useSelector } from 'src/utils/react/useSelector';

export function OrderCompletedSummaryPage(): React.ReactElement {
    const classes = useStyles();

    const orders = useSelector((state) => state.app.orders);

    const clearKiosk = useClearKiosk();

    useEffect(() => {
        const timeout: NodeJS.Timeout = setTimeout(() => {
            clearKiosk();
        }, 5 * SECONDS);

        return () => window.clearTimeout(timeout);
    }, []);

    if (!orders) return <CircularLoadingIndicator />;

    return (
        <SubscribedPage title={'Order summary'}>
            <div className={classes.container}>
                <SuccessOrders />
            </div>
        </SubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
    },
    title: {
        fontFamily: theme.typography.bold,
        fontSize: '7vw',
        textAlign: 'center',
        width: '90%',
        margin: '0 auto',
        color: '#2C374A',
    },
    icon: {
        margin: '5vh 0',
    },
    subtitle: {
        fontFamily: theme.typography.bold,
        fontSize: '4vw',
        textAlign: 'center',
        width: '90%',
        margin: '0 auto',
        color: '#2C374A',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: '3.5vw',
        textAlign: 'center',
        width: '70%',
        margin: '0px auto',
        marginTop: 40,
        color: '#2C374A',
    },
    iconBackground: {
        backgroundColor: '#0A8972',
        borderRadius: '15vw',
        width: '25vw',
        height: '25vw',
        marginBottom: '5vh',
        position: 'relative',
    },
    hours: {
        width: '10vw',
        height: '3vw',
        borderRadius: '5vw',
        backgroundColor: 'white',
        top: '13.5vw',
        left: '11vw',
        margin: 0,
        position: 'absolute',
        transformOrigin: 'top left',
        transform: 'rotate(-90deg)',
    },
    minutes: {
        width: '8vw',
        height: '3vw',
        borderRadius: '5vw',
        backgroundColor: 'white',
        top: '10.5vw',
        left: '12.5vw',
        margin: 0,
        position: 'absolute',
        transformOrigin: 'top left',
        transform: 'rotate(45deg)',
    },
}));
