import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Text } from 'src/components/app/Text';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { ServiceErrorIcon } from 'src/icons/ServiceErrorIcon';
import { useClearKiosk } from 'src/services/useClearKiosk';
import { useSelector } from 'src/utils/react/useSelector';

export function ServiceSuspended(): React.ReactElement {
    const classes = useStyles();

    const companyId = useSelector((state) => state.app.companyId);

    const clearKiosk = useClearKiosk();

    return (
        <div className={classes.container}>
            <Text title>{translate('Service suspended')}</Text>
            <div className={classes.content}>
                <ServiceErrorIcon title={translate('Service suspended icon')} />
                {!!companyId && <Text>{translate("Contact your Let's Eat representative to reactivate it")}</Text>}
                {!companyId && <Text>{translate('Pay your bill to continue using your service')}</Text>}
            </div>
            <Button classes={{ button: classes.button }} onClick={clearKiosk}>
                {translate('Back to home')}
            </Button>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        padding: '0 15%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        textAlign: 'center',
        gap: '2vh',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        textAlign: 'center',
        gap: '1vh',
    },
    button: {
        marginTop: '3vh',
        fontSize: '4vw',
        width: '100%',
        padding: '1.5vh 2.5vh',
        borderRadius: '1vw',
    },
    bold: {
        fontWeight: 'bold',
    },
}));
