import * as React from 'react';
import { LanguageDropDown } from 'src/components/app/LanguageDropDown';
import { AppContainer } from 'src/components/page/AppContainer';
import { CircularLoadingIndicator } from 'src/components/page/CircularLoadingIndicator';
import { HeaderBar } from 'src/components/page/HeaderBar';
import { PickUpStationSteps } from 'src/scenes/PickUpStationSteps';
import { RestaurantSteps } from 'src/scenes/RestaurantSteps';
import { useGetAppContext } from 'src/services/useGetAppContext';
import { useIsPickupStationUser } from 'src/services/useIsPickupStationUser';

export function KioskPage(): React.ReactElement {
    const [loading] = useGetAppContext();
    const isPickupStationUser = useIsPickupStationUser();

    if (loading) return <CircularLoadingIndicator />;

    return (
        <AppContainer>
            <LanguageDropDown />
            <HeaderBar />
            {!isPickupStationUser && <RestaurantSteps />}
            {isPickupStationUser && <PickUpStationSteps />}
        </AppContainer>
    );
}
