import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { ItemQuantitySpinner } from 'src/components/button/ItemQuantitySpinner';
import { NotFoundCategoryImageIcon } from 'src/icons/NotFoundCategoryImageIcon';
import { appReducer } from 'src/reducers/appReducer';
import { useAddItem } from 'src/services/order/useAddItem';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { CartItemVm } from 'src/types/CartItemVm';
import { calculateOrderItemPrice } from 'src/utils/order/calculateOrderItemPrice';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function CartItem({ cartItem, items }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [addItem] = useAddItem();

    const menuCategorySelected = useSelector((state) => state.app.menuCategorySelected);

    const removeOneKioskItem = useAction(appReducer.actions.removeOneKioskItem);

    const addOrderItem = () => {
        addItem({
            menuItemId: cartItem.menuItemId,
            menuItemType: cartItem.menuItemType,
            menuCategoryId: menuCategorySelected?.menuCategoryId,
            name: cartItem.name,
            unitPrice: cartItem.unitPrice,
            promoUnitPrice: cartItem.promoUnitPrice,
            promoText: cartItem.promoText,
            size: cartItem.size,
            imageUrl: cartItem.imageUrl,
            quantity: 1,
            note: cartItem.note,
            modifierGroups: cartItem.modifierGroups,
            pickupTime: cartItem.pickupTime,
        });
    };

    const handleSubtract = () => {
        if (cartItem?.quantity === 0) return;

        removeOneKioskItem(cartItem);
    };
    return (
        <div className={classes.item}>
            {!!cartItem?.imageUrl && <img alt={`${cartItem?.name} image`} className={classes.image} src={cartItem?.imageUrl} />}
            {!cartItem?.imageUrl && (
                <div className={classNames(classes.image)}>
                    <NotFoundCategoryImageIcon title={'item not found'} />
                </div>
            )}
            <div className={classes.itemSummary}>
                <div>{cartItem?.name}</div>
                {cartItem.modifierGroups?.map((modifierGroup) => (
                    <div className={classes.modifierGroups}>
                        {modifierGroup.showModifierGroupNameInCommand && <div>{modifierGroup.name}</div>}
                        {modifierGroup.modifiers?.map((modifier) => (
                            <span key={modifier.name} className={classes.modifier}>
                                {`${modifierGroup.showModifierGroupNameInCommand ? '* ' : ''}${modifier.quantity}x ${modifier.name}`}
                            </span>
                        ))}
                    </div>
                ))}
            </div>
            <div className={classes.quantityContainer}>
                <span style={{ textAlign: 'right', width: 'max-content' }}>{formatAsCurrencyNumber(calculateOrderItemPrice(cartItem))}</span>
                <ItemQuantitySpinner classes={{ button: classes.button }} quantity={cartItem?.quantity} onSubtract={handleSubtract} onAdd={addOrderItem} />
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    item: {
        display: 'flex',
        width: '100%',
        color: '#2E3748',
        fontSize: '2vw',
        fontFamily: theme.typography.regular,
        padding: '30px 2vw',
    },
    image: {
        width: '20%',
        borderRadius: 12,
    },
    modifierGroups: {
        fontFamily: theme.typography.regular,
        display: 'flex',
        flexDirection: 'column',
        fontSize: '2.5vw',
    },
    modifier: {
        color: '#8A95A5',
        fontSize: '2.2vw',
    },
    itemSummary: {
        width: '50%',
        paddingLeft: 30,
        fontSize: '3vw',
        fontFamily: theme.typography.medium,
    },
    quantityContainer: {
        fontSize: '3vw',
        fontFamily: theme.typography.medium,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        color: '#06B7A2',
        maxHeight: '70%',
    },
    button: {
        width: '5vw',
    },
}));

type Props = {
    cartItem: CartItemVm;
    items: Array<CartItemVm>;
};
