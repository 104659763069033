import { useRef, useState } from 'react';
import { pollPaymentTerminalPaymentApi, PollPaymentTerminalPaymentApiResponse } from 'src/api/pidedirectokiosk/paymentTerminalPayment/pollPaymentTerminalPaymentApi';
import { PaymentTerminalPaymentResultMock, pushPaymentTerminalPaymentApi } from 'src/api/pidedirectokiosk/paymentTerminalPayment/pushPaymentTerminalPaymentApi';
import { PaymentTerminalPaymentFailedReasons } from 'src/constants/PaymentTerminalPaymentFailedReason';
import { PaymentTerminalPaymentStatuses } from 'src/constants/PaymentTerminalPaymentStatus';
import type { PaymentTerminalProvider } from 'src/constants/PaymentTerminalProvider';
import { SECONDS } from 'src/constants/TimeUnit';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { useOpenSelectPaymentTerminalPaymentResultMockDialog } from 'src/scenes/checkout/SelectPaymentTerminalPaymentResultMockDialog';
import { useDeveloperMode } from 'src/services/developerMode/useDeveloperMode';
import { usePayWithPaymentTerminalDeprecatedService } from 'src/services/paymentTerminal/deprecated/usePayWithPaymentTerminalDeprecatedService';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { useConfirmDialog } from 'src/services/useConfirmDialog';
import type { PaymentTerminalId, PaymentTerminalPaymentId } from 'src/types/Id';
import type { PaymentTerminalVm } from 'src/types/PaymentTerminalVm';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useTimeoutInterval } from 'src/utils/react/useTimeoutInterval';
import { isPaymentTerminalDevice } from 'src/utils/reactNative/isPaymentTerminalDevice';

export function usePayWithPaymentTerminal(): Service {
    const payWithPaymentTerminalService = usePayWithPaymentTerminalService();
    const payWithPaymentTerminalDeprecatedService = usePayWithPaymentTerminalDeprecatedService();

    const newDeviceManagementEnabled = useSelector((state) => state.app.restaurant?.newDeviceManagementEnabled);

    if (!newDeviceManagementEnabled) {
        return payWithPaymentTerminalDeprecatedService;
    }

    return payWithPaymentTerminalService;
}

export function usePayWithPaymentTerminalService(): Service {
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();
    const confirmDialog = useConfirmDialog();

    const paymentResponseResolve = useRef((result: PaymentResult) => {});

    const [loading, setLoading] = useState(false);
    const [paymentTerminalPaymentId, setPaymentTerminalPaymentId] = useState<PaymentTerminalPaymentId>();

    const deviceGroup = useSelector((state) => state.app.deviceGroup);
    const paymentTerminals = useSelector((state) => state.app.paymentTerminals) ?? [];
    const qpayPosTerminalDeviceId = undefined; // this is not applicable since this webapp can never be installed on qpay terminal and therefor it will always be undefined, kept to keep in sync with admin payment service

    const openSelectPaymentTerminalDialog = useAction(appReducer.actions.openSelectPaymentTerminalDialog);
    const setPaying = useAction(appReducer.actions.setPaying);
    const developerMode = useDeveloperMode();

    const openSelectPaymentTerminalPaymentResultMockDialog = useOpenSelectPaymentTerminalPaymentResultMockDialog();

    useTimeoutInterval(
        async () => {
            if (!paymentTerminalPaymentId) return;

            const response = await pollPaymentTerminalPaymentApi({ paymentTerminalPaymentId: paymentTerminalPaymentId });

            if (!response.ok) {
                console.log(`Failed silently, trying again in 5 seconds response`, response);
                return;
            }

            if (response.data.paymentTerminalPaymentStatus === PaymentTerminalPaymentStatuses.FAILED) {
                await onPaymentFailed(response.data.message ?? translate('Failed to do the payment.'));
                return;
            }

            if (response.data.paymentTerminalPaymentStatus === PaymentTerminalPaymentStatuses.PAID) {
                await onPaymentSuccess(response.data);
            }
        },
        2 * SECONDS,
        [paymentTerminalPaymentId],
    );

    const payWithPaymentTerminal = ({ paymentTerminalId, amount }: { paymentTerminalId?: PaymentTerminalId; amount: string }): Promise<PaymentResult> => {
        if (paymentTerminalId) {
            return payInPaymentTerminal({ paymentTerminalId, amount });
        }

        const paymentTerminalsInDeviceGroup = paymentTerminals.filter((paymentTerminal) => deviceGroup?.paymentTerminalIds?.includes(paymentTerminal.paymentTerminalId));

        if (paymentTerminalsInDeviceGroup.length > 1) {
            return selectPaymentTerminalAndPay({
                header: translate('Select a terminal to pay @amount', { amount: formatAsCurrencyNumber(amount) }),
                amount,
            });
        }

        const paymentTerminalIdFromDeviceGroup = paymentTerminalsInDeviceGroup[0]?.paymentTerminalId;
        return payInPaymentTerminal({ paymentTerminalId: paymentTerminalIdFromDeviceGroup, amount });
    };

    const payInPaymentTerminal = async ({ paymentTerminalId, amount }: { paymentTerminalId?: PaymentTerminalId; amount: string }): Promise<PaymentResult> => {
        return new Promise(async (resolve) => {
            paymentResponseResolve.current = resolve;

            if (!paymentTerminalId) {
                await onPaymentFailed(translate('Terminal not configured'));
                return;
            }

            let mock: PaymentTerminalPaymentResultMock | undefined;
            if (developerMode) {
                mock = await new Promise((resolve) => {
                    openSelectPaymentTerminalPaymentResultMockDialog({
                        onSelectMock: async (mock: PaymentTerminalPaymentResultMock) => {
                            resolve(mock);
                        },
                        onCloseDialog: () => {
                            resolve(undefined);
                        },
                    });
                });
                if (!mock) {
                    setLoading(false);
                    setPaying(false);
                    resolve({ paid: false });
                    return;
                }
            }

            setLoading(true);
            setPaying(true);
            const paymentTerminal = paymentTerminals.find((paymentTerminal) => paymentTerminal.paymentTerminalId === paymentTerminalId);
            const response = await pushPaymentTerminalPaymentApi({
                paymentTerminalId,
                amount,
                apphook: isPaymentTerminalDevice() && paymentTerminal?.qpayTerminalDeviceId === qpayPosTerminalDeviceId ? 'pidedirectoadminapp' : undefined,
                mock,
            });

            if (!response.ok) {
                await onPaymentFailed(response.data?.message ? translate('Something went wrong @error', { error: response.data.message }) : translate('Something went wrong'));
                return;
            }

            const paymentTerminalPaymentStatus = response.data?.paymentTerminalPaymentStatus;

            if (paymentTerminalPaymentStatus === PaymentTerminalPaymentStatuses.FAILED) {
                const paymentTerminalPaymentFailedReason = response.data?.paymentTerminalPaymentFailedReason;

                if (paymentTerminalPaymentFailedReason === PaymentTerminalPaymentFailedReasons.TERMINAL_BUSY) {
                    await onPaymentFailed(translate('Terminal busy, please wait a moment and try again'));
                    return;
                }

                if (paymentTerminalPaymentFailedReason === PaymentTerminalPaymentFailedReasons.TERMINAL_NOT_FOUND) {
                    await onPaymentFailed(translate('Terminal not found, check your configuration and try again'));
                    return;
                }

                // should not happen, in case it happens create a PaymentTerminalPaymentFailedReasons for it and handle with if case above
                await onPaymentFailed(translate(response.data.message ? translate('Something went wrong @error', { error: response.data.message }) : translate('Something went wrong')));
                return;
            }

            paymentResponseResolve.current = resolve;
            setPaymentTerminalPaymentId(response.data.paymentTerminalPaymentId);
        });
    };

    const onPaymentFailed = async (message: string) => {
        setLoading(false);
        setPaying(false);
        setPaymentTerminalPaymentId(undefined);
        await confirmDialog({
            title: translate('Error'),
            content: message,
            buttonText: translate('Accept'),
            variant: 'error',
            timeoutSeconds: 15,
        });
        paymentResponseResolve.current({ paid: false });
        paymentResponseResolve.current = (paymentResult) => {};
    };

    const onPaymentSuccess = async (response: PollPaymentTerminalPaymentApiResponse) => {
        setLoading(false);
        setPaying(false);

        paymentResponseResolve.current({
            paid: true,
            paymentTerminalPaymentId,
            paymentTerminalId: response.paymentTerminalId,
            paymentTerminalProvider: response.paymentTerminalProvider,
            cardNumber: response.cardNumber,
            cardType: response.cardType,
        });
        paymentResponseResolve.current = (paymentResult) => {};
        setPaymentTerminalPaymentId(undefined);
    };

    const selectPaymentTerminalAndPay = async ({ header, amount }: SelectPaymentTerminalAndPayParams): Promise<PaymentResult> => {
        return await new Promise((resolve) => {
            openSelectPaymentTerminalDialog({
                header,
                onSelectPaymentTerminal: async (paymentTerminal: PaymentTerminalVm) => {
                    const paymentResponse = await payInPaymentTerminal({
                        amount,
                        paymentTerminalId: paymentTerminal.paymentTerminalId,
                    });
                    if (!paymentResponse.paid) {
                        resolve(await selectPaymentTerminalAndPay({ header, amount: amount }));
                        return;
                    }

                    resolve(paymentResponse);
                },
                onCloseDialog: () => {
                    resolve({ paid: false });
                },
            });
        });
    };

    const cancelPayment = () => {
        setPaymentTerminalPaymentId(undefined);
    };

    return { payWithPaymentTerminal, cancelPayment, loading };
}

type Service = {
    payWithPaymentTerminal: PayWithPaymentTerminalFunction;
    cancelPayment: any;
    loading: boolean;
};

type PayWithPaymentTerminalFunction = (arg1: {
    paymentTerminalId?: PaymentTerminalId; // If not provided device group will decide what paymentTerminal to use for payments,
    amount: string;
}) => Promise<PaymentResult>;

type PaymentResult = {
    paid: boolean;
    paymentTerminalPaymentId?: PaymentTerminalPaymentId;
    paymentTerminalId?: PaymentTerminalId;
    paymentTerminalProvider?: PaymentTerminalProvider;
    cardNumber?: string;
    cardType?: string;
};

type SelectPaymentTerminalAndPayParams = {
    header?: string;
    amount: string;
};
