import { BigNumber } from 'bignumber.js';
import type { CartItemVm } from 'src/types/CartItemVm';
import { calculateOrderItemModifierGroupsPrice } from 'src/utils/order/calculateOrderItemModifierGroupsPrice';

export function calculateOrderItemPriceWithPromo(orderItem: CartItemVm): string {
    const priceWithoutOptions = BigNumber(orderItem.promoUnitPrice ?? orderItem.unitPrice);
    const modifierGroupsPrice = calculateOrderItemModifierGroupsPrice(orderItem.modifierGroups);

    return priceWithoutOptions.plus(modifierGroupsPrice).multipliedBy(orderItem.quantity).toString();
}
