import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { createOrderApi } from 'src/api/pidedirectokiosk/order/createOrderApi';
import { Button } from 'src/components/Button';
import { Form } from 'src/components/form/Form';
import { FormPasswordField } from 'src/components/form/FormPasswordField';
import { SubscribedPage } from 'src/components/page/SubscribedPage';
import { OrderSteps } from 'src/constants/OrderStep';
import { OrderTypes } from 'src/constants/OrderType';
import { PaymentMethods } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { PaymentSummary } from 'src/scenes/payment/PaymentSummary';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { usePageLoader } from 'src/services/usePageLoader';
import { OrderPaymentVm } from 'src/types/OrderPaymentVm';
import { removeDuplicates } from 'src/utils/array/removeDuplicates';
import { mapCartItemToOrderItem } from 'src/utils/order/mapCartItemToOrderItem';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function PaymentPage(): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();
    const { setError } = form;

    const { showLoader, closeLoader } = usePageLoader();

    const total = useSelector((state) => state.app.payment?.total);
    const usedPromotions = useSelector((state) => state.app.payment?.usedPromotions);
    const customerEmployee = useSelector((state) => state.app.customerEmployee);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const companyId = useSelector((state) => state.app.companyId);
    const items = useSelector((state) => state.app.items);
    const orderPayment = useSelector<OrderPaymentVm | undefined>((state) => state.app.orderPayment);

    const setOrders = useAction(appReducer.actions.setOrders);
    const setOrderStep = useAction(appReducer.actions.setOrderStep);

    const handlePay = async (form: any) => {
        if (!restaurantId) return;

        showLoader(translate('Creating order'));

        const response = await createOrderApi({
            restaurantId,
            promotionIds: removeDuplicates(usedPromotions?.map((usedPromotion) => usedPromotion.promotionId)),
            companyId,
            customerId: customerEmployee?.customerId,
            mobileNumber: customerEmployee?.mobileNumber,
            firstName: customerEmployee?.firstName,
            lastName: customerEmployee?.lastName,
            email: customerEmployee?.email,
            password: form.password,
            orderType: OrderTypes?.PICKUP_STATION_ORDER,
            paymentMethod: PaymentMethods.CREDIT_CARD,
            orderItems: items?.map(mapCartItemToOrderItem),
            pickupTime: new Date(),
            payments: orderPayment ? [orderPayment] : [],
        });
        closeLoader();

        if (!response.ok) {
            if (response.status === 401) {
                setError('password', { type: 'custom', message: translate('Wrong password') });
                return;
            }
        }

        setOrders(response.data ?? []);
        setOrderStep(OrderSteps.COMPLETED);
    };

    return (
        <SubscribedPage title={'Payment'}>
            <div className={classes.container}>
                <PaymentSummary />
                <Form form={form} className={classes.form} onSubmit={handlePay}>
                    <FormPasswordField
                        name={'password'}
                        required
                        label={translate('Password')}
                        classes={{ input: classes.input, label: classes.label, helperText: classes.helperText, container: classes.inputWrapper, inputContainer: classes.inputContainer }}
                    />
                    <Button classes={{ button: classes.button }} type='submit' disabled={form.formState.isSubmitting}>
                        {`${translate('Pay')} ${formatAsCurrencyNumber(total)}`}
                    </Button>
                </Form>
            </div>
        </SubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        padding: '10vh 16vw',
        backgroundColor: 'white',
    },
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 50,
    },
    input: {
        width: '80vw',
        height: '4vh',
        borderWidth: '2px',
        fontSize: '3.5vw',
        padding: '2.5vh 2vw',
        borderRadius: '0vw',
    },
    label: {
        fontSize: '2vw',
        color: '#0D3037',
    },
    error: {
        color: '#C7413A',
        fontFamily: theme.typography.regular,
        fontSize: '2vw',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
    },
    helperText: {
        fontSize: '2vw',
    },
    inputWrapper: {
        marginTop: '5vh',
        width: '100%',
    },
    inputContainer: {
        marginTop: '1vh',
        width: '100%',
        height: '4vh',
        borderRadius: 8,
    },
    button: {
        fontSize: '2.5vw',
        width: '100%',
        height: '4vh',
        borderRadius: 12,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        flexDirection: 'column',
        border: 0,
    },
}));
