import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Text } from '@pidedirecto/ui';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { GoBackButton } from 'src/components/button/GoBackButton';
import { SubscribedPage } from 'src/components/page/SubscribedPage';
import { OrderSteps } from 'src/constants/OrderStep';
import { PaymentMethods } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';
import { CashIcon } from 'src/icons/CashIcon';
import { CreditCardIcon } from 'src/icons/CreditCardIcon';
import { MasterCardIcon } from 'src/icons/MasterCardIcon';
import { VisaIcon } from 'src/icons/VisaIcon';
import { appReducer } from 'src/reducers/appReducer';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { useClearKiosk } from 'src/services/useClearKiosk';
import { useConfirmDialog } from 'src/services/useConfirmDialog';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function DeprecatedSelectPaymentMethodPage(): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();
    const confirmDialog = useConfirmDialog();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const restaurant = useSelector((state) => state.app.restaurant);

    const { cashPaymentsEnabled, creditCardPaymentsEnabled, kioskPayWithCreditCardInCounterEnabled } = restaurant ?? {};
    const hasPaymentMethodsEnabled = cashPaymentsEnabled || creditCardPaymentsEnabled;
    const newDeviceManagementEnabled = useSelector((state) => state.app.restaurant?.newDeviceManagementEnabled);
    const paymentTerminals = useSelector((state) => state.app.paymentTerminals) ?? [];
    const total = useSelector((state) => state.app.payment?.total);

    const paymentWithQPayTerminalEnabled = !!restaurant?.kioskQpayTerminalDeviceId || (newDeviceManagementEnabled && paymentTerminals.length);
    const showPayWithCreditCardInCounter = creditCardPaymentsEnabled && (kioskPayWithCreditCardInCounterEnabled || !restaurant?.kioskQpayTerminalDeviceId);

    const setKioskPaymentMethod = useAction(appReducer.actions.setKioskPaymentMethod);
    const setOrderStep = useAction(appReducer.actions.setOrderStep);
    const clearKiosk = useClearKiosk();

    const handleCard = async (payWithQpay: boolean) => {
        setKioskPaymentMethod(PaymentMethods.CREDIT_CARD);
        if (!payWithQpay) {
            setOrderStep(OrderSteps.COMPLETED);
            return;
        }
        setOrderStep(OrderSteps.CARD_PAYMENT);
    };

    const handleCash = () => {
        setKioskPaymentMethod(PaymentMethods.CASH);
        setOrderStep(OrderSteps.COMPLETED);
    };

    const handleStartOver = async () => {
        const confirmStartOver = await confirmDialog({
            title: translate('Are you sure you want to start over?'),
            content: translate('You have items in your order, if you start over they will be lost'),
            cancelButtonText: translate('Cancel'),
            buttonText: translate('Yes, start over'),
        });

        if (!confirmStartOver) return;

        clearKiosk();
    };

    return (
        <SubscribedPage title={'Kiosk select payment method'} className={classes.container}>
            <GoBackButton />
            <h1 className={classes.title}>{translate(hasPaymentMethodsEnabled ? 'Where would you like to pay?' : 'No payment methods enabled')}</h1>
            <div className={classes.totalContainer}>
                <Text className={classes.total}>{formatAsCurrencyNumber(total)}</Text>
                <Text className={classes.totalText}>{translate('Total to pay')}</Text>
            </div>
            <div className={classes.buttonsContainer}>
                {paymentWithQPayTerminalEnabled && creditCardPaymentsEnabled && (
                    <Button outlined classes={{ button: classes.button }} onClick={async () => await handleCard(true)}>
                        <div className={classes.iconsContainer}>
                            <VisaIcon disabled={!paymentWithQPayTerminalEnabled} />
                            <MasterCardIcon disabled={!paymentWithQPayTerminalEnabled} />
                        </div>
                        <div className={classes.textContainer}>
                            <p className={classNames(classes.text, !paymentWithQPayTerminalEnabled ? classes.textDisabled : '')}>{translate('Pay Here')}</p>
                            <p className={classNames(classes.text, !paymentWithQPayTerminalEnabled ? classes.textDisabled : '')} style={{ marginTop: 0 }}>
                                {translate('(Only Card)')}
                            </p>
                        </div>
                    </Button>
                )}
                {showPayWithCreditCardInCounter && (
                    <Button outlined classes={{ button: classes.button }} onClick={async () => await handleCard(false)}>
                        <div className={classes.iconsContainer}>
                            <CreditCardIcon color={theme.palette.primary.main ?? '#FFFFFF'} width={'120'} height={'80'} />
                        </div>
                        <div className={classes.textContainer}>
                            <p className={classes.text}>{translate('Pay with credit card in the counter')}</p>
                        </div>
                    </Button>
                )}
                {cashPaymentsEnabled && (
                    <Button outlined classes={{ button: classes.button }} onClick={handleCash}>
                        <div className={classes.iconsContainer}>
                            <CashIcon width={'100'} height={'60'} />
                        </div>
                        <div className={classes.textContainer}>
                            <p className={classes.text}>{translate('Pay with cash in the counter')}</p>
                        </div>
                    </Button>
                )}
            </div>
            <Button onClick={handleStartOver} outlined classes={{ button: classes.textButton }}>
                {translate('Start over')}
            </Button>
        </SubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        fontFamily: theme.typography.bold,
        fontSize: '6vh',
        textAlign: 'center',
        width: '90%',
        margin: '0 auto',
        textTransform: 'none',
        color: '#2C374A',
    },
    text: {
        fontFamily: theme.typography.semiBold,
        fontSize: '3.5vw',
        textAlign: 'center',
        marginTop: '1vh',
        textTransform: 'none',
        marginBottom: 0,
        color: theme.palette.primary.main,
    },
    textDisabled: {
        color: theme.palette.text.disabled,
    },
    buttonsContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '4vh',
    },
    buttonDisabled: {
        border: `2px solid ${theme.palette.text.disabled}`,
    },
    button: {
        padding: 0,
        width: '50%',
        minWidth: 0,
        borderRadius: '2vw',
        margin: '0 2vw',
        height: '30vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        border: `2px solid ${theme.palette.primary.main}`,
        position: 'relative',
        paddingTop: 20,
    },
    icon: {
        width: '100%',
    },
    iconsContainer: {
        width: '90%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        margin: '0 5%',
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    textButton: {
        margin: 50,
        padding: '40px 30px',
        color: theme.palette.text.secondary,
        fontSize: '3vw',
    },
    totalContainer: {
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '4vh',
    },
    total: {
        fontFamily: theme.typography.bold,
        fontSize: '3vh',
    },
    totalText: {
        fontFamily: theme.typography.regular,
        fontSize: '2vh',
    },
}));
