import { createMuiTheme } from '@material-ui/core/styles';

export const themeOptions = {
    typography: {
        htmlFontSize: 10,
        fontFamily: 'Roboto, sans-serif',
        bold: 'PoppinsBold',
        semiBold: 'PoppinsSemiBold',
        regular: 'PoppinsRegular',
        medium: 'PoppinsMedium',
        light: 'PoppinsLight',
    },
    palette: {
        primary: {
            light: '#dbffe9',
            main: '#64d6b3',
            mainHover: '#198074',
            dark: '#0097aa',
            contrastText: '#fff',
        },
        secondary: {
            light: '#fafbfb',
            main: '#3affc7',
            mainHover: '#198074',
            dark: '#e5e5e5',
            contrastText: '#40464d',
        },
        outlined: {
            mainHover: '#EBFFF9',
        },
        background: {
            default: '#f2f2f2',
            light: '#fafbfb',
            medium: '#dbffe9',
            dark: '#64d6b3',
        },
        gray: {
            light: '#969DA6',
            dark: '#0D3037',
            one: '#232933',
            two: '#616B79',
            three: '#7E8EA1',
            four: '#8C9196',
            five: '#D9D9D9',
            six: '#F3F3F4',
        },
        green: {
            one: '#0D3037',
            two: '#0C8F8B',
            three: '#A6F7E4',
            four: '#E7FDF9',
        },
        blue: {
            one: '#06347A',
            two: '#458FFF',
            three: '#B4CFE8',
            four: '#EAF7FD',
        },
    },
};

export const muiTheme = createMuiTheme(themeOptions);

// add TypeScript support for custom theme props by extending Mui types, see https://v4.mui.com/guides/typescript/#customization-of-theme
declare module '@material-ui/core/styles/createTypography' {
    interface Typography {
        bold: string;
        semiBold: string;
        regular: string;
        medium: string;
        light: string;
    }

    interface TypographyOptions {
        bold: string;
        semiBold: string;
        regular: string;
        medium: string;
        light: string;
    }
}

// add TypeScript support for custom theme props by extending Mui types, see https://v4.mui.com/guides/typescript/#customization-of-theme
declare module '@material-ui/core/styles/createPalette' {
    export interface Palette {
        outlined: Outlined;
        gray: Gray;
        green: Green;
        blue: Blue;
    }

    interface PaletteOptions {
        outlined: Outlined;
        gray: Gray;
        green: Green;
        blue: Blue;
    }

    interface PaletteColor {
        mainHover: string;
    }

    interface SimplePaletteColorOptions {
        mainHover: string;
    }

    type Outlined = {
        mainHover: string;
    };

    type Gray = {
        light: string;
        dark: string;
        one: string;
        two: string;
        three: string;
        four: string;
        five: string;
        six: string;
    };

    type Green = {
        one: string;
        two: string;
        three: string;
        four: string;
    };

    type Blue = {
        one: string;
        two: string;
        three: string;
        four: string;
    };
}
