import moment from 'moment-timezone';
import { CartItemVm } from 'src/types/CartItemVm';
import { useSelector } from 'src/utils/react/useSelector';

export const useItemsGroupedByDay = (): Array<Array<CartItemVm>> => {
    const items = useSelector((state) => state.app.items);

    const itemsGroupedByDay = items.reduce<Array<Array<CartItemVm>> | void>((itemsByDay, item) => {
        const groupByDay = itemsByDay?.find((group) => group?.some((itemByDay) => moment(itemByDay.pickupTime).isSame(item.pickupTime)));

        if (!groupByDay) {
            return [...(itemsByDay ?? []), [item]];
        }

        groupByDay.push(item);
        return itemsByDay;
    }, []);

    return itemsGroupedByDay ?? [];
};
