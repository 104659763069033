import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import * as React from 'react';
import { APP_VERSION } from 'src/config/appVersion';
import { envBUILD_TIME } from 'src/env/envBUILD_TIME';
import { appReducer } from 'src/reducers/appReducer';
import { isProductionEnvironment } from 'src/utils/environment/isProductionEnvironment';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function AppVersionInfo(): React.ReactElement {
    const classes = useStyles();

    const [clicks, setClicks] = useState(0);

    const developerMode = useSelector((state) => state.app.developerMode);

    const setDeveloperMode = useAction(appReducer.actions.setDeveloperMode);
    const hideSnackbar = useAction(appReducer.actions.hideSnackbar);
    const showSnackbar = useAction(appReducer.actions.showSnackbar);

    function handleClickVersion() {
        // TODO: Disable developer mode in production for safety, enable with password later
        if (developerMode || isProductionEnvironment()) {
            return;
        }
        const _clicks = clicks + 1;
        if (_clicks >= 10) {
            setDeveloperMode(true);
            showSnackbar({
                message: `Developer mode enabled!`,
                autoHideDuration: 500,
                dontCloseOnClickAway: true,
            });
            setClicks(0);
        } else {
            hideSnackbar();
            if (_clicks >= 5) {
                showSnackbar({
                    message: `You are ${10 - _clicks} away from enabling developer mode`,
                    autoHideDuration: 5_000,
                    dontCloseOnClickAway: 3_000,
                    onClose: () => {
                        hideSnackbar();
                        setClicks(0);
                    },
                });
            }
            setClicks(_clicks);
        }
    }

    return (
        <div className={classes.version} onClick={handleClickVersion}>
            <div>{APP_VERSION}</div>
            {!isProductionEnvironment() && !!envBUILD_TIME() && <div>{envBUILD_TIME()}</div>}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    version: {
        fontSize: isProductionEnvironment() ? '8px' : '12px',
        position: 'fixed',
        bottom: 0,
        right: 8,
        textAlign: 'right',
        color: isProductionEnvironment() ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0)',
    },
}));
