import { useEffect, useRef } from 'react';
import { syncDeviceApi } from 'src/api/pidedirectokiosk/device/syncDeviceApi';
import { APP_VERSION } from 'src/config/appVersion';
import { Apps } from 'src/constants/App';
import { SECONDS } from 'src/constants/TimeUnit';
import { getDeviceId } from 'src/services/device/getDeviceId';
import { RestaurantId } from 'src/types/Id';
import { isTabHidden } from 'src/utils/isTabHidden';
import { useSelector } from 'src/utils/react/useSelector';
import { wait } from 'src/utils/wait';

export function useSyncDevice(options?: Options): [any] {
    const secondsToSyncDevice = 60 * SECONDS;

    const restaurantIdRef = useRef<RestaurantId>();
    const hasAlreadyStartedSyncing = useRef(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    useEffect(() => {
        restaurantIdRef.current = restaurantId;
    }, [restaurantId]);

    const handleSyncDevice = () => {
        if (hasAlreadyStartedSyncing.current) return;
        hasAlreadyStartedSyncing.current = true;
        syncDevice();
    };

    const syncDevice = async () => {
        if (!shouldSyncDevice()) return waitToFetchAgain();
        try {
            await syncDeviceApi({
                deviceId: getDeviceId(),
                restaurantId: restaurantIdRef.current,
                pideDirectoKioskVersion: APP_VERSION,
                app: Apps.PIDEDIRECTOKIOSK,
                languageTag: navigator.language,
                platform: navigator.platform,
                screenWidth: window.screen.width,
                screenHeight: window.screen.height,
            });
        } catch (e: any) {
            console.log(`syncDeviceApi failed`, e);
        }
        waitToFetchAgain();
    };

    const shouldSyncDevice = () => {
        // if (!restaurantIdRef.current) return false;
        if (isTabHidden()) return false;
        return true;
    };

    const waitToFetchAgain = async () => {
        await wait(secondsToSyncDevice);
        if (!options?.callApiContinuously) {
            hasAlreadyStartedSyncing.current = false;
            return;
        }
        syncDevice();
    };

    return [handleSyncDevice];
}

type Options = {
    callApiContinuously?: boolean;
};
