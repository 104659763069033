import * as React from 'react';

export function BackIcon({ title, width, height }: Props): React.ReactElement {
    return (
        <svg width={width ?? '26'} height={height ?? '23'} viewBox='0 0 26 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <title>{title}</title>
            <path d='M24.0003 11.5001H2.00031M2.00031 11.5001L11.9003 21.0001M2.00031 11.5001L11.9003 2.00012' stroke='white' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    );
}

type Props = {
    title?: string;
    width?: string;
    height?: string;
};
