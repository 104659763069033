import { useTheme } from '@material-ui/core/styles';
import * as React from 'react';

export function VisaIcon({ disabled }: Props): React.ReactElement {
    const theme = useTheme();

    return (
        <svg width='120' height='80' viewBox='0 0 140 100' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M114.26 44.5004C114.26 44.5004 116.108 52.7832 116.521 54.5199H108.403C109.205 52.5383 112.292 44.8344 112.292 44.8344C112.243 44.9012 113.094 42.8082 113.58 41.5168L114.26 44.5004ZM140 10.8125V89.1875C140 95.0879 134.774 99.875 128.333 99.875H11.6667C5.22569 99.875 0 95.0879 0 89.1875V10.8125C0 4.91211 5.22569 0.125 11.6667 0.125H128.333C134.774 0.125 140 4.91211 140 10.8125ZM37.066 66.7438L52.4271 32.1875H42.0972L32.5451 55.7891L31.5 51.0019L28.0972 35.1043C27.5382 32.9 25.8125 32.2766 23.6736 32.1875H7.94792L7.77778 32.8777C11.6181 33.7684 15.0451 35.0598 18.0347 36.6852L26.7361 66.7438H37.066ZM60.0104 66.7883L66.1354 32.1875H56.3646L50.2639 66.7883H60.0104ZM94.0139 55.4773C94.0625 51.5363 91.4375 48.5305 85.8229 46.059C82.3958 44.4781 80.3055 43.4094 80.3055 41.784C80.3542 40.3145 82.0799 38.8004 85.9201 38.8004C89.1042 38.7336 91.4375 39.4238 93.1875 40.1141L94.0625 40.4926L95.3993 33.0113C93.4792 32.3211 90.4167 31.5418 86.6493 31.5418C77 31.5418 70.2188 36.2621 70.1701 42.9863C70.0972 47.9516 75.0312 50.7125 78.7257 52.3824C82.493 54.0746 83.7812 55.1879 83.7812 56.6797C83.7326 58.9953 80.7188 60.0641 77.9236 60.0641C74.0347 60.0641 71.9444 59.5074 68.7604 58.216L67.4722 57.6594L66.1111 65.4301C68.3958 66.3875 72.625 67.2336 77 67.2781C87.2569 67.3004 93.941 62.6469 94.0139 55.4773ZM128.333 66.7883L120.458 32.1875H112.899C110.566 32.1875 108.792 32.8109 107.795 35.0598L93.2847 66.7883H103.542C103.542 66.7883 105.219 62.5133 105.583 61.6004H118.125C118.417 62.825 119.292 66.7883 119.292 66.7883H128.333Z'
                fill={disabled ? theme.palette.text.disabled : theme.palette.primary.main}
            />
        </svg>
    );
}

type Props = {
    disabled?: boolean;
};
