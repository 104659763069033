import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { memo } from 'react';
import { classNames } from 'src/utils/react/classNames';

export const Text: React.ComponentType<Props> = memo<Props>(function Text({ children, className, title, style, size, variant, error }: Props): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();

    const getClassNames = () => {
        let textClasses = classNames(classes.text, className);

        if (size === 'small') textClasses = classNames(textClasses, classes.smallText);
        if (size === 'large') textClasses = classNames(textClasses, classes.largeText);

        if (title) textClasses = classNames(textClasses, classes.title);

        if (error) textClasses = classNames(textClasses, classes.errorText);

        return textClasses;
    };

    return (
        <div className={getClassNames()} style={{ fontFamily: (title && theme.typography.semiBold) || theme.typography[variant || 'regular'], ...style }}>
            {children}
        </div>
    );
});

const useStyles = makeStyles((theme) => ({
    text: {
        fontFamily: theme.typography.regular,
        fontSize: '2vw',
        color: '#4F586E',
    },
    title: {
        color: '#000000',
        fontSize: '6vw',
        [theme.breakpoints.down('sm')]: {
            fontSize: '4vw',
        },
    },
    smallText: {
        fontSize: '1vw',
    },
    largeText: {
        fontSize: '3vw',
    },
    errorText: {
        color: '#f44336',
    },
}));

type Props = {
    size?: 'small' | 'large';
    variant?: 'bold' | 'semiBold' | 'medium' | 'light';
    error?: boolean;
    className?: string;
    style?: Object;
    children?: React.ReactElement | string;
    title?: boolean;
};
