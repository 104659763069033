export const OrderSteps = Object.freeze({
    START: 'START',
    SELECT_ORDER_TYPE: 'SELECT_ORDER_TYPE',
    CREATE_ORDER: 'CREATE_ORDER',
    PRODUCT_DETAIL: 'PRODUCT_DETAIL',
    MODIFIERS: 'MODIFIERS',
    CHECKOUT: 'CHECKOUT',
    SELECT_PAYMENT_METHOD: 'SELECT_PAYMENT_METHOD',
    CARD_PAYMENT: 'CARD_PAYMENT',
    CUSTOMER_INFO: 'CUSTOMER_INFO',
    COMPLETED: 'COMPLETED',
    VALIDATE_EMPLOYEE: 'VALIDATE_EMPLOYEE',
    ORDER_SUMMARY: 'ORDER_SUMMARY',
});

export type OrderStep = (typeof OrderSteps)[keyof typeof OrderSteps];
