import * as React from 'react';

export function UserIcon({ title, width, height }: Props): React.ReactElement {
    return (
        <svg width={width ?? '27'} height={height ?? '27'} viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <title>{title}</title>

            <path
                d='M21.1451 22.0932C20.2522 20.9111 19.0971 19.9525 17.7707 19.2928C16.4442 18.6331 14.9827 18.2904 13.5013 18.2918C12.0199 18.2904 10.5584 18.6331 9.23195 19.2928C7.90552 19.9525 6.75037 20.9111 5.85752 22.0932M21.1451 22.0932C22.8874 20.5435 24.1161 18.5008 24.6708 16.2359C25.2254 13.9711 25.0785 11.5911 24.2495 9.41171C23.4204 7.23228 21.9485 5.35636 20.0288 4.03271C18.1091 2.70907 15.8324 2.00024 13.5007 2.00024C11.1689 2.00024 8.89221 2.70907 6.97253 4.03271C5.05286 5.35636 3.58091 7.23228 2.75188 9.41171C1.92285 11.5911 1.77592 13.9711 2.33057 16.2359C2.88522 18.5008 4.11525 20.5435 5.85752 22.0932M21.1451 22.0932C19.0419 23.9693 16.3197 25.0042 13.5013 25.0002C10.6825 25.0046 7.96108 23.9696 5.85752 22.0932M17.3347 10.625C17.3347 11.6417 16.9308 12.6167 16.2119 13.3356C15.493 14.0545 14.518 14.4584 13.5013 14.4584C12.4846 14.4584 11.5096 14.0545 10.7907 13.3356C10.0718 12.6167 9.66792 11.6417 9.66792 10.625C9.66792 9.60832 10.0718 8.63329 10.7907 7.91438C11.5096 7.19548 12.4846 6.79161 13.5013 6.79161C14.518 6.79161 15.493 7.19548 16.2119 7.91438C16.9308 8.63329 17.3347 9.60832 17.3347 10.625Z'
                stroke='white'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}

type Props = {
    title?: string;
    width?: string;
    height?: string;
};
