import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { SubscribedPage } from 'src/components/page/SubscribedPage';
import { OrderTypes } from 'src/constants/OrderType';
import { translate } from 'src/i18n/translate';
import eatHereIcon from 'src/images/eathere.svg';
import takeAwayIcon from 'src/images/takeaway.svg';
import { appReducer } from 'src/reducers/appReducer';
import { useAction } from 'src/utils/react/useAction';

export function SelectOrderTypePage(): React.ReactElement {
    const classes = useStyles();

    const setKioskOrderType = useAction(appReducer.actions.setKioskOrderType);

    const handleEatHere = () => setKioskOrderType(OrderTypes.SELF_SERVICE_ORDER);

    const handleTakeAway = () => setKioskOrderType(OrderTypes.TAKE_AWAY_ORDER);

    return (
        <SubscribedPage title={'Kiosk select order type'}>
            <div className={classes.container}>
                <h1 className={classes.title}>{translate('Where do you enjoy your meal today?')}</h1>
                <div className={classes.buttonsContainer}>
                    <Button classes={{ button: classes.button }} onClick={handleEatHere}>
                        <img src={eatHereIcon} className={classes.icon} />
                        <p className={classes.text}>{translate('Eat here')}</p>
                    </Button>
                    <Button classes={{ button: classes.button }} onClick={handleTakeAway}>
                        <img src={takeAwayIcon} className={classes.icon} />
                        <p className={classes.text}>{translate('Take out')}</p>
                    </Button>
                </div>
            </div>
        </SubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        fontFamily: theme.typography.bold,
        fontSize: '10vw',
        textAlign: 'center',
        color: 'white',
        width: '90%',
        margin: '0 auto',
    },
    text: {
        fontFamily: theme.typography.bold,
        fontSize: '3.5vw',
        textAlign: 'center',
        color: 'white',
        marginBottom: 0,
        textTransform: 'none',
    },
    buttonsContainer: {
        display: 'flex',
        width: '70%',
        margin: '0 auto',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '8vh',
        position: 'relative',
        top: '5vh',
        gap: '4vw',
    },
    button: {
        width: '40vw',
        height: '30vh',
        borderRadius: '2vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.1))',
        backdropFilter: 'blur(5px)',
        flexDirection: 'column',
        border: 0,
    },
    buttonRoot: {
        padding: 0,
        width: 'fit-content',
        minWidth: 0,
        borderRadius: '2vw',
        margin: '0 2vw',
        flexShrink: 0,
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    icon: {
        height: '10vw',
        pointerEvents: 'none',
    },
}));
