import * as React from 'react';

export function PlusIcon({ title, width, height }: Props): React.ReactElement {
    return (
        <svg width={width ?? '110'} height={height ?? '110'} viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <title>{title}</title>
            <path d='M13.5001 2.5013V24.5013M24.5001 13.5013H2.50012' stroke='white' strokeWidth='4' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    );
}

type Props = {
    title?: string;
    width?: string;
    height?: string;
};
