export const LogEventTypes = Object.freeze({
    USER_SIGNED_IN_TO_PIDE_DIRECTO_KIOSK: 'USER_SIGNED_IN_TO_PIDE_DIRECTO_KIOSK',
    USER_OPENED_PAGE: 'USER_OPENED_PAGE',
    USER_CLICKED_BUTTON: 'USER_CLICKED_BUTTON',
    USER_CLICKED_TAB: 'USER_CLICKED_TAB',
    USER_OPENED_DIALOG: 'USER_OPENED_DIALOG',
    USER_OPENED_CONTEXTUAL_MENU: 'USER_OPENED_CONTEXTUAL_MENU',
    USER_CLICKED_CONTEXTUAL_MENU_ITEM: 'USER_CLICKED_CONTEXTUAL_MENU_ITEM',
    USER_TOGGLED_ACCORDION: 'USER_TOGGLED_ACCORDION',
    USER_OPENED_ALERT_DIALOG: 'USER_OPENED_ALERT_DIALOG',
    USER_TYPED_INPUT: 'USER_TYPED_INPUT',
    USER_CLICKED_SWITCH: 'USER_CLICKED_SWITCH',
    USER_CLICKED_CHECKBOX: 'USER_CLICKED_CHECKBOX',
    USER_CLICKED_SELECT_OPTION: 'USER_CLICKED_SELECT_OPTION',
    MERCADO_PAGO_NOTIFICATION: 'MERCADO_PAGO_NOTIFICATION',
    USER_OPENED_PIDEDIRECTO: 'USER_OPENED_PIDEDIRECTO',
    USER_SELECTED_ORDER_TYPE: 'USER_SELECTED_ORDER_TYPE',
    USER_OPENED_RESTAURANT_OPENING_HOURS_DIALOG: 'USER_OPENED_RESTAURANT_OPENING_HOURS_DIALOG',
    USER_SELECTED_RESTAURANT_FROM_SELECT_RESTAURANT_DIALOG: 'USER_SELECTED_RESTAURANT_FROM_SELECT_RESTAURANT_DIALOG',
    USER_SELECTED_RESTAURANT_FROM_CHAIN_RESTAURANTS: 'USER_SELECTED_RESTAURANT_FROM_CHAIN_RESTAURANTS',
    USER_OPENED_CART_DIALOG: 'USER_OPENED_CART_DIALOG',
    USER_OPENED_CART_DETAILS: 'USER_OPENED_CART_DETAILS',
    USER_ADDED_ITEM_TO_CART: 'USER_ADDED_ITEM_TO_CART',
    USER_REMOVED_ITEM_FROM_CART: 'USER_REMOVED_ITEM_FROM_CART',
    USER_SELECTED_ADDRESS: 'USER_SELECTED_ADDRESS',
    USER_ADDED_INVALID_PROMO_CODE: 'USER_ADDED_INVALID_PROMO_CODE',
    USER_ADDED_VALID_PROMO_CODE: 'USER_ADDED_VALID_PROMO_CODE',
    USER_SELECTED_PAYMENT_METHOD: 'USER_SELECTED_PAYMENT_METHOD',
    USER_PRINT_SUCCESSFUL: 'USER_PRINT_SUCCESSFUL',
    USER_PRINT_FAILED: 'USER_PRINT_FAILED',
});

export type LogEventType = (typeof LogEventTypes)[keyof typeof LogEventTypes];
