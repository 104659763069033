import * as React from 'react';
import { useContext, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormContext } from 'src/components/form/Form';
import { Input } from 'src/components/Input';
import { translate } from 'src/i18n/translate';
import { getError } from 'src/utils/form/getError';

export function FormTextField({ name, label, placeholder, helperText, defaultValue = null, disabled, required, minLength, maxLength, validate, classes, tooltip }: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const formContext = useContext(FormContext);
    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <Controller
            render={({ value, onChange, ...props }) => {
                const { ref, ...restProps } = props;
                return (
                    <Input
                        {...restProps}
                        value={value}
                        onChange={onChange}
                        label={label}
                        placeholder={placeholder}
                        helperText={getError(errors, name)?.message ?? helperText}
                        disabled={isSubmitting || disabled || formContext.disabled}
                        required={(required as any)?.value || required}
                        error={!!getError(errors, name)}
                        inputRef={inputRef}
                        classes={classes}
                        tooltip={tooltip}
                    />
                );
            }}
            control={control}
            name={name}
            defaultValue={!defaultValue ? null : defaultValue}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
                minLength: {
                    value: (minLength as any)?.value ?? minLength,
                    message: (minLength as any)?.message ?? translate('Must be at least @number characters', { number: (minLength as any)?.value ?? minLength }),
                },
                maxLength: {
                    value: (maxLength as any)?.value ?? maxLength,
                    message: (maxLength as any)?.message ?? translate('Must be at most @number characters', { number: (maxLength as any)?.value ?? maxLength }),
                },
                validate,
            }}
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

export type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    minLength?:
        | number
        | {
              value: number;
              message: string;
          };
    maxLength?:
        | number
        | {
              value: number;
              message: string;
          };
    validate?: any;
    classes?: any;
    tooltip?: string;
};
