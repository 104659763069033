import { BigNumber } from 'bignumber.js';
import moment from 'moment-timezone';
import { TimeZones } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { useConfirmDialog } from 'src/services/useConfirmDialog';
import { useIsPickupStationUser } from 'src/services/useIsPickupStationUser';
import { CartItemVm } from 'src/types/CartItemVm';
import { getDayFromNumberDay } from 'src/utils/date/getDayFromNumberDay';
import { momentFromTimeString } from 'src/utils/date/momentFromTimeString';
import { calculateOrderItemPrice } from 'src/utils/order/calculateOrderItemPrice';
import { isUnavailableDateTimeOrder } from 'src/utils/order/isUnavailableDateTimeOrder';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export const useAddItem = (): [(item: CartItemVm) => Promise<boolean>] => {
    const confirmDialog = useConfirmDialog();
    const isPickupStationUser = useIsPickupStationUser();

    const selectedMenu = useSelector((state) => state.app.selectedMenu);
    const restaurant = useSelector((state) => state.app.restaurant);
    const items = useSelector((state) => state.app.items);
    const timeZone = useSelector((state) => state.app.restaurant?.timeZone);
    const selectedDay = useSelector((state) => state.app.selectedDay);
    const customerEmployee = useSelector((state) => state.app.customerEmployee);

    const addKioskItem = useAction(appReducer.actions.addKioskItem);

    const totalByDay = items
        ?.filter((item) => item.pickupTime && getDayFromNumberDay(moment.tz(item.pickupTime, timeZone ?? TimeZones.AMERICA_MONTERREY).isoWeekday()) === selectedDay)
        .reduce((totalCost, item) => {
            const itemCost = calculateOrderItemPrice(item);
            return BigNumber(totalCost).plus(itemCost).toNumber();
        }, BigNumber(0).toNumber());
    const giftByDay = customerEmployee?.days?.find((day) => day.day === selectedDay);
    const availableCredits = BigNumber(giftByDay?.gift?.credits ?? 0)
        .minus(giftByDay?.gift?.usedCredits ?? 0)
        .toNumber();
    const hasOrderedOnDay = customerEmployee?.days?.find((day) => day.day === selectedDay && !!day.hasOrderToday);

    const addItem = async (item: CartItemVm) => {
        if (!isPickupStationUser) {
            addKioskItem(item);
            return true;
        }

        if (BigNumber(BigNumber(totalByDay).plus(calculateOrderItemPrice(item)).toNumber()).isGreaterThan(availableCredits)) {
            confirmDialog({
                title: translate('Insufficient credits'),
                content: translate('This item exceeds your available credits for this day'),
                buttonText: translate('Accept'),
                variant: 'warning',
            });
            return false;
        }

        if (isUnavailableDateTimeOrder(restaurant, selectedMenu?.pickupTime)) {
            await confirmDialog({
                title: translate('Out of schedule'),
                content: translate('This dish is no longer available at this time'),
                buttonText: translate('Accept'),
                variant: 'warning',
            });
            return false;
        }

        if (hasOrderedOnDay) {
            await confirmDialog({
                title: translate('You have already placed an order on this day'),
                buttonText: translate('Accept'),
                variant: 'warning',
            });
            return false;
        }

        addKioskItem({
            ...item,
            pickupTime: selectedMenu?.pickupTime
                ? momentFromTimeString(restaurant?.pickupStationClosingTime ?? '00:00', restaurant?.timeZone ?? TimeZones.AMERICA_MONTERREY, selectedMenu?.pickupTime)
                : item.pickupTime,
        });
        return true;
    };

    return [addItem];
};
