import { makeStyles } from '@material-ui/core/styles';
import { DropDown, DropDownItem, Image, Text } from '@pidedirecto/ui';
import { I18n } from 'aws-amplify';
import * as React from 'react';
import { Language, Languages } from 'src/constants/Language';
import { OrderStep, OrderSteps } from 'src/constants/OrderStep';
import { translate } from 'src/i18n/translate';
import MexFlag from 'src/images/mexFlag.png';
import UsaFlag from 'src/images/usaFlag.png';
import { getLanguageInLocalStorage } from 'src/localStorage/language/getLanguageInLocalStorage';
import { setLanguageInLocalStorage } from 'src/localStorage/language/setLanguageInLocalStorage';
import { useIsPickupStationUser } from 'src/services/useIsPickupStationUser';
import { useSelector } from 'src/utils/react/useSelector';
import { upperCase } from 'src/utils/string/upperCase';

export function LanguageDropDown(): React.ReactElement | null {
    const classes = useStyles();
    const isPickupStationUser = useIsPickupStationUser();
    const currentLanguage = getLanguageInLocalStorage();
    const availableChangeLanguageSteps: Array<OrderStep> = [OrderSteps.START, OrderSteps.SELECT_ORDER_TYPE, OrderSteps.CREATE_ORDER];
    const orderStep = useSelector((state) => state.app.orderStep);

    const availableLanguages = Object.values(Languages)?.filter((language) => language !== currentLanguage);
    const shouldRenderLanguageDropDown = availableChangeLanguageSteps?.includes(orderStep) && !isPickupStationUser;

    const getLanguageFlagSources = (language: Language) => {
        return language === Languages.EN ? UsaFlag : MexFlag;
    };

    const changeLanguage = (language: Language) => {
        I18n.setLanguage(language);
        setLanguageInLocalStorage(language);
    };

    if (!shouldRenderLanguageDropDown) return null;

    return (
        <div className={classes.content}>
            <DropDown
                content={<Image width={50} alt={translate('Selected language')} src={getLanguageFlagSources(currentLanguage)}></Image>}
                classes={{ button: classes.dropDown }}
                variant='secondary'
                position={'right'}
            >
                {availableLanguages.map((language) => (
                    <DropDownItem classes={{ container: classes.dropDownItem }} onClick={() => changeLanguage(language as Language)}>
                        <Image width={30} src={getLanguageFlagSources(language as Language)}></Image>
                        <Text className={classes.text}>{upperCase(language)}</Text>
                    </DropDownItem>
                ))}
            </DropDown>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    dropDown: {
        borderColor: 'transparent',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            borderColor: 'transparent',
        },
        '&:focus': {
            outline: 0,
        },
    },
    content: {
        position: 'absolute',
        top: 24,
        right: 24,
        zIndex: 1000,
    },
    dropDownItem: {
        display: 'flex',
        alignItems: 'center',
    },
    text: {
        padding: '0 10px',
        fontSize: '1.5vw',
    },
}));
