import { makeStyles } from '@material-ui/core';
import moment from 'moment-timezone';
import * as React from 'react';
import { useEffect } from 'react';
import { SubscribedPage } from 'src/components/page/SubscribedPage';
import { OrderSteps } from 'src/constants/OrderStep';
import { TimeZones } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { Cart } from 'src/scenes/menu/Cart';
import { CartItem } from 'src/scenes/order/CartItem';
import { useItemsGroupedByDay } from 'src/services/order/useItemsGroupedByDay';
import { getDayFromNumberDay } from 'src/utils/date/getDayFromNumberDay';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function OrderSummaryPage(): React.ReactElement {
    const classes = useStyles();

    const itemsGroupedByDay = useItemsGroupedByDay();
    const items = useSelector((state) => state.app.items);
    const timeZone = useSelector((state) => state.app.restaurant?.timeZone);

    const setOrderStep = useAction(appReducer.actions.setOrderStep);

    useEffect(() => {
        if (!items?.length) {
            setOrderStep(OrderSteps.CREATE_ORDER);
        }
    }, [items]);

    return (
        <SubscribedPage title={'Order summary'}>
            <div className={classes.container}>
                {itemsGroupedByDay?.map((itemsByDay) => {
                    const day = getDayFromNumberDay(moment.tz(itemsByDay?.[0].pickupTime, timeZone ?? TimeZones.AMERICA_MONTERREY).isoWeekday());

                    return (
                        <div className={classes.group}>
                            <div className={classes.day}>{translate(`Days.${day}`)}</div>
                            {itemsByDay.map((item) => (
                                <CartItem cartItem={item} items={itemsByDay}></CartItem>
                            ))}
                        </div>
                    );
                })}
            </div>
            <Cart />
        </SubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '75vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'white',
        padding: '1.5vw 1vw',
        fontSize: '2vw',
        fontFamily: theme.typography.regular,
        gap: 50,
        overflowY: 'auto',
    },
    group: {
        borderRadius: 20,
        backgroundColor: '#F6F8F9',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 30,
    },
    day: {
        padding: '1vw',
        width: '100%',
        color: '#2C374A',
        fontFamily: theme.typography.bold,
        fontSize: '3vw',
        backgroundColor: '#D9D9D9',
    },
}));
