import { pidedirectokioskApiMethod } from 'src/api/config/pidedirectokioskApiMethod';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { QpayTerminalPaymentResponseResult } from 'src/constants/QpayTerminalPaymentResponseResults';
import type { QpayTerminalInstructionId } from 'src/types/Id';

export async function deprecatedMockQpayPaymentTerminalResponseApi(request: MockQpayPaymentTerminalResponseApiRequest): ApiSauceResponse<any> {
    return pidedirectokioskApiMethod('mock/mockQpayPaymentTerminalResponseApi', request);
}

export type MockQpayPaymentTerminalResponseApiRequest = {
    terminalInstructionId: QpayTerminalInstructionId;
    qpayResponseResult: QpayTerminalPaymentResponseResult;
    amount: string;
};
