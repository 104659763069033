import { createContext } from 'react';
import * as React from 'react';
import { FormProvider } from 'react-hook-form';

export function Form({ form, onSubmit, disabled, disableSubmitOnEnterKey, style, className, children }: Props): React.ReactElement {
    return (
        <FormProvider {...form}>
            <FormContext.Provider value={{ disabled: !!disabled }}>
                <form
                    role='form'
                    onSubmit={(event) => {
                        if (disabled) return;
                        form.handleSubmit(onSubmit)(event);
                    }}
                    onKeyDown={
                        disableSubmitOnEnterKey
                            ? (event: any) => {
                                  if ((event.key || event.code) === 'Enter') event.preventDefault();
                              }
                            : undefined
                    }
                    style={style}
                    className={className}
                    noValidate
                >
                    {children}
                </form>
            </FormContext.Provider>
        </FormProvider>
    );
}

export const FormContext = createContext({ disabled: false });

type Props = {
    form: any;
    onSubmit?: any;
    disabled?: boolean;
    disableSubmitOnEnterKey?: boolean;
    style?: any;
    enabledForUserTypeView?: boolean;
    className?: any;
    children?: any;
};
