import * as React from 'react';

export function CheckIcon({ color, title, width, height }: Props): React.ReactElement {
    return (
        <svg width={width ?? '190'} height={height ?? '190'} viewBox='0 0 190 190' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <title>{title}</title>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M95 0C42.5308 0 0 42.5308 0 95C0 147.469 42.5308 190 95 190C147.469 190 190 147.469 190 95C190 42.5308 147.469 0 95 0ZM124.806 78.5528C125.542 77.9226 126.146 77.1522 126.582 76.2863C127.018 75.4203 127.278 74.4763 127.345 73.5092C127.413 72.5421 127.288 71.5712 126.976 70.653C126.665 69.7349 126.174 68.8878 125.532 68.1613C124.89 67.4347 124.11 66.8431 123.237 66.421C122.365 65.9989 121.417 65.7546 120.449 65.7025C119.48 65.6503 118.512 65.7913 117.599 66.1173C116.686 66.4432 115.846 66.9476 115.13 67.601C101.797 79.3618 91.1359 93.8403 83.8631 110.064L70.9333 97.1436C69.548 95.8528 67.7158 95.15 65.8226 95.1834C63.9294 95.2168 62.1231 95.9838 60.7842 97.3227C59.4453 98.6616 58.6784 100.468 58.645 102.361C58.6116 104.254 59.3143 106.087 60.6051 107.472L81.4077 128.284C82.286 129.161 83.3733 129.8 84.567 130.141C85.7608 130.481 87.0216 130.512 88.2305 130.231C89.4395 129.949 90.5567 129.364 91.4769 128.53C92.397 127.697 93.0898 126.643 93.4898 125.468C99.6628 107.369 110.458 91.1959 124.806 78.5528Z'
                fill={color ?? '#13D48F'}
            />
        </svg>
    );
}

type Props = {
    color?: string;
    title?: string;
    width?: string;
    height?: string;
};
