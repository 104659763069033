import React from 'react';
import { CircularLoadingIndicator } from 'src/components/page/CircularLoadingIndicator';
import { usePageLoader } from 'src/services/usePageLoader';

export function Loader(): React.ReactElement | null {
    const { loaderState } = usePageLoader();

    if (!loaderState.open) return null;

    return <CircularLoadingIndicator text={loaderState.text} />;
}
