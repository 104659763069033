import { createMuiTheme } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { themeOptions } from 'src/theme/muiTheme';

export function createRestaurantMuiTheme(restaurantColors: Array<string>): Theme {
    return createMuiTheme({
        ...themeOptions,
        palette: {
            ...themeOptions.palette,
            primary: {
                ...themeOptions.palette.primary,
                main: restaurantColors[0] ?? '#64d6b3',
            },
            secondary: {
                ...themeOptions.palette.secondary,
                main: restaurantColors[1] ?? '#3affc7',
            },
        },
    });
}
