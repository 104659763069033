import Amplify from 'aws-amplify';
import { envAWS_AUTH_IDENTITY_POOL_ID } from 'src/env/envAWS_AUTH_IDENTITY_POOL_ID';
import { envAWS_AUTH_REGION } from 'src/env/envAWS_AUTH_REGION';
import { envAWS_AUTH_USER_POOL_ID } from 'src/env/envAWS_AUTH_USER_POOL_ID';
import { envAWS_AUTH_USER_POOL_WEB_CLIENT_ID } from 'src/env/envAWS_AUTH_USER_POOL_WEB_CLIENT_ID';

export function configureAws() {
    Amplify.configure({
        Auth: {
            identityPoolId: envAWS_AUTH_IDENTITY_POOL_ID(),
            region: envAWS_AUTH_REGION(),
            userPoolId: envAWS_AUTH_USER_POOL_ID(),
            userPoolWebClientId: envAWS_AUTH_USER_POOL_WEB_CLIENT_ID(),
            mandatorySignIn: true,
        },
    } as AwsConfig);
}

type AwsConfig = {
    Auth: {
        identityPoolId: string;
        region: string;
        userPoolId: string;
        userPoolWebClientId: string;
        mandatorySignIn: boolean;
    };
};
