import type { CartItemVm } from 'src/types/CartItemVm';
import { removeNulls } from 'src/utils/removeNulls';
import { emptyStringToUndefined } from 'src/utils/string/emptyStringToUndefined';

export function getItemFormatted(item: CartItemVm): CartItemVm {
    return removeNulls({
        menuItemId: item.menuItemId,
        menuCategoryId: item.menuCategoryId,
        menuItemType: item.menuItemType,
        name: item.name,
        unitPrice: item.unitPrice,
        promoUnitPrice: item.promoUnitPrice,
        promoText: item.promoText,
        imageUrl: item.imageUrl,
        note: emptyStringToUndefined(item.note),
        modifierGroups: item.modifierGroups.map((modifierGroup) => ({
            name: modifierGroup.name,
            freeModifiersQuantity: modifierGroup.freeModifiersQuantity,
            showModifierGroupNameInCommand: modifierGroup.showModifierGroupNameInCommand,
            modifiers: modifierGroup.modifiers.map((modifier) => ({
                modifierId: modifier.modifierId,
                name: modifier.name,
                price: modifier.price ?? 0,
                quantity: modifier.quantity,
            })),
        })),
        orderItemKitchenStatus: item.orderItemKitchenStatus,
        salesUnit: item.salesUnit,
        isSoldByWeight: item.isSoldByWeight,
        pickupTime: item.pickupTime,
        quantity: item.quantity,
        addedAt: item.addedAt,
        cancellationReason: item.cancellationReason,
    });
}
