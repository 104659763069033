import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { AppSnackbar } from 'src/components/app/AppSnackbar';
import { AppVersionInfo } from 'src/components/app/AppVersionInfo';
import { DeveloperModeLabel } from 'src/components/app/DeveloperModeLabel';
import { EnvironmentLabel } from 'src/components/app/EnvironmentLabel';
import { SyncDevice } from 'src/components/app/SyncDevice';
import { MockPaymentTerminalResponseDialog } from 'src/components/dialog/deprecated/MockPaymentTerminalResponseDialog';
import { OrderSteps } from 'src/constants/OrderStep';
import { MINUTES } from 'src/constants/TimeUnit';
import { translate } from 'src/i18n/translate';
import { SelectPaymentTerminalDialog } from 'src/scenes/checkout/SelectPaymentTerminalDialog';
import { SelectPaymentTerminalPaymentResultMockDialog } from 'src/scenes/checkout/SelectPaymentTerminalPaymentResultMockDialog';
import { useClearKiosk } from 'src/services/useClearKiosk';
import { useConfirmDialog } from 'src/services/useConfirmDialog';
import { useIsPickupStationUser } from 'src/services/useIsPickupStationUser';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';

export function AppContainer({ children }: Props): React.ReactElement {
    const classes = useStyles();
    const timeout = useRef<NodeJS.Timeout>();
    const payingRef = useRef<boolean | void>();

    const confirmDialog = useConfirmDialog();

    const orderStep = useSelector((state) => state.app.orderStep);
    const restaurant = useSelector((state) => state.app.restaurant);
    const paying = useSelector((state) => state.app.paying);

    const clearKiosk = useClearKiosk();

    const isStartOrSelectOrderTypeStep = orderStep === OrderSteps.START || orderStep === OrderSteps.SELECT_ORDER_TYPE;
    const isPickupStationUser = useIsPickupStationUser();
    const backgroundImageUrl = isStartOrSelectOrderTypeStep ? restaurant?.backgroundImageUrl : '';

    useEffect(() => {
        payingRef.current = paying;
    }, [paying]);

    const handleClearKiosk = async () => {
        if (payingRef.current) {
            refreshResetKioskTimer();
            return;
        }

        const doesCustomerStillHere = await openConfirmStillHereDialog();
        if (!doesCustomerStillHere) {
            clearKiosk();
            return;
        }

        refreshResetKioskTimer();
    };

    const refreshResetKioskTimer = () => {
        if (timeout.current) window.clearTimeout(timeout.current);
        timeout.current = setTimeout(() => handleClearKiosk(), 2 * MINUTES);
    };

    const openConfirmStillHereDialog = async () => {
        return confirmDialog({
            variant: 'notification',
            content: translate('Are you still here?'),
            buttonText: translate('Yes, I am'),
            cancelButtonText: translate('Exit'),
            timeoutSeconds: 12,
        });
    };

    return (
        <div
            className={classes.container}
            style={{ backgroundImage: backgroundImageUrl && !isPickupStationUser ? `url(${backgroundImageUrl})` : '', backgroundColor: isStartOrSelectOrderTypeStep ? `#1a9aa0` : '' }}
            onKeyDown={refreshResetKioskTimer} // refresh reset timer when typing
            onTouchStart={refreshResetKioskTimer} // refresh reset timer when touching screen on mobile devices
            onMouseDown={refreshResetKioskTimer} // refresh reset timer when clicking on computer devices
        >
            <div className={classNames(classes.container, backgroundImageUrl ? classes.background : '')}>{children}</div>
            <SelectPaymentTerminalDialog />
            <SelectPaymentTerminalPaymentResultMockDialog />
            <MockPaymentTerminalResponseDialog />
            <SyncDevice />
            <AppSnackbar />
            <EnvironmentLabel />
            <DeveloperModeLabel />
            <AppVersionInfo />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        margin: 0,
        overflow: 'hidden',
        '& h1': {
            userSelect: 'none',
        },
        '& p': {
            userSelect: 'none',
        },
        '& div': {
            userSelect: 'none',
            '-webkit-tap-highlight-color': 'transparent',
        },
        '& img': {
            userSelect: 'none',
        },
    },
    background: {
        backgroundColor: 'rgba(0,0,0,0.4)',
    },
    body: {
        overscrollBehavior: 'contain',
    },
}));

type Props = {
    children: React.ReactNode;
};
