import React, { createContext, ReactNode, useState } from 'react';
import { Loader } from 'src/components/Loader';

export const LoaderProvider = ({ children }: { children: ReactNode }) => {
    const [loaderState, setLoaderState] = useState({ open: false, text: '' });

    const showLoader = (text: string) => {
        setLoaderState({ open: true, text });
    };

    const closeLoader = () => {
        setLoaderState({ open: false, text: '' });
    };

    return (
        <LoaderContext.Provider value={{ loaderState, showLoader, closeLoader }}>
            <Loader />
            {children}
        </LoaderContext.Provider>
    );
};

export const LoaderContext = createContext<
    | {
          loaderState: { open: boolean; text: string };
          showLoader: (text: string) => void;
          closeLoader: () => void;
      }
    | undefined
>(undefined);
