import * as React from 'react';
import { OrderSteps } from 'src/constants/OrderStep';
import { CardPaymentPage } from 'src/scenes/CardPaymentPage';
import { CheckoutPage } from 'src/scenes/CheckoutPage';
import { CustomerInfoPage } from 'src/scenes/CustomerInfoPage';
import { MenuPage } from 'src/scenes/MenuPage';
import { ModifiersPage } from 'src/scenes/ModifiersPage';
import { OrderCompletedPage } from 'src/scenes/OrderCompletedPage';
import { ProductDetailPage } from 'src/scenes/ProductDetailPage';
import { SelectOrderTypePage } from 'src/scenes/SelectOrderTypePage';
import { SelectPaymentMethodPage } from 'src/scenes/SelectPaymentMethodPage';
import { StartPage } from 'src/scenes/StartPage';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantSteps(): React.ReactElement {
    const orderStep = useSelector((state) => state.app.orderStep);
    return (
        <>
            {orderStep === OrderSteps.START && <StartPage />}
            {orderStep === OrderSteps.SELECT_ORDER_TYPE && <SelectOrderTypePage />}
            {orderStep === OrderSteps.CREATE_ORDER && <MenuPage />}
            {orderStep === OrderSteps.PRODUCT_DETAIL && <ProductDetailPage />}
            {orderStep === OrderSteps.MODIFIERS && <ModifiersPage />}
            {orderStep === OrderSteps.CHECKOUT && <CheckoutPage />}
            {orderStep === OrderSteps.CUSTOMER_INFO && <CustomerInfoPage />}
            {orderStep === OrderSteps.SELECT_PAYMENT_METHOD && <SelectPaymentMethodPage />}
            {orderStep === OrderSteps.CARD_PAYMENT && <CardPaymentPage />}
            {orderStep === OrderSteps.COMPLETED && <OrderCompletedPage />}
        </>
    );
}
