import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { SubscribedPage } from 'src/components/page/SubscribedPage';
import { OrderSteps } from 'src/constants/OrderStep';
import { PaymentMethods } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';
import { TerminalIcon } from 'src/icons/TerminalIcon';
import { appReducer } from 'src/reducers/appReducer';
import { usePayWithPaymentTerminal } from 'src/services/paymentTerminal/usePayWithPaymentTerminal';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function CardPaymentPage(): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const { payWithPaymentTerminal, loading } = usePayWithPaymentTerminal();

    const [totalPaid, setTotalPaid] = useState(0);

    const total = useSelector((state) => state.app.payment?.total ?? '0');

    const goToPreviousKioskOrderStep = useAction(appReducer.actions.goToPreviousKioskOrderStep);
    const setOrderStep = useAction(appReducer.actions.setOrderStep);
    const setPaying = useAction(appReducer.actions.setPaying);
    const setOrderPayment = useAction(appReducer.actions.setOrderPayment);

    useEffect(() => {
        pay(total);
    }, []);

    useEffect(() => {
        setPaying(loading);
    }, [loading]);

    const pay = async (amount: string) => {
        const paymentResponse = await payWithPaymentTerminal({ amount });
        if (!paymentResponse.paid) {
            goToPreviousKioskOrderStep();
            return;
        }

        setOrderPayment({
            paymentTerminalPaymentId: paymentResponse.paymentTerminalPaymentId,
            paymentTerminalId: paymentResponse.paymentTerminalId,
            paymentTerminalProvider: paymentResponse.paymentTerminalProvider,
            amount,
            paymentMethod: PaymentMethods.CREDIT_CARD,
            cardNumber: paymentResponse.cardNumber,
            cardType: paymentResponse.cardType,
        });

        const hasPaidTotalOrder = BigNumber(amount ?? 0)
            .plus(totalPaid)
            .isEqualTo(total);

        if (hasPaidTotalOrder) return setOrderStep(OrderSteps.COMPLETED);

        setTotalPaid(
            BigNumber(amount ?? 0)
                .plus(totalPaid)
                .toNumber(),
        );

        await pay(
            BigNumber(total)
                .minus(amount ?? 0)
                .toString(),
        );
    };

    return (
        <SubscribedPage title={'Kiosk card payment'}>
            <div className={classes.container}>
                <TerminalIcon />
                <h1 className={classes.title}>{formatAsCurrencyNumber(BigNumber(total).minus(totalPaid).toNumber())}</h1>
                <p className={classes.text}>{translate('Follow the terminal instructions')}</p>
            </div>
        </SubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        fontFamily: theme.typography.bold,
        fontSize: '7vw',
        textAlign: 'center',
        width: '90%',
        margin: '5vh auto',
        textTransform: 'none',
        marginBottom: '2vh',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: '3.5vw',
        textAlign: 'center',
        marginTop: 0,
    },
}));
