import { makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { useAction } from 'src/utils/react/useAction';

export function GoBackButton({ disabled }: Props): React.ReactElement {
    const classes = useStyles();

    const goToPreviousKioskOrderStep = useAction(appReducer.actions.goToPreviousKioskOrderStep);

    return (
        <Button text classes={{ button: classes.button }} onClick={goToPreviousKioskOrderStep} disabled={disabled}>
            <ArrowBackIcon className={classes.icon} />
            <span className={classes.text}>{translate('Back')}</span>
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        width: 'fit-content',
        minWidth: 0,
        borderRadius: 10,
        flexShrink: 0,
        position: 'absolute',
        top: '5vh',
        left: '5vw',
        fontSize: '4vw',
    },
    text: {
        fontFamily: theme.typography.regular,
        textTransform: 'none',
        marginLeft: 20,
    },
    icon: {
        fontSize: '4vw',
        color: '#75828C',
    },
}));

type Props = {
    disabled?: boolean;
};
