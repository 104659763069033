import { pidedirectokioskApiMethod } from 'src/api/config/pidedirectokioskApiMethod';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { type CustomerEmployeeVm } from 'src/types/CustomerEmployeeVm';
import { EmployeeNumber, RestaurantId } from 'src/types/Id';

export async function findCustomerEmployeeApi(request: FindCustomerEmployeeApiRequest): ApiSauceResponse<CustomerEmployeeVm> {
    return pidedirectokioskApiMethod('/customer/findCustomerEmployeeApi', request);
}

export type FindCustomerEmployeeApiRequest = {
    restaurantId: RestaurantId;
    employeeNumberAtCompany: EmployeeNumber;
};
