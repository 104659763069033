import { I18n } from 'aws-amplify';
import { en } from 'src/i18n/en';
import { es } from 'src/i18n/es';
import { getLanguageInLocalStorage } from 'src/localStorage/language/getLanguageInLocalStorage';

// export const locale = navigator.language || navigator.userLanguage;
export const locale = 'es-MX';

const currentLanguage = getLanguageInLocalStorage();

I18n.setLanguage(currentLanguage);
I18n.putVocabularies({
    es,
    en,
});
// export const locale = navigator.language || navigator.userLanguage;
//
// I18n.setLanguageInLocalStorage(locale);
// I18n.putVocabularies({
//     en,
//     es,
// });
