export const PaymentMethods = Object.freeze({
    CREDIT_CARD: 'CREDIT_CARD',
    CARD_ON_DELIVERY: 'CARD_ON_DELIVERY',
    CASH: 'CASH',
    PAYROLL: 'PAYROLL',
    PENDING: 'PENDING',
    PAYMENT_LINK: 'PAYMENT_LINK',
    CREDITS: 'CREDITS',
    PAYMENT_TERMINAL: 'PAYMENT_TERMINAL',
});

export type PaymentMethod = (typeof PaymentMethods)[keyof typeof PaymentMethods];
