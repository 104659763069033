import { getSessionIdInSessionStorage } from 'src/sessionStorage/getSessionIdInSessionStorage';
import { setSessionIdInSessionStorage } from 'src/sessionStorage/setSessionIdInSessionStorage';
import type { SessionId } from 'src/types/Id';
import { newUuid } from 'src/utils/uuid/newUuid';

export function getSessionId(): SessionId {
    const sessionId = getSessionIdInSessionStorage();

    if (!sessionId) {
        const newSessionId: SessionId = newUuid();
        setSessionIdInSessionStorage(newSessionId);
        return newSessionId;
    }
    return sessionId;
}
