import * as React from 'react';
import { Page } from 'src/components/Page';
import { ServiceSuspended } from 'src/components/page/ServiceSuspended';
import { useSelector } from 'src/utils/react/useSelector';

export function SubscribedPage({ title, children, className }: Props): React.ReactElement {
    const remainingDays = useSelector((state) => state.app.remainingDays);
    const subscriptionAccess = useSelector((state) => state.app.subscriptionAccess);

    const hasAccess = subscriptionAccess && remainingDays === 0;

    if (hasAccess) {
        return (
            <Page title={title} className={className}>
                <ServiceSuspended />
            </Page>
        );
    }

    return (
        <Page title={title} className={className}>
            {children}
        </Page>
    );
}

type Props = {
    title: string;
    children: React.ReactNode;
    className?: string;
};
