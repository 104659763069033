import { makeStyles } from '@material-ui/core';
import { RefreshIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useEffect } from 'react';
import { Button } from 'src/components/Button';
import { Tooltip } from 'src/components/Tooltip';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { Cart } from 'src/scenes/menu/Cart';
import { MenuCategories } from 'src/scenes/menu/MenuCategories';
import { MenuItem } from 'src/scenes/menu/MenuItem';
import { useClearKiosk } from 'src/services/useClearKiosk';
import { useConfirmDialog } from 'src/services/useConfirmDialog';
import { useIsPickupStationUser } from 'src/services/useIsPickupStationUser';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function Menu(): React.ReactElement {
    const classes = useStyles();

    const isPickupStationUser = useIsPickupStationUser();
    const confirmDialog = useConfirmDialog();

    const categorySelected = useSelector((state) => state.app.menuCategorySelected);
    const restaurant = useSelector((state) => state.app.restaurant);
    const items = useSelector((state) => state.app.items);
    const customerEmployee = useSelector((state) => state.app?.customerEmployee);
    const menuCategories = useSelector((state) => state.app.kioskMenu?.menuCategories);
    const selectedMenu = useSelector((state) => state.app.selectedMenu);
    const menuItems = useSelector((state) => state.app.kioskMenu?.menuItems) ?? [];
    const selectedDay = useSelector((state) => state.app.selectedDay);

    const selectMenuCategory = useAction(appReducer.actions.selectMenuCategory);
    const clearKiosk = useClearKiosk();

    const categories = menuCategories?.filter((category) => selectedMenu?.menuCategoryIds?.includes(category.menuCategoryId));
    const currentMenuCategories = isPickupStationUser && selectedMenu ? categories || [] : menuCategories || [];

    useEffect(() => {
        if (!categorySelected && currentMenuCategories?.length) {
            selectMenuCategory(currentMenuCategories[0]);
        }
    }, [categorySelected, currentMenuCategories]);

    useEffect(() => {
        if ((selectedMenu && !categorySelected) || isPickupStationUser) {
            const menuCategory = menuCategories?.find((category) => category.menuCategoryId === selectedMenu?.menuCategoryIds[0]);
            selectMenuCategory(menuCategory);
        }
    }, [selectedMenu, selectedDay]);

    const getItems = () => categorySelected?.menuItemIds.map((id) => menuItems.find((menuItem) => menuItem.menuItemId === id)) ?? [];

    const handleStartOver = async () => {
        const confirmStartOver = await confirmDialog({
            title: translate('Are you sure you want to start over?'),
            content: !!items.length ? translate('You have items in your order, if you start over they will be lost') : undefined,
            cancelButtonText: translate('Cancel'),
            buttonText: translate('Yes, start over'),
        });

        if (!confirmStartOver) return;

        clearKiosk();
    };

    if (!menuCategories) return <></>;

    return (
        <div className={classes.kiosk} style={{ marginTop: customerEmployee ? '8vh' : 0 }}>
            {!!restaurant?.imageUrl && !isPickupStationUser && <img src={restaurant?.imageUrl} alt={`image for ${restaurant?.name ?? ''}`} className={classes.bannerImage} />}
            <div className={classes.container}>
                <div className={classes.categoriesBar}>
                    {!isPickupStationUser && (
                        <Button onClick={handleStartOver} text classes={{ button: classes.button }}>
                            <Tooltip text={translate('Start over')}>
                                <RefreshIcon size={40} />
                            </Tooltip>
                        </Button>
                    )}
                    <div className={classNames(classes.categories, items.length !== 0 ? classes.categoriesWithItems : '')}>
                        <MenuCategories />
                    </div>
                </div>
                <div style={{ height: '80vh' }}>
                    <div className={classNames(classes.productsContainer, items.length !== 0 ? classes.productsContainerWithItems : '')}>
                        {getItems()?.map((menuItem) => <MenuItem key={menuItem?.menuItemId} menuItem={menuItem} />)}
                    </div>
                </div>
            </div>
            {items?.length && <Cart />}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    kiosk: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
    },
    container: {
        padding: '0px 10px 24px',
        display: 'flex',
        flexDirection: 'row',
        gap: 24,
        height: '100vh',
        backgroundColor: '#FFF',
    },
    title: {
        fontSize: '7vw',
        margin: 0,
        marginBottom: '1vh',
        fontFamily: theme.typography.bold,
        color: '#2C374A',
    },
    categories: {
        height: '85vh',
        minWidth: 150,
        overflow: 'scroll',
        paddingBottom: '10vh',
        flexShrink: 0,
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    categoriesWithItems: {
        paddingBottom: '20vh',
    },
    productsContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(20vw, 2fr))',
        columnGap: '2vw',
        rowGap: '2vh',
        overflow: 'scroll',
        marginBottom: '10vh',
        maxHeight: '85vh',
        paddingBottom: '12vh',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    productsContainerWithItems: {
        paddingBottom: '24vh',
    },
    bannerImage: {
        height: 250,
        width: '100%',
        backgroundColor: theme.palette.secondary.dark,
        objectFit: 'cover',
        pointerEvents: 'none',
    },
    button: {
        position: 'sticky',
        top: 0,
        paddingBottom: 10,
        margin: '0 auto',
        color: theme.palette.primary.main,
        marginTop: '1vh',
    },
    categoriesBar: {
        display: 'flex',
        flexDirection: 'column',
    },
}));
