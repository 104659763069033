import { pidedirectokioskApiMethodPublic } from 'src/api/config/pidedirectokioskApiMethodPublic';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { AwsCognitoUsername } from 'src/types/Id';

export async function ensureManagerCognitoUserExistsInDevEnvironmentApi(request: Request): ApiSauceResponse<void> {
    return pidedirectokioskApiMethodPublic('authentication/ensureManagerCognitoUserExistsInDevEnvironmentApi', request);
}

type Request = {
    username: AwsCognitoUsername;
};
