import type { AppState } from 'src/reducers/appReducer';
import { createPaymentDistribution } from 'src/utils/cart/createPaymentDistribution';

export function refreshPaymentState(state: AppState): void {
    if (!state.items.length) {
        delete state.payment;
        return;
    }

    const payment = createPaymentDistribution({
        orderItems: state.items,
        promotions: state.promotions,
        orderType: state.orderType,
    });

    state.payment = {
        subtotal: payment.subtotal,
        productDiscount: payment.productDiscount,
        promotionsDiscount: payment.promotionsDiscount,
        total: payment.total,
        usedPromotions: payment.usedPromotions,
    };
}
