import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button } from 'src/components/Button';
import { GoBackButton } from 'src/components/button/GoBackButton';
import { Input } from 'src/components/Input';
import { SubscribedPage } from 'src/components/page/SubscribedPage';
import { ModifierTypes } from 'src/constants/ModifierType';
import { OrderSteps } from 'src/constants/OrderStep';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { Modifier } from 'src/scenes/menu/Modifier';
import { ModifierGroupHeader } from 'src/scenes/menu/ModifierGroupHeader';
import { useAddItem } from 'src/services/order/useAddItem';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { CartItemVm, ModifierVm } from 'src/types/CartItemVm';
import { MenuItemId } from 'src/types/Id';
import { ModifierGroupVm } from 'src/types/ModifierGroupVm';
import { ModifierVm as MenuItemModifierVm } from 'src/types/ModifierVm';
import { removeDuplicates } from 'src/utils/array/removeDuplicates';
import { calculateOrderItemSubtotalUnitPrice } from 'src/utils/order/calculateOrderItemSubtotalUnitPrice';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { sum } from 'src/utils/reduce/sum';
import { removeNulls } from 'src/utils/removeNulls';
import { requireValue } from 'src/utils/require/requireValue';

export function ModifiersPage(): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [addItem] = useAddItem();

    const [formMenuItemId, setFormMenuItemId] = useState<MenuItemId>();
    const [form, setForm] = useState<Record<string, Array<ModifierVm>>>({});
    const [note, setNote] = useState('');
    const [missingSectionName, setMissingSectionName] = useState('');

    const item = useSelector((state) => state.app.itemSelected);
    const quantity = useSelector((state) => state.app.selectedMenuItemQuantity);
    const selectedMenu = useSelector((state) => state.app.selectedMenu);
    const menuCategorySelected = useSelector((state) => state.app.menuCategorySelected);
    const modifierGroups = useSelector((state) => state.app.kioskMenu?.modifierGroups);
    const menuCategories = useSelector((state) => state.app.kioskMenu?.menuCategories);

    console.log('quantity =', quantity);

    const setOrderStep = useAction(appReducer.actions.setOrderStep);

    const menuCategoryItemBelongs = menuCategories?.find((menuCategory) => menuCategory.menuCategoryId === item?.menuCategoryId);

    useEffect(() => {
        if (item) {
            setFormMenuItemId(item.menuItemId);
            setForm({
                ...getModifierGroups()?.reduce(
                    (form, modifierGroup) => {
                        form[modifierGroup.name] = [];
                        modifierGroup.modifiers.forEach((modifier) => {
                            if (modifier.preselected) {
                                form[modifierGroup.name].push({
                                    modifierId: modifier.menuItemId,
                                    name: modifier.name,
                                    price: modifier.price,
                                    quantity: 1,
                                    modifierType: modifier.modifierType,
                                });
                            }
                        });
                        return form;
                    },
                    {} as Record<string, Array<ModifierVm>>,
                ),
            });
            setNote('');
        }
    }, [item]);

    useEffect(() => {
        if (missingSectionName) {
            setTimeout(() => setMissingSectionName(''), 700);
        }
    }, [missingSectionName]);

    const showMissingSection = () => {
        const modifierGroups = getModifierGroups();
        for (const modifierGroup of modifierGroups) {
            if (!modifierGroup) return;

            const modifiersInGroup = getTotalModifiers(modifierGroup);
            if (modifierGroup.requiredMin && modifiersInGroup < modifierGroup.requiredMin) {
                setMissingSectionName(modifierGroup.name);
                break;
            }
            if (modifierGroup.requiredMax && modifiersInGroup > modifierGroup.requiredMax) {
                setMissingSectionName(modifierGroup.name);
                break;
            }
        }
    };

    const getModifierGroups = (): Array<ModifierGroupVm> => {
        const menuCategoryModifierGroupIds = menuCategoryItemBelongs?.modifierGroupIds ?? menuCategorySelected?.modifierGroupIds ?? [];
        const modifierGroupIds = removeDuplicates([...(item?.modifierGroupIds ?? []), ...menuCategoryModifierGroupIds]);
        return removeNulls(
            modifierGroupIds?.map((modifierGroupId) => {
                const modifierGroup = modifierGroups?.find((modifierGroup) => modifierGroup.modifierGroupId === modifierGroupId);
                if (!modifierGroup) return null as any;
                return modifierGroup;
            }) ?? [],
        );
    };

    const addModifier = (modifier: MenuItemModifierVm, _modifierGroup: ModifierGroupVm, _quantity: any) => {
        const modifierGroup = requireValue(_modifierGroup);
        let updatedModifierGroup = [...(form[modifierGroup.name] ?? [])];

        if (form[modifierGroup.name].some((_modifier: any) => _modifier.name === modifier.name)) {
            if (modifier.modifierType === ModifierTypes.SINGLE) {
                updatedModifierGroup = updatedModifierGroup.filter((_modifier) => _modifier.name !== modifier.name);
            } else if (modifier.modifierType === ModifierTypes.MULTIPLE) {
                const updatedModifier = updatedModifierGroup.find((_modifier) => _modifier.name === modifier.name);
                if (!updatedModifier) return;

                updatedModifier.quantity = _quantity ?? 1;
            }
        } else {
            if (modifierGroup.requiredMax && form[modifierGroup.name].length === modifierGroup.requiredMax) {
                updatedModifierGroup.shift();
            }
            updatedModifierGroup.push({
                modifierId: modifier.menuItemId,
                name: modifier.name,
                price: modifier.price,
                quantity: _quantity ?? 1,
                modifierType: modifier.modifierType,
            });
        }

        const updatedForm = {
            ...form,
            [modifierGroup.name]: updatedModifierGroup,
        };
        setForm(updatedForm);
    };

    const handleAddKioskItem = async () => {
        if (!item) return;
        if (disabled) {
            showMissingSection();
            return;
        }

        await addItem({
            menuItemId: item.menuItemId,
            menuItemType: item.menuItemType,
            menuCategoryId: menuCategorySelected?.menuCategoryId,
            name: item.name,
            unitPrice: item.price,
            promoUnitPrice: item.promoPrice,
            promoText: item.promoText,
            size: item.size,
            imageUrl: item.imageUrl,
            quantity,
            note,
            modifierGroups: getOrderItemModifierGroups(),
            pickupTime: selectedMenu?.pickupTime,
        } as CartItemVm);

        setOrderStep(OrderSteps.CREATE_ORDER);
    };

    if (!formMenuItemId || !item?.menuItemId || item?.menuItemId !== formMenuItemId) {
        return null;
    }

    const getOrderItemModifierGroups = () => {
        return getModifierGroups()?.reduce<Array<any>>((selectedModifierGroups, modifierGroup) => {
            const selectedModifierGroup = {
                name: modifierGroup.name,
                showModifierGroupNameInCommand: modifierGroup.showModifierGroupNameInCommand,
                freeModifiersQuantity: modifierGroup.freeModifiersQuantity,
                modifiers: modifierGroup.modifiers.reduce<Array<any>>((selectedModifiers, modifier) => {
                    const modifierAdded = form[modifierGroup.name].find((_modifier: any) => _modifier.name === modifier.name);
                    if (modifierAdded) {
                        const selectedModifier = {
                            modifierId: modifierAdded.modifierId,
                            name: modifierAdded.name,
                            price: modifierAdded.price,
                            quantity: modifierAdded.quantity,
                        };
                        selectedModifiers.push(selectedModifier);
                    }
                    return selectedModifiers;
                }, []),
            };
            if (selectedModifierGroup.modifiers.length > 0) {
                selectedModifierGroups.push(selectedModifierGroup);
            }
            return selectedModifierGroups;
        }, []);
    };

    const totalPrice = calculateOrderItemSubtotalUnitPrice({
        menuItemId: item.menuItemId,
        menuItemType: item.menuItemType,
        menuCategoryId: menuCategorySelected?.menuCategoryId,
        name: item.name,
        unitPrice: item.price,
        promoUnitPrice: item.promoPrice,
        promoText: item.promoText,
        size: item.size,
        imageUrl: item.imageUrl,
        quantity,
        note,
        modifierGroups: getOrderItemModifierGroups(),
        pickupTime: selectedMenu?.pickupTime,
    } as CartItemVm);

    const getTotalModifiers = (modifierGroup: ModifierGroupVm) => {
        if (!modifierGroup.requiredMax) return 0;
        return form[modifierGroup.name]?.reduce((totalItems: any, modifier: any) => {
            totalItems += modifier.quantity;
            return totalItems;
        }, 0);
    };

    const getSections = () => {
        const modifierGroups = getModifierGroups();
        return modifierGroups.map((modifierGroup) => {
            return {
                key: modifierGroup?.name,
                modifierGroup: modifierGroup,
                data: modifierGroup?.modifiers,
            };
        });
    };

    const disabled =
        getModifierGroups()?.reduce((disabled: boolean, modifierGroup: ModifierGroupVm) => {
            const selectedModifiers = form[modifierGroup.name];
            const selectedModifiersQuantity =
                selectedModifiers
                    .map((selectedModifier) => selectedModifier.quantity)
                    .reduce(sum, BigNumber(0))
                    .toNumber() ?? 0;
            if (modifierGroup.requiredMin && selectedModifiersQuantity < modifierGroup.requiredMin) {
                disabled = true;
            }
            if (modifierGroup.requiredMax && selectedModifiersQuantity > modifierGroup.requiredMax) {
                disabled = true;
            }
            return disabled;
        }, false) ?? false;

    if (!item) return null;

    return (
        <SubscribedPage title={'Kiosk modifiers'} className={classes.container}>
            <GoBackButton />
            <div>
                <h1 className={classes.title}>{translate('Customize your @itemName', { itemName: item.name })}</h1>
                <div className={classes.sectionsContainer}>
                    {getSections()?.map((section) => (
                        <div className={classes.sectionContainer} key={section.key}>
                            <ModifierGroupHeader modifierGroup={section.modifierGroup} missingSectionName={missingSectionName} />
                            <div className={classes.modifiersContainer}>
                                {section.data.map((modifier) => {
                                    const modifiersInGroup = getTotalModifiers(section.modifierGroup);
                                    return (
                                        <Modifier
                                            key={modifier.name}
                                            modifierGroup={section.modifierGroup}
                                            modifier={modifier}
                                            selected={form[section.modifierGroup.name].some((_modifier) => _modifier.name === modifier.name)}
                                            addModifier={(quantity: number) => addModifier(modifier, section.modifierGroup, quantity)}
                                            modifiersInGroup={modifiersInGroup}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    ))}
                    <div className={classes.notesContainer}>
                        <Input
                            name={'notes'}
                            label={translate('Type your special instruction')}
                            value={note}
                            onChange={(note: string) => setNote(note)}
                            classes={{ container: classes.inputWrapper, inputContainer: classes.inputContainer, input: classes.input, label: classes.label }}
                        />
                    </div>
                </div>
            </div>
            <div className={classes.buttonsContainer}>
                <Button onClick={handleAddKioskItem} classes={{ button: classes.addButton }}>
                    {translate('Add @total', { total: formatAsCurrencyNumber(totalPrice?.toString() ?? 0) })}
                </Button>
            </div>
        </SubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100vh',
        overflowY: 'scroll',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '5vw',
        paddingTop: '8vh',
        paddingBottom: '1vh',
    },
    title: {
        fontSize: '5vw',
        alignSelf: 'center',
        textAlign: 'center',
        fontFamily: theme.typography.bold,
        color: '#2C374A',
    },
    sectionsContainer: {
        height: '65vh',
        overflow: 'scroll',
        overflowX: 'hidden',
    },
    sectionContainer: {},
    modifiersContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(40vw, 2fr))',
        columnGap: '4vw',
        rowGap: '1vh',
    },
    buttonsContainer: {
        marginTop: '2vh',
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'flex-end',
    },
    addButton: {
        marginTop: '3vh',
        fontSize: '4vw',
        paddingTop: '1.5vh',
        paddingBottom: '1.5vh',
        paddingLeft: '2.5vh',
        paddingRight: '2.5vh',
        borderRadius: 12,
    },
    notesContainer: {
        width: '100%',
        marginTop: '3vh',
    },
    inputWrapper: {
        width: '100%',
        borderWidth: '2px',
        marginBottom: 20,
    },
    inputContainer: {
        width: '98%',
        height: '10vw',
        padding: '0 10px',
        margin: '0 1%',
    },
    input: {
        fontSize: '3.5vw',
    },
    label: {
        fontSize: '3.5vw',
        marginBottom: '1vh',
    },
}));
