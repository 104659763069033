import * as React from 'react';

export function SimpleTerminalIcon({ color, title }: Props): React.ReactElement {
    return (
        <svg width='13' height='19' viewBox='0 0 13 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {title && <title>{title}</title>}
            <path d='M9.5 10C9.77614 10 10 9.77614 10 9.5C10 9.22386 9.77614 9 9.5 9L2.75 9C2.47386 9 2.25 9.22386 2.25 9.5C2.25 9.77614 2.47386 10 2.75 10L9.5 10Z' fill={color ?? '#616B79'} />
            <path
                d='M9.5 12.25C9.77614 12.25 10 12.0261 10 11.75C10 11.4739 9.77614 11.25 9.5 11.25H8.65625C8.38011 11.25 8.15625 11.4739 8.15625 11.75C8.15625 12.0261 8.38011 12.25 8.65625 12.25H9.5Z'
                fill={color ?? '#616B79'}
            />
            <path
                d='M6.96875 12.25C7.24489 12.25 7.46875 12.0261 7.46875 11.75C7.46875 11.4739 7.24489 11.25 6.96875 11.25H5.28125C5.00511 11.25 4.78125 11.4739 4.78125 11.75C4.78125 12.0261 5.00511 12.25 5.28125 12.25H6.96875Z'
                fill={color ?? '#616B79'}
            />
            <path
                d='M3.59375 12.25C3.86989 12.25 4.09375 12.0261 4.09375 11.75C4.09375 11.4739 3.86989 11.25 3.59375 11.25H2.75C2.47386 11.25 2.25 11.4739 2.25 11.75C2.25 12.0261 2.47386 12.25 2.75 12.25H3.59375Z'
                fill={color ?? '#616B79'}
            />
            <path
                d='M10 14C10 14.2761 9.77614 14.5 9.5 14.5H8.65625C8.38011 14.5 8.15625 14.2761 8.15625 14C8.15625 13.7239 8.38011 13.5 8.65625 13.5H9.5C9.77614 13.5 10 13.7239 10 14Z'
                fill={color ?? '#616B79'}
            />
            <path
                d='M7.46875 14C7.46875 14.2761 7.24489 14.5 6.96875 14.5H5.28125C5.00511 14.5 4.78125 14.2761 4.78125 14C4.78125 13.7239 5.00511 13.5 5.28125 13.5H6.96875C7.24489 13.5 7.46875 13.7239 7.46875 14Z'
                fill={color ?? '#616B79'}
            />
            <path
                d='M4.09375 14C4.09375 14.2761 3.86989 14.5 3.59375 14.5H2.75C2.47386 14.5 2.25 14.2761 2.25 14C2.25 13.7239 2.47386 13.5 2.75 13.5H3.59375C3.86989 13.5 4.09375 13.7239 4.09375 14Z'
                fill={color ?? '#616B79'}
            />
            <path
                d='M9.5 16.75C9.77614 16.75 10 16.5261 10 16.25C10 15.9739 9.77614 15.75 9.5 15.75H8.65625C8.38011 15.75 8.15625 15.9739 8.15625 16.25C8.15625 16.5261 8.38011 16.75 8.65625 16.75H9.5Z'
                fill={color ?? '#616B79'}
            />
            <path
                d='M6.96875 16.75C7.24489 16.75 7.46875 16.5261 7.46875 16.25C7.46875 15.9739 7.24489 15.75 6.96875 15.75H5.28125C5.00511 15.75 4.78125 15.9739 4.78125 16.25C4.78125 16.5261 5.00511 16.75 5.28125 16.75H6.96875Z'
                fill={color ?? '#616B79'}
            />
            <path
                d='M3.59375 16.75C3.86989 16.75 4.09375 16.5261 4.09375 16.25C4.09375 15.9739 3.86989 15.75 3.59375 15.75H2.75C2.47386 15.75 2.25 15.9739 2.25 16.25C2.25 16.5261 2.47386 16.75 2.75 16.75H3.59375Z'
                fill={color ?? '#616B79'}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M10 3.25V4.51234C11.2633 4.63777 12.25 5.70366 12.25 7V16.5C12.25 17.8807 11.1307 19 9.75 19H2.5C1.11929 19 0 17.8807 0 16.5V7C0 5.70366 0.98667 4.63777 2.25 4.51234V2.5C2.25 1.11929 3.36929 0 4.75 0H9.75C11.1307 0 12.25 1.11929 12.25 2.5V3.25H10ZM3.25 2.5C3.25 1.67157 3.92157 1 4.75 1H9V6.75H3.25V2.5ZM10 2.25V1.02074C10.6281 1.12612 11.1239 1.62187 11.2293 2.25H10ZM2.25 5.52074C1.54057 5.63976 1 6.25675 1 7V16.5C1 17.3284 1.67157 18 2.5 18H9.75C10.5784 18 11.25 17.3284 11.25 16.5V7C11.25 6.25675 10.7094 5.63976 10 5.52074V7.75H2.25V5.52074Z'
                fill={color ?? '#616B79'}
            />
        </svg>
    );
}

type Props = {
    color?: string;
    title?: string;
};
