import * as React from 'react';
import { appReducer } from 'src/reducers/appReducer';
import { MenuCategory } from 'src/scenes/menu/MenuCategory';
import { useIsPickupStationUser } from 'src/services/useIsPickupStationUser';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function MenuCategories(): React.ReactElement | any {
    const isPickupStationUser = useIsPickupStationUser();

    const kioskMenu = useSelector((state) => state.app.kioskMenu);
    const selectedMenu = useSelector((state) => state.app.selectedMenu);
    const menuCategories = useSelector((state) => state.app.kioskMenu?.menuCategories) ?? [];

    const selectMenuCategory = useAction(appReducer.actions.selectMenuCategory);

    const selectCategory = (menuCategory: any) => selectMenuCategory(menuCategory);

    if (isPickupStationUser && selectedMenu) {
        return selectedMenu?.menuCategoryIds?.map((menuCategoryId) => {
            const menuCategory = menuCategories.find((menuCategory) => menuCategory.menuCategoryId === menuCategoryId);
            if (!menuCategory) return null;

            return <MenuCategory key={menuCategory.menuCategoryId} category={menuCategory} onClick={() => selectCategory(menuCategory)} />;
        });
    }

    return kioskMenu?.menus?.map((menu) => {
        return menu.menuCategoryIds?.map((menuCategoryId) => {
            const menuCategory = menuCategories.find((menuCategory) => menuCategory.menuCategoryId === menuCategoryId);
            if (!menuCategory) return null;
            return <MenuCategory key={menuCategory.menuCategoryId} category={menuCategory} onClick={() => selectCategory(menuCategory)} />;
        });
    });
}
