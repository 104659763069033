import * as React from 'react';
import { DeprecatedSelectPaymentMethodPage } from 'src/scenes/DeprecatedSelectPaymentMethodPage';
import { SelectRestaurantPaymentMethodPage } from 'src/scenes/SelectRestaurantPaymentMethodPage';
import { useSelector } from 'src/utils/react/useSelector';

export function SelectPaymentMethodPage(): React.ReactElement {
    const restaurant = useSelector((state) => state.app.restaurant);

    if (!!restaurant?.restaurantPaymentMethods) return <SelectRestaurantPaymentMethodPage />;
    return <DeprecatedSelectPaymentMethodPage />;
}
