import { makeStyles } from '@material-ui/core';
import { Text } from '@pidedirecto/ui';
import { useTheme } from '@pidedirecto/ui/hooks';
import moment from 'moment-timezone';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { OrderSteps } from 'src/constants/OrderStep';
import { OrderType, OrderTypes } from 'src/constants/OrderType';
import { TimeZones } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import { NotFoundCategoryImageIcon } from 'src/icons/NotFoundCategoryImageIcon';
import { appReducer } from 'src/reducers/appReducer';
import { useItemsGroupedByDay } from 'src/services/order/useItemsGroupedByDay';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { useConfirmDialog } from 'src/services/useConfirmDialog';
import { useIsPickupStationUser } from 'src/services/useIsPickupStationUser';
import type { MenuItemVm } from 'src/types/MenuItemVm';
import { getDayFromNumberDay } from 'src/utils/date/getDayFromNumberDay';
import { doesPromotionIncludeAllOrderTypes } from 'src/utils/promotion/doesPromotionIncludeAllOrderTypes';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function MenuItem({ menuItem }: Props): React.ReactElement | null {
    const classes = useStyles();
    const theme = useTheme();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const isPickupStationUser = useIsPickupStationUser();
    const itemsGroupedByDay = useItemsGroupedByDay();
    const confirmDialog = useConfirmDialog();

    const selectedDay = useSelector((state) => state.app.selectedDay);
    const timeZone = useSelector((state) => state.app.restaurant?.timeZone);
    const customerEmployee = useSelector((state) => state.app.customerEmployee);
    const promotions = useSelector((state) => state.app.promotions);
    const menus = useSelector((state) => state.app.kioskMenu?.menus);

    const setOrderStep = useAction(appReducer.actions.setOrderStep);
    const selectKioskItem = useAction(appReducer.actions.selectKioskItem);

    const menuProductInfo = menuItem?.menusProductInfo?.find((menuProductInfo) => menus?.some((menu) => menu.menuId === menuProductInfo.menuId));

    if (!menuItem) return null;

    const promotion = promotions?.find((promotion) => promotion.menuItemIds.includes(menuItem.menuItemId));

    const handleClickMenuItem = () => {
        if (hasItemAddedOnSelectedDay() && isPickupStationUser && customerEmployee?.oneProductPerDay) {
            confirmDialog({
                content: translate('You already have a product added to your cart for the selected day'),
                timeoutSeconds: 15,
                buttonText: translate('Accept'),
            });
            return;
        }
        selectKioskItem(menuItem);
        setOrderStep(OrderSteps.PRODUCT_DETAIL);
    };

    const hasItemAddedOnSelectedDay = () => {
        return itemsGroupedByDay?.some((itemsByDay) => {
            const day = getDayFromNumberDay(moment.tz(itemsByDay?.[0].pickupTime, timeZone ?? TimeZones.AMERICA_MONTERREY).isoWeekday());

            return day === selectedDay;
        });
    };

    const getPromotionLabel = () => {
        if (!promotion) return '';
        if (doesPromotionIncludeAllOrderTypes(promotion)) return promotion.name;
        const orderTypesAvailableInKiosk = [OrderTypes.SELF_SERVICE_ORDER, OrderTypes.TAKE_AWAY_ORDER] as Array<OrderType>;
        const validOrderTypes = promotion.orderTypes.filter((orderType) => orderTypesAvailableInKiosk.includes(orderType));

        return `${promotion.name} ${translate('only @orderTypes', { orderTypes: validOrderTypes.map((orderType) => translate(`OrderTypes.${orderType}`)).join(', ') })}`;
    };

    if (menuProductInfo?.hidden) return null;

    return (
        <Button text onClick={handleClickMenuItem} classes={{ button: classes.menuItem }}>
            {menuItem.imageUrl && <img src={menuItem.imageUrl} alt={`image for ${menuItem.name}`} className={classes.image} />}
            {!menuItem.imageUrl && (
                <div className={classes.noImageContainer}>
                    <NotFoundCategoryImageIcon title={'category'} />
                </div>
            )}
            <div className={classes.name}>{menuItem.name}</div>
            {!!promotion && (
                <Text className={classes.promotionName} style={{ color: theme.palette?.text?.brand }}>
                    {getPromotionLabel()}
                </Text>
            )}
            {!menuItem.promoPrice && <div className={classes.price}>{formatAsCurrencyNumber(menuItem.price)}</div>}
            {menuItem.promoPrice && (
                <div className={classes.priceContainer}>
                    <div className={classes.lastPrice}>{formatAsCurrencyNumber(menuItem.price)}</div>
                    <span className={classes.price}>{formatAsCurrencyNumber(menuItem.promoPrice)}</span>
                    {menuItem.promoText && <p className={classes.promoText}>{menuItem.promoText}</p>}
                </div>
            )}
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    menuItem: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: 0,
        width: '100%',
        '&:focus': {
            margin: 0,
            border: 0,
        },
    },
    image: {
        borderRadius: 20,
        width: '100%',
        aspectRatio: 1,
        objectFit: 'cover',
        flexShrink: 0,
        marginBottom: 2,
        pointerEvents: 'none',
        marginTop: '2vh',
    },
    noImageContainer: {
        borderRadius: 20,
        width: '100%',
        aspectRatio: 1,
        backgroundColor: theme.palette.secondary.dark,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        marginBottom: 12,
        padding: 24,
        marginTop: '2vh',
    },
    noImage: {
        width: '100%',
        aspectRatio: 1,
        objectFit: 'cover',
    },
    name: {
        fontFamily: theme.typography.semiBold,
        fontSize: '3.2vw',
        margin: 0,
        textAlign: 'left',
        color: '#2C374A',
        width: '100%',
        lineHeight: 1.2,
        whiteSpace: 'normal',
    },
    price: {
        display: 'flex',
        fontFamily: theme.typography.medium,
        fontSize: '3.2vw',
        lineHeight: 1,
        margin: 0,
        color: '#2C374A',
        marginBottom: '2vh',
    },
    lastPrice: {
        textDecoration: 'line-through',
        fontFamily: theme.typography.medium,
        color: theme.palette.text.disabled,
        fontSize: '3.2vw',
        lineHeight: 1,
        margin: 0,
        marginRight: '1vw',
    },
    promoText: {
        position: 'absolute',
        top: -12,
        left: 12,
        fontSize: 24,
        width: 'fit-content',
        maxWidth: '100%',
        backgroundColor: '#EBFEFE',
        color: '#1B5764',
        borderRadius: '12px',
        padding: '8px 20px',
    },
    priceContainer: {
        display: 'flex',
    },
    promotionName: {
        fontSize: '3vw',
        textAlign: 'left',
    },
}));

type Props = {
    menuItem?: MenuItemVm;
};
