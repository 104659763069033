import { pidedirectokioskApiMethod } from 'src/api/config/pidedirectokioskApiMethod';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import { RestaurantMenusVm } from 'src/types/RestaurantMenusVm';

export async function getKioskMenuApi(request: GetKioskMenuApiRequest): ApiSauceResponse<RestaurantMenusVm> {
    return pidedirectokioskApiMethod('getKioskMenuApi', request);
}

type GetKioskMenuApiRequest = {
    restaurantId: RestaurantId;
};
