import * as React from 'react';
import 'src/config/configureI18n';
import 'src/config/configureMoment';
import { CircularLoadingIndicator } from 'src/components/page/CircularLoadingIndicator';
import { KioskPage } from 'src/scenes/KioskPage';
import { SignInPage } from 'src/scenes/SignInPage';
import { useIsUserSignedIn } from 'src/services/useIsUserSignedIn';

export function App(): React.ReactElement {
    const [loading, isUserSignedIn] = useIsUserSignedIn();

    if (loading) {
        return <CircularLoadingIndicator />;
    }

    if (!isUserSignedIn) return <SignInPage />;

    return <KioskPage />;
}
