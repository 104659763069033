import * as React from 'react';
import { SubscribedPage } from 'src/components/page/SubscribedPage';
import { Menu } from 'src/scenes/menu/Menu';
import { MenuLoader } from 'src/scenes/menu/MenuLoader';
import { WeekSummary } from 'src/scenes/menu/WeekSummary';

export function MenuPage(): React.ReactElement {
    return (
        <SubscribedPage title={'Menu'}>
            <MenuLoader>
                <WeekSummary />
                <Menu />
            </MenuLoader>
        </SubscribedPage>
    );
}
