import * as React from 'react';
import { FormSelect } from 'src/components/form/FormSelect';
import type { Variant } from 'src/components/form/FormTypes';
import { PaymentTerminalPaymentFailedReasons } from 'src/constants/PaymentTerminalPaymentFailedReason';

export function FormPaymentTerminalPaymentFailedReasonSelect({ name, label, placeholder, helperText, defaultValue, disabled, required, variant }: Props): React.ReactElement {
    const options = Object.values(PaymentTerminalPaymentFailedReasons).map((paymentTerminalPaymentFailedReason: any) => ({
        label: paymentTerminalPaymentFailedReason.replace(/_/g, ' '),
        value: paymentTerminalPaymentFailedReason,
    }));

    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled}
            required={required}
            variant={variant}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?: boolean;
    variant?: Variant;
};
