import { BigNumber } from 'bignumber.js';
import { DiscountTypes } from 'src/constants/DiscountType';
import type { OrderType } from 'src/constants/OrderType';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import { RewardTypes } from 'src/constants/RewardType';
import type { CartItemVm } from 'src/types/CartItemVm';
import type { CustomPaymentMethod } from 'src/types/Id';
import type { PromoCodeVm } from 'src/types/PromoCodeVm';
import { calculateOrderItemSubtotalPrice } from 'src/utils/order/calculateOrderItemSubtotalPrice';

export function calculatePromoCodeDiscount(
    promoCode: PromoCodeVm,
    orderType: OrderType | null | undefined,
    paymentMethod: PaymentMethod | CustomPaymentMethod | undefined,
    amount: string,
    orderItems: Array<CartItemVm>,
): string | undefined {
    if (!promoCode) {
        return;
    }

    const isProductPromoCode = promoCode.rewardType === RewardTypes.PRODUCTS;

    let applicableProductsAmount = BigNumber(0);
    let discountAmount = BigNumber(0);

    if (isProductPromoCode) {
        const menuItems = orderItems.filter((orderItem) => promoCode.menuItemIds?.includes(orderItem.menuItemId));
        let orderItemsSubTotal = BigNumber(0);
        menuItems.forEach((menuItem) => {
            const orderItemSubtotal = calculateOrderItemSubtotalPrice(menuItem);
            orderItemsSubTotal = orderItemsSubTotal.plus(orderItemSubtotal);
        });
        applicableProductsAmount = BigNumber(applicableProductsAmount).plus(orderItemsSubTotal);
    }

    if (promoCode.discountType === DiscountTypes.AMOUNT) {
        discountAmount = discountAmount.plus(promoCode.discount);
    }

    if (promoCode.discountType === DiscountTypes.PERCENT) {
        const percentDiscount = BigNumber(BigNumber(amount).multipliedBy(promoCode.discount).dividedBy(100).toFixed(2));

        discountAmount = discountAmount.plus(percentDiscount);
    }

    if (!applicableProductsAmount.isZero() && isProductPromoCode && discountAmount.isGreaterThanOrEqualTo(applicableProductsAmount)) return applicableProductsAmount.toString();

    if (!promoCode.freeDelivery && !!promoCode.maxDiscountAmount) {
        if (discountAmount.isGreaterThanOrEqualTo(promoCode.maxDiscountAmount ?? 0)) return promoCode.maxDiscountAmount;
    }

    if (!promoCode.maxDiscountAmount && discountAmount.isGreaterThanOrEqualTo(amount)) return amount.toString();

    return discountAmount.toString();
}
