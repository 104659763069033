export const es: {
    [key: string]: string;
} = {
    'Pay your bill to continue using your service': 'Paga ahora para continuar usando tu servicio',
    'Tap to order': 'Toca para ordenar',
    'Pay Here': 'Paga Aquí',
    'Verb.Order': 'Ordena',
    'Where do you enjoy your meal today?': '¿Dónde disfrutarás tu comida hoy?',
    'Eat here': 'Comer aquí',
    'Take out': 'Para llevar',
    'Enter to AmbitOne': 'Entra a tu AmbitOne',
    'Sign In': 'Iniciar Sesión',
    'Username': 'Usuario',
    'Password': 'Contraseña',
    'Enter your username or email': 'Tu email o usuario',
    'Enter your password': 'Tu contraseña',
    'Sign in': 'Iniciar sesión',
    'By logging in you accept our': 'Al iniciar sesión aceptas nuestros',
    'Terms of use and privacy policy.': 'Términos y Condiciones y Política de Privacidad',
    'REQUIRED': 'REQUERIDO',
    'Type your special instruction': 'Escriba sus indicaciones adicionales',
    'Add @total': 'Agregar @total',
    'Customize your @itemName': 'Personaliza tu @itemName',
    'My Order': 'Mi Orden',
    'Continue': 'Continuar',
    'Back': 'Atrás',
    'Go back': 'Regresar',
    'Total': 'Total',
    'Items': 'Artículos',
    'Customize': 'Personalizar',
    'Add': 'Agregar',
    'Where would you like to pay?': '¿Dónde te gustaría pagar?',
    'No payment methods enabled': 'No tienes ningún método de pago habilitado',
    '(Only Card)': '(solo tarjeta)',
    'Pay with credit card in the counter': 'Pagar en el mostrador con tarjeta',
    'Pay with cash in the counter': 'Pagar en el mostrador con efectivo',
    'Total:': 'Total:',
    'Note: ': 'Notas: ',
    'Are you still here?': '¿Sigues aquí?',
    'Yes, I am': 'Sí, lo estoy',
    'Exit': 'Salir',
    'Service suspended': 'Servicio suspendido',
    "Contact your Let's Eat representative to reactivate it": "Comunícate con tu representante de Let's Eat para reactivarlo",
    'Image of @cartItem': 'Imagen de @cartItem',
    'This field is required': 'Este campo es requerido',
    'Invalid phone number': 'Número de teléfono inválido',
    'Invalid email': 'Email inválido',
    'Must be at least @number characters': 'Debe ser mayor a @number caracteres',
    'Must be at most @number characters': 'Debe ser menor a @number caracteres',
    'We will notify you when the order is ready': 'Te avisaremos cuando tu orden esté lista',
    'Name': 'Nombre',
    'Log out': 'Cerrar sesión',
    'Total to pay': 'Total a pagar',
    'Pay': 'Pagar',
    'Available Credits': 'Créditos disponibles',
    'Incorrect password, try again': 'Contraseña incorrecta, por favor intenta nuevamente.',
    'Phone Number': 'Número de teléfono',
    'Follow the terminal instructions': 'Sigue las instrucciones de la terminal',
    'Thanks for you preference': 'Gracias por tu preferencia',
    'Order #@dailyOrderId': 'Orden #@dailyOrderId',
    'You will receive a message when your order is ready': 'Recibirás un mensaje cuando tu orden esté lista',
    'We are creating your order': 'Estamos creando tu orden',
    'Identify yourself to order': 'Identifícate para ordenar',
    'Employee Number': 'Número de empleado',
    'By continue, you accept our': 'Al continuar, aceptas nuestros',
    'Validating Employee Number': 'Validando Número de Empleado',
    'Terms and Conditions of Use of the “PideDirecto” Platform': 'Términos y Condiciones de Uso de la Plataforma “PideDirecto”',
    'Notice of Privacy (Privacy Policy) - Users': 'Aviso de Privacidad (Política de Privacidad) - Usuarios',
    'Close': 'Cerrar',
    'Back to home': 'Volver al inicio',
    'Scheduled order': 'Orden programada',
    'Scheduled orders': 'Órdenes programadas',
    'Enjoy your order!': '¡Buen provecho!',
    'Try again': 'Intentar de nuevo',
    'Days.Mo': 'Lunes',
    'Days.Tu': 'Martes',
    'Days.We': 'Miércoles',
    'Days.Th': 'Jueves',
    'Days.Fr': 'Viernes',
    'Days.Sa': 'Sábado',
    'Days.Su': 'Domingo',
    'Accept': 'Aceptar',

    '@freeModifiersQuantity Modifiers free': '@freeModifiersQuantity Modificadores gratis',
    'If it exceeds the free quantity, the higher value modifiers will be added': 'Si supera la cantidad gratuita, se cobrarán los modificadores de mayor valor',
    'Order your food here': 'Pide tu comida aquí',
    'Play to start': 'Toca para empezar',
    'No menu to display on the selected day': 'No hay menú para el día seleccionado',
    'You have already placed an order on this day': 'Ya ha realizado un pedido en este día',
    'Out of schedule': 'Fuera de horario',
    'This dish is no longer available at this time': 'Este producto ya no está disponible en este momento',
    'Oops! Something went wrong': '¡Ups! Algo salió mal',
    'Error programming order, try again later': 'Error al programar el pedido, inténtelo más tarde',
    'We had problems creating your order': 'Tuvimos problemas al crear tu órden',
    'Your order will be delivered according to the scheduled day.': 'Tu orden se entregará de acuerdo al día programado.',
    'Your orders will be delivered according to the scheduled days.': 'Tu órdenes se entregarán de acuerdo los días programados.',
    'Error to programming the order, try again later': 'Error al programar orden, intenta más tarde',
    'By continuing, you are accepting the ': 'Al continuar, aceptas las',
    ' and ': ' y ',
    'Failed to do the payment.': 'Hubo un error al realizar el pago',
    'Terminal busy, please wait a moment and try again': 'Terminal ocupada, por favor espere un momento e inténtelo de nuevo',
    'Terminal not found, ask in store or try later': 'Terminal no encontrado, pregunte en tienda o inténtelo más tarde',
    'Privacy Policies': 'Políticas de Privacidad',
    'Terms and Conditions.': 'Términos y Condiciones.',
    'Incorrect employee number, please try again.': 'Número de empleado incorrecto, por favor intenta nuevamente.',
    'Loading menu': 'Cargando Menú',
    'Logout': 'Cerrar Sesión',
    'Creating order': 'Creando órden',
    'Wrong password': 'Contraseña incorrecta',
    'Are you sure you want logged out?': '¿Estás seguro de cerrar sesión?',
    'You have items in your order, if you log out now the information will be lost.': 'Tienes artículos en tu orden, si sales ahora se perderá la información',
    'Insufficient credits': 'Créditos insuficientes',
    'This item exceeds your available credits for this day': 'Este artículo excede tu saldo disponible para este día',
    'Choose payment behavior': 'Elige el comportamiento de pago',
    'Terminal Payment Instruction Result': 'Resultado de la instrucción de pago terminal',
    'Response Result': 'Resultado de la respuesta',
    'Timeout to response': 'Tiempo de espera para responder',
    'Create payment behavior': 'Crear comportamiento de pago',
    'Terminal not configured': 'La terminal no esta configurada',
    'QpayTerminalPaymentInstructionResults.TERMINAL_NOT_FOUND': 'TERMINAL NO ENCONTRADA',
    'QpayTerminalPaymentInstructionResults.UNKNOWN': 'DESCONOCIDO',
    'QpayTerminalPaymentInstructionResults.INSTRUCTION_RECEIVED': 'INSTRUCCION RECIBIDA',
    'QpayTerminalPaymentInstructionResults.TERMINAL_BUSY': 'TERMINAL OCUPADA',
    'Payment Cancelled': 'Pago cancelado',
    'You already have a product added to your cart for the selected day': 'Ya tienes un producto agregado a tu carrito para el día seleccionado',

    'Start over': 'Volver a empezar',
    'Yes, start over': 'Sí, volver a empezar',
    'Are you sure you want to start over?': '¿Estás seguro de volver a empezar?',
    'You have items in your order, if you start over they will be lost': 'Tienes artículos en tu orden, si vuelves a empezar se perderán',
    'First Name': 'Nombre',
    'Last Name': 'Apellido',
    'Subtotal': 'Subtotal',
    'Promotions': 'Promociones',

    'EN': 'ENG',
    'ES': 'ESP',

    'OrderTypes.DELIVERY_ORDER': 'Envío',
    'OrderTypes.TABLE_ORDER': 'Comer aquí',
    'OrderTypes.SELF_SERVICE_ORDER': 'Comer aquí',
    'OrderTypes.TAKE_AWAY_ORDER': 'Para llevar',
    'only @orderTypes': 'solo @orderTypes',
};
