import * as React from 'react';

export function MoneyIcon({ title, width, height }: Props): React.ReactElement {
    return (
        <svg width={width ?? '116'} height={height ?? '116'} viewBox='0 0 116 116' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <title>{title}</title>
            <circle cx='58.0002' cy='58.0001' r='57.9998' fill='#CBF7E9' />
            <path
                d='M59.5 37.3333V78.6667M49.3333 68.9602L52.3122 71.2301C56.2806 74.2578 62.7161 74.2578 66.6878 71.2301C70.6596 68.2024 70.6596 63.2976 66.6878 60.2699C64.7053 58.7543 62.1027 58 59.5 58C57.0431 58 54.5861 57.2422 52.7121 55.7301C48.9639 52.7024 48.9639 47.7976 52.7121 44.7699C56.4602 41.7422 62.5398 41.7422 66.2879 44.7699L67.6943 45.9066M90 58C90 62.071 89.2111 66.1021 87.6783 69.8632C86.1456 73.6243 83.8989 77.0417 81.0668 79.9203C78.2346 82.7989 74.8723 85.0824 71.1718 86.6403C67.4714 88.1982 63.5053 89 59.5 89C55.4947 89 51.5286 88.1982 47.8282 86.6403C44.1277 85.0824 40.7654 82.7989 37.9332 79.9203C35.1011 77.0417 32.8544 73.6243 31.3217 69.8632C29.7889 66.1021 29 62.071 29 58C29 49.7783 32.2134 41.8933 37.9332 36.0797C43.6531 30.2661 51.4109 27 59.5 27C67.5891 27 75.3469 30.2661 81.0668 36.0797C86.7866 41.8933 90 49.7783 90 58Z'
                stroke='#26B3A2'
                strokeWidth='4'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}

type Props = {
    title?: string;
    width?: string;
    height?: string;
};
