import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { Button } from 'src/components/Button';
import { PrivacyPolicyDialog } from 'src/components/dialog/PrivacyPolicyDialog';
import { TermsAndConditionsDialog } from 'src/components/dialog/TermsAndConditionsDialog';
import { translate } from 'src/i18n/translate';
import { classNames } from 'src/utils/react/classNames';

export function TermsAndConditions({ classes: classesProp }: Props): React.ReactElement {
    const classes = useStyles();

    const [privacyPolicyDialogState, setPrivacyPolicyDialogState] = useState(false);
    const [termsAndConditionsDialogState, setTermsAndConditionsDialogState] = useState(false);

    const openPrivacyPolicyDialog = () => setPrivacyPolicyDialogState(true);
    const openTermsAndConditionsDialog = () => setTermsAndConditionsDialogState(true);

    return (
        <div className={classNames(classes.termsAndConditions, classesProp?.container)}>
            <PrivacyPolicyDialog open={privacyPolicyDialogState} onClose={() => setPrivacyPolicyDialogState(false)} />
            <TermsAndConditionsDialog open={termsAndConditionsDialogState} onClose={() => setTermsAndConditionsDialogState(false)} />
            <p>
                {translate('By continuing, you are accepting the ')}
                <Button text onClick={openPrivacyPolicyDialog} classes={{ button: classes.termsAndConditionsButton }}>
                    {translate('Privacy Policies')}
                </Button>
                {translate(' and ')}
                <Button text onClick={openTermsAndConditionsDialog} classes={{ button: classes.termsAndConditionsButton }}>
                    {translate('Terms and Conditions.')}
                </Button>
            </p>
        </div>
    );
}

type Props = {
    classes?: {
        container: string;
    };
};

const useStyles = makeStyles((theme) => ({
    termsAndConditionsButton: {
        display: 'inline-block',
        fontSize: '1.5vh',
        fontFamily: theme.typography.semiBold,
        margin: 0,
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: 'unset',
    },
    termsAndConditions: {
        fontSize: '1.5vh',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        color: '#324054',
        fontFamily: theme.typography.regular,
        position: 'absolute',
        bottom: '3%',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
    },
}));
