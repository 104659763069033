export const en: {
    [key: string]: string;
} = {
    'Days.Mo': 'Monday',
    'Days.Tu': 'Tuesday',
    'Days.We': 'Wednesday',
    'Days.Th': 'Thursday',
    'Days.Fr': 'Friday',
    'Days.Sa': 'Saturday',
    'Days.Su': 'Sunday',

    'Verb.Order': 'Order',
    'OrderTypes.DELIVERY_ORDER': 'Delivery',
    'OrderTypes.TABLE_ORDER': 'Eat here',
    'OrderTypes.SELF_SERVICE_ORDER': 'Eat here',
    'OrderTypes.TAKE_AWAY_ORDER': 'Take away',

    'QpayTerminalPaymentInstructionResults.TERMINAL_NOT_FOUND': 'TERMINAL NOT FOUND',
    'QpayTerminalPaymentInstructionResults.UNKNOWN': 'UNKNOWN',
    'QpayTerminalPaymentInstructionResults.INSTRUCTION_RECEIVED': 'INSTRUCTION RECEIVED',
    'QpayTerminalPaymentInstructionResults.TERMINAL_BUSY': 'TERMINAL BUSY',
};
