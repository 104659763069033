import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { findCustomerEmployeeApi } from 'src/api/pidedirectokiosk/customer/findCustomerEmployeeApi';
import { Text } from 'src/components/app/Text';
import { Button } from 'src/components/Button';
import { Form } from 'src/components/form/Form';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { SubscribedPage } from 'src/components/page/SubscribedPage';
import { TermsAndConditions } from 'src/components/TermsAndConditions';
import { OrderTypes } from 'src/constants/OrderType';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { usePageLoader } from 'src/services/usePageLoader';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function ValidateEmployeePage(): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const { showLoader, closeLoader } = usePageLoader();

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const setKioskOrderType = useAction(appReducer.actions.setKioskOrderType);
    const setCustomerEmployee = useAction(appReducer.actions.setCustomerEmployee);

    const onSubmit = async (form: any) => {
        if (!restaurantId) return;

        setLoading(true);
        showLoader(translate('Validating Employee Number'));
        const response = await findCustomerEmployeeApi({
            restaurantId: restaurantId,
            employeeNumberAtCompany: form.employeeNumber,
        });
        closeLoader();
        setLoading(false);

        if (!response.ok) {
            setError(translate('Incorrect employee number, please try again.'));
            return;
        }

        setKioskOrderType(OrderTypes.PICKUP_STATION_ORDER);
        setCustomerEmployee(response.data);
    };

    if (loading) return <></>;

    return (
        <SubscribedPage title={'Validate Employee data'}>
            <Form className={classes.container} form={form} onSubmit={onSubmit}>
                <h1 className={classes.title}>{translate('Identify yourself to order')}</h1>
                <FormNumberField
                    name='employeeNumber'
                    label={translate('Employee Number')}
                    required
                    classes={{ input: classes.input, label: classes.label, helperText: classes.helperText, container: classes.inputWrapper, inputContainer: classes.inputContainer }}
                />
                {error && <Text error>{error}</Text>}
                <div className={classes.buttonsContainer}>
                    <Button classes={{ button: classes.button }} type='submit' disabled={form.formState.isSubmitting}>
                        <p className={classes.text}>{translate('Continue')}</p>
                    </Button>
                </div>
            </Form>
            <TermsAndConditions />
        </SubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        padding: '0 18vw',
    },
    title: {
        fontFamily: theme.typography.semiBold,
        fontSize: '8vw',
        textAlign: 'center',
        color: 'black',
        width: '90%',
        margin: '0 auto',
    },
    text: {
        fontFamily: theme.typography.bold,
        fontSize: '3.5vw',
        textAlign: 'center',
        color: 'white',
        textTransform: 'none',
    },
    buttonsContainer: {
        display: 'flex',
        width: '100%',
        margin: '0 auto',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        top: '5vh',
        gap: '4vw',
    },
    button: {
        width: '100%',
        height: '6vh',
        borderRadius: 12,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        flexDirection: 'column',
        border: 0,
    },
    buttonRoot: {
        padding: 0,
        width: 'fit-content',
        minWidth: 0,
        borderRadius: '2vw',
        margin: '0 2vw',
        flexShrink: 0,
    },
    icon: {
        height: '10vw',
        pointerEvents: 'none',
    },
    input: {
        width: '80vw',
        height: '4vh',
        borderWidth: '2px',
        fontSize: '3.5vw',
        padding: '2.5vh 2vw',
        borderRadius: '0vw',
    },
    label: {
        fontSize: '3vw',
        marginBottom: '1vh',
    },
    error: {
        color: '#C7413A',
        fontFamily: theme.typography.regular,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
    },
    helperText: {
        fontSize: '3vw',
    },
    inputWrapper: {
        marginTop: '5vh',
        width: '100%',
    },
    inputContainer: {
        marginTop: '1vh',
        width: '100%',
        height: '6vh',
        borderRadius: 8,
    },
}));
