import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Text } from 'src/components/app/Text';

export function CircularLoadingIndicator({ text }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.circularLoading}>
                <div className={classes.circularLoadingIndicator}></div>
                {text && <Text>{text}</Text>}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        zIndex: 9999,
        backgroundColor: 'white',
    },
    circularLoading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    circularLoadingIndicator: {
        width: '100px', // Ajusta el tamaño según sea necesario
        height: '100px', // Ajusta el tamaño según sea necesario
        borderRadius: '50%',
        border: '4px solid #D9D9D9',
        borderTop: `4px solid ${theme.palette.primary.main}`,
        animation: '$spin 1s linear infinite',
    },
    loaderText: {
        fontFamily: theme.typography.semiBold,
        color: '#000',
        fontSize: '2.5rem',
        marginTop: '1rem',
    },
    '@keyframes spin': {
        '0%': { transform: 'rotate(0deg)' },
        '100%': { transform: 'rotate(360deg)' },
    },
}));

type Props = {
    text?: string;
};
