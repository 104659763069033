import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { createOrderApi } from 'src/api/pidedirectokiosk/order/createOrderApi';
import { type OrderVm } from 'src/api/pidedirectokiosk/types/OrderVm';
import { SubscribedPage } from 'src/components/page/SubscribedPage';
import { SECONDS } from 'src/constants/TimeUnit';
import { translate } from 'src/i18n/translate';
import checkIcon from 'src/images/check.svg';
import { appReducer } from 'src/reducers/appReducer';
import { useClearKiosk } from 'src/services/useClearKiosk';
import { OrderPaymentVm } from 'src/types/OrderPaymentVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { removeDuplicates } from 'src/utils/array/removeDuplicates';
import { mapCartItemToOrderItem } from 'src/utils/order/mapCartItemToOrderItem';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function OrderCompletedPage(): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState<OrderVm>();

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const orderType = useSelector((state) => state.app.orderType);
    const usedPromotions = useSelector((state) => state.app.payment?.usedPromotions);
    const paymentMethod = useSelector((state) => state.app.paymentMethod);
    const firstName = useSelector((state) => state.app.firstName);
    const lastName = useSelector((state) => state.app.lastName);
    const mobileNumber = useSelector((state) => state.app.mobileNumber);
    const email = useSelector((state) => state.app.email);
    const sendTicketOnline = useSelector((state) => state.app.sendTicketOnline);
    const items = useSelector((state) => state.app.items);
    const orderPayment = useSelector<OrderPaymentVm | undefined>((state) => state.app.orderPayment);

    const clearKiosk = useClearKiosk();
    const goToPreviousKioskOrderStep = useAction(appReducer.actions.goToPreviousKioskOrderStep);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (!loading) {
            timeout = setTimeout(() => {
                clearKiosk();
            }, 5 * SECONDS);
        }
        return () => window.clearTimeout(timeout);
    }, [loading]);

    useEffect(() => {
        createOrder();
    }, []);

    const createOrder = async () => {
        if (!restaurantId || !orderType || !paymentMethod) return;
        const response = await createOrderApi({
            restaurantId,
            promotionIds: removeDuplicates(usedPromotions?.map((usedPromotion) => usedPromotion.promotionId)),
            orderType,
            paymentMethod,
            firstName,
            lastName,
            mobileNumber,
            email,
            orderItems: items?.map(mapCartItemToOrderItem),
            sendTicketOnline,
            pickupTime: new Date(),
            payments: orderPayment ? [orderPayment] : [],
        });

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            goToPreviousKioskOrderStep();
            return;
        }
        setLoading(false);
        setOrder(response.data?.[0]);
    };

    return (
        <SubscribedPage title={'Kiosk order completed'}>
            <div className={classes.container} onClick={clearKiosk}>
                {!loading && <h1 className={classes.title}>{translate('Thanks for you preference')}</h1>}
                {loading && (
                    <div className={classes.iconBackground}>
                        <div className={classes.hours}></div>
                        <div className={classes.minutes}></div>
                    </div>
                )}
                {!loading && <img src={checkIcon} className={classes.icon} />}
                {!loading && (
                    <>
                        <h2 className={classes.subtitle}>{translate('Order #@dailyOrderId', { dailyOrderId: order?.dailyOrderId })}</h2>
                        <p className={classes.text}>{translate('You will receive a message when your order is ready')}</p>
                    </>
                )}
                {loading && <h2 className={classes.subtitle}>{translate('We are creating your order')}</h2>}
            </div>
        </SubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        fontFamily: theme.typography.bold,
        fontSize: '7vw',
        textAlign: 'center',
        width: '90%',
        margin: '0 auto',
        color: '#2C374A',
    },
    icon: {
        margin: '5vh 0',
    },
    subtitle: {
        fontFamily: theme.typography.bold,
        fontSize: '4vw',
        textAlign: 'center',
        width: '90%',
        margin: '0 auto',
        color: '#2C374A',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: '3.5vw',
        textAlign: 'center',
        width: '70%',
        margin: '0px auto',
        marginTop: 40,
        color: '#2C374A',
    },
    iconBackground: {
        backgroundColor: '#0A8972',
        borderRadius: '15vw',
        width: '25vw',
        height: '25vw',
        marginBottom: '5vh',
        position: 'relative',
    },
    hours: {
        width: '10vw',
        height: '3vw',
        borderRadius: '5vw',
        backgroundColor: 'white',
        top: '13.5vw',
        left: '11vw',
        margin: 0,
        position: 'absolute',
        transformOrigin: 'top left',
        transform: 'rotate(-90deg)',
    },
    minutes: {
        width: '8vw',
        height: '3vw',
        borderRadius: '5vw',
        backgroundColor: 'white',
        top: '10.5vw',
        left: '12.5vw',
        margin: 0,
        position: 'absolute',
        transformOrigin: 'top left',
        transform: 'rotate(45deg)',
    },
}));
