import { Day, Days } from 'src/constants/Day';

export function getNumberOfDayFromDay(day: Day): number {
    if (day === Days.MONDAY) return 0;
    if (day === Days.TUESDAY) return 1;
    if (day === Days.WEDNESDAY) return 2;
    if (day === Days.THURSDAY) return 3;
    if (day === Days.FRIDAY) return 4;
    if (day === Days.SATURDAY) return 5;
    if (day === Days.SUNDAY) return 6;

    return 0;
}
