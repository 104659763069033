import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { memo } from 'react';
import { Button } from 'src/components/Button';
import { MinusIcon } from 'src/icons/MinusIcon';
import { PlusIcon } from 'src/icons/PlusIcon';
import { useIsPickupStationUser } from 'src/services/useIsPickupStationUser';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';

export const ItemQuantitySpinner: React.ComponentType<Props> = memo<Props>(function KioskItemQuantitySpinner({ quantity, onSubtract, onAdd, classes: classesProp }: Props): React.ReactElement {
    const classes = useStyles();

    const isPickupStationUser = useIsPickupStationUser();

    const customerEmployee = useSelector((state) => state.app.customerEmployee);

    const allowoneProductPerDay = isPickupStationUser && customerEmployee?.oneProductPerDay;

    return (
        <div className={classNames(classes.container, allowoneProductPerDay ? classes.leftContainer : '')}>
            <Button classes={{ button: classNames(classes.button, classesProp?.button) }} onClick={onSubtract}>
                <MinusIcon width={'35'} height={'15'} />
            </Button>
            <span className={classNames(classes.quantity)}>{quantity}</span>
            {!allowoneProductPerDay && (
                <Button classes={{ button: classNames(classes.button, classesProp?.button) }} onClick={onAdd}>
                    <PlusIcon width={'35'} height={'90'} />
                </Button>
            )}
        </div>
    );
});

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        fontSize: '4vw',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '45vw',
    },
    leftContainer: {
        justifyContent: 'flex-end',
        width: '100%',
        alignItems: 'center',
        gap: 30,
    },
    button: {
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
        width: '6.5vw',
        aspectRatio: 1,
    },
    quantity: {
        fontFamily: theme.typography.bold,
        color: '#2C374A',
    },
}));

type Props = {
    quantity: number;
    onSubtract: () => void;
    onAdd: () => void;
    classes?: {
        button: string;
    };
};
