import { I18n } from 'aws-amplify';
import { Languages } from 'src/constants/Language';
import { getLanguageInLocalStorage } from 'src/localStorage/language/getLanguageInLocalStorage';
import { setLanguageInLocalStorage } from 'src/localStorage/language/setLanguageInLocalStorage';
import { appReducer } from 'src/reducers/appReducer';
import { useAction } from 'src/utils/react/useAction';

export function useClearKiosk() {
    const clearKiosk = useAction(appReducer.actions.clearKiosk);

    const language = getLanguageInLocalStorage();

    return () => {
        if (language !== Languages.ES) {
            I18n.setLanguage(Languages.ES);
            setLanguageInLocalStorage(Languages.ES);
        }
        clearKiosk();
    };
}
