import { RestaurantTypes } from 'src/constants/RestaurantType';
import { RestaurantVm } from 'src/types/RestaurantVm';
import { momentFromTimeString } from 'src/utils/date/momentFromTimeString';

export function isUnavailableDateTimeOrder(openedRestaurant?: RestaurantVm, pickupTimeOrder?: Date): boolean {
    if (!openedRestaurant || openedRestaurant.restaurantType !== RestaurantTypes.PICKUP_STATION || !openedRestaurant.pickupStationClosingTime) {
        return false;
    }

    if (!pickupTimeOrder) {
        return false;
    }

    const lastOrderTime = momentFromTimeString(openedRestaurant.pickupStationClosingTime ?? '00:00', openedRestaurant.timeZone, pickupTimeOrder);

    return new Date() > lastOrderTime.toDate();
}
