import { pidedirectokioskApiMethod } from 'src/api/config/pidedirectokioskApiMethod';
import { type OrderVm } from 'src/api/pidedirectokiosk/types/OrderVm';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { OrderType } from 'src/constants/OrderType';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import { CompanyId, CustomerId, EmailAddress, PromotionId, RestaurantId } from 'src/types/Id';
import type { OrderItemVm } from 'src/types/OrderItemVm';
import type { OrderPaymentVm } from 'src/types/OrderPaymentVm';

export async function createOrderApi(request: CreateOrderApiRequest): ApiSauceResponse<Array<OrderVm>> {
    return pidedirectokioskApiMethod('order/createOrderApi', request);
}

type CreateOrderApiRequest = {
    restaurantId: RestaurantId;
    customerId?: CustomerId;
    companyId?: CompanyId;
    promotionIds?: Array<PromotionId>;
    password?: string;
    orderType: OrderType;
    paymentMethod: PaymentMethod;
    firstName?: string;
    lastName?: string;
    mobileNumber?: string;
    email?: EmailAddress;
    orderItems: Array<OrderItemVm>;
    sendTicketOnline?: boolean;
    pickupTime: Date;
    payments: Array<OrderPaymentVm>;
};
