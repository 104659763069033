import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect } from 'react';
import { GoBackButton } from 'src/components/button/GoBackButton';
import { SubscribedPage } from 'src/components/page/SubscribedPage';
import { OrderSteps } from 'src/constants/OrderStep';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { CheckoutItem } from 'src/scenes/checkout/CheckoutItem';
import { CheckoutSummary } from 'src/scenes/checkout/CheckoutSummary';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function CheckoutPage(): React.ReactElement {
    const classes = useStyles();

    const items = useSelector((state) => state.app.items);

    const setOrderStep = useAction(appReducer.actions.setOrderStep);

    useEffect(() => {
        if (items.length === 0) {
            setOrderStep(OrderSteps.CREATE_ORDER);
        }
    }, [items]);

    return (
        <SubscribedPage title={'Kiosk checkout'}>
            <div className={classes.container}>
                <GoBackButton />
                <h1 className={classes.title}>{translate('My Order')}</h1>
                <div className={classes.itemsContainer}>
                    {items.map((item) => (
                        <CheckoutItem key={item.key} item={item} />
                    ))}
                </div>
                <CheckoutSummary />
            </div>
        </SubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '95vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 4vw',
        paddingTop: 'max(80px, 10vh)',
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'flex-start',
    },
    title: {
        fontFamily: theme.typography.semiBold,
        fontSize: '6vw',
        width: '100%',
        marginBottom: 20,
        color: '#2C374A',
        marginTop: '3vh',
    },
    itemsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        flexGrow: 1,
        gap: 40,
    },
}));
