import * as React from 'react';
import { useEffect } from 'react';
import { getKioskMenuApi } from 'src/api/pidedirectokiosk/getKioskMenuApi';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { usePageLoader } from 'src/services/usePageLoader';
import { useAction } from 'src/utils/react/useAction';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { requireValue } from 'src/utils/require/requireValue';

export function MenuLoader({ children }: Props): React.ReactElement {
    const { showLoader, closeLoader } = usePageLoader();

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const kioskMenuInState = useSelector((state) => state.app.kioskMenu);

    const setKioskMenu = useAction(appReducer.actions.setKioskMenu);
    const setSelectedMenu = useAction(appReducer.actions.setSelectedMenu);
    const setPromotions = useAction(appReducer.actions.setPromotions);

    const [loading, kioskMenu] = useLoadApi(
        getKioskMenuApi,
        { restaurantId: requireValue(restaurantId) },
        { dependencies: [restaurantId], requiredValues: [kioskMenuInState ? null : !!kioskMenuInState] },
    );

    useEffect(() => {
        if (kioskMenu) {
            setKioskMenu(kioskMenu);
            setSelectedMenu(kioskMenu?.menus[0]);
            setPromotions(kioskMenu?.promotions);
        }
    }, [kioskMenu]);

    useEffect(() => {
        if (loading) return showLoader(translate('Loading menu'));

        if (kioskMenu) closeLoader();
    }, [loading]);

    return <>{children}</>;
}

type Props = {
    children: React.ReactNode;
};
