import * as React from 'react';

export function ErrorIcon({ title, width, height }: Props): React.ReactElement {
    return (
        <svg width={width ?? '190'} height={height ?? '190'} viewBox='0 0 190 190' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <title>{title}</title>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M95 0C42.5308 0 0 42.5308 0 95C0 147.469 42.5308 190 95 190C147.469 190 190 147.469 190 95C190 42.5308 147.469 0 95 0ZM78.241 67.9128C77.572 67.1948 76.7652 66.619 75.8688 66.2196C74.9724 65.8202 74.0048 65.6054 73.0235 65.5881C72.0423 65.5708 71.0677 65.7513 70.1578 66.1188C69.2478 66.4863 68.4212 67.0334 67.7273 67.7273C67.0334 68.4212 66.4863 69.2478 66.1188 70.1578C65.7513 71.0677 65.5708 72.0423 65.5881 73.0235C65.6054 74.0048 65.8202 74.9724 66.2196 75.8688C66.619 76.7652 67.1948 77.572 67.9128 78.241L84.6718 95L67.9128 111.759C67.1948 112.428 66.619 113.235 66.2196 114.131C65.8202 115.028 65.6054 115.995 65.5881 116.976C65.5708 117.958 65.7513 118.932 66.1188 119.842C66.4863 120.752 67.0334 121.579 67.7273 122.273C68.4212 122.967 69.2478 123.514 70.1578 123.881C71.0677 124.249 72.0423 124.429 73.0235 124.412C74.0048 124.395 74.9724 124.18 75.8688 123.78C76.7652 123.381 77.572 122.805 78.241 122.087L95 105.328L111.759 122.087C112.428 122.805 113.235 123.381 114.131 123.78C115.028 124.18 115.995 124.395 116.976 124.412C117.958 124.429 118.932 124.249 119.842 123.881C120.752 123.514 121.579 122.967 122.273 122.273C122.967 121.579 123.514 120.752 123.881 119.842C124.249 118.932 124.429 117.958 124.412 116.976C124.395 115.995 124.18 115.028 123.78 114.131C123.381 113.235 122.805 112.428 122.087 111.759L105.328 95L122.087 78.241C122.805 77.572 123.381 76.7652 123.78 75.8688C124.18 74.9724 124.395 74.0048 124.412 73.0235C124.429 72.0423 124.249 71.0677 123.881 70.1578C123.514 69.2478 122.967 68.4212 122.273 67.7273C121.579 67.0334 120.752 66.4863 119.842 66.1188C118.932 65.7513 117.958 65.5708 116.976 65.5881C115.995 65.6054 115.028 65.8202 114.131 66.2196C113.235 66.619 112.428 67.1948 111.759 67.9128L95 84.6718L78.241 67.9128Z'
                fill='#F64F4F'
            />
        </svg>
    );
}

type Props = {
    title?: string;
    width?: string;
    height?: string;
};
