import MomentUtils from '@date-io/moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { App } from 'src/App';
import { history } from 'src/config/history';
import { persistor, store } from 'src/config/store';
import 'src/index.css';
import 'src/letseatfont.css';
import 'typeface-roboto';
import { PersistGate } from 'redux-persist/integration/react';
import { CheckForUpdates } from 'src/components/app/CheckForUpdates';
import { ThemeProvider } from 'src/components/page/ThemeProvider';
import { ConfirmDialogProvider } from 'src/context/ConfirmDialogContext';
import { LoaderProvider } from 'src/context/LoaderContext';

export function AppRoot(): React.ReactElement {
    return (
        <Provider store={store}>
            <LoaderProvider>
                <ConfirmDialogProvider>
                    <PersistGate loading={null} persistor={persistor}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <ThemeProvider>
                                <Router history={history}>
                                    <div id='app-container'>
                                        <CssBaseline />
                                        <CheckForUpdates />
                                        <App />
                                    </div>
                                </Router>
                            </ThemeProvider>
                        </MuiPickersUtilsProvider>
                    </PersistGate>
                </ConfirmDialogProvider>
            </LoaderProvider>
        </Provider>
    );
}
