import { makeStyles } from '@material-ui/core';
import { Text } from '@pidedirecto/ui';
import { useTheme } from '@pidedirecto/ui/hooks';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { OrderSteps } from 'src/constants/OrderStep';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function CheckoutSummary(): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const total = useSelector((state) => state.app.payment?.total);
    const subtotal = useSelector((state) => state.app.payment?.subtotal);
    const promotionsDiscount = useSelector((state) => state.app.payment?.promotionsDiscount);

    const setOrderStep = useAction(appReducer.actions.setOrderStep);

    return (
        <div className={classes.container}>
            <div className={classes.row}>
                <Text className={classes.summaryText}>{translate('Subtotal')}</Text>
                <Text className={classes.summaryText}>{formatAsCurrencyNumber(subtotal)}</Text>
            </div>
            {!!promotionsDiscount && (
                <div className={classes.row}>
                    <Text className={classes.summaryText}>{translate('Promotions')}</Text>
                    <Text className={classes.summaryText}>-{formatAsCurrencyNumber(promotionsDiscount)}</Text>
                </div>
            )}
            <div className={classes.divider} style={{ borderBottom: `2px solid ${theme.palette?.border?.primary}` }}></div>
            <div className={classes.row}>
                <Text weight='bold' className={classes.totalLabel}>
                    {translate('Total')}
                </Text>
                <Text weight='bold' className={classes.total}>
                    {formatAsCurrencyNumber(total)}
                </Text>
            </div>
            <div className={classes.row} style={{ justifyContent: 'flex-end', marginTop: '2vh' }}>
                <Button classes={{ button: classes.button }} onClick={() => setOrderStep(OrderSteps.CUSTOMER_INFO)}>
                    {translate('Continue')}
                </Button>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 10,
        minHeight: 'max(240px, 10vh)',
        marginTop: 'max(40px, 2vh)',
    },
    row: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    divider: {
        width: '100%',
        margin: '10px 0 10px 0',
    },
    totalLabel: {
        fontSize: '4vw',
        fontFamily: theme.typography.regular,
        marginRight: 10,
        color: '#2C374A',
    },
    total: {
        fontSize: '4vw',
        fontFamily: theme.typography.bold,
        color: '#2C374A',
    },
    button: {
        fontSize: '4vw',
        paddingTop: '2vw',
        paddingBottom: '2vw',
        paddingLeft: '4vw',
        paddingRight: '4vw',
        borderRadius: 12,
    },
    summaryText: {
        fontSize: '4vw',
    },
}));
