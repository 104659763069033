import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import moment from 'moment-timezone';
import * as React from 'react';
import { Text } from 'src/components/app/Text';
import { Button } from 'src/components/Button';
import { Day, Days } from 'src/constants/Day';
import { TimeZones } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import { CheckOutlineIcon } from 'src/icons/CheckOutlineIcon';
import { appReducer } from 'src/reducers/appReducer';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { useConfirmDialog } from 'src/services/useConfirmDialog';
import { useIsPickupStationUser } from 'src/services/useIsPickupStationUser';
import { getDayFromNumberDay } from 'src/utils/date/getDayFromNumberDay';
import { getNumberOfDayFromDay } from 'src/utils/order/getNumberOfDayFromDay';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { upperCase } from 'src/utils/string/upperCase';

export function WeekSummary(): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const isPickupStationUser = useIsPickupStationUser();
    const confirmDialog = useConfirmDialog();

    const timeZone = useSelector((state) => state.app.restaurant?.timeZone);
    const items = useSelector((state) => state.app.items);
    const selectedDay = useSelector((state) => state.app.selectedDay);
    const kioskMenu = useSelector((state) => state.app.kioskMenu);
    const customerEmployee = useSelector((state) => state.app.customerEmployee);

    const setSelectedDay = useAction(appReducer.actions.setSelectedDay);
    const setSelectedMenu = useAction(appReducer.actions.setSelectedMenu);

    const days: Array<Day> = Object.values(Days);

    const handleSelectMenuDay = async (day: Day) => {
        const menuDay = kioskMenu?.menus?.[getNumberOfDayFromDay(day)];

        if (!menuDay) {
            await confirmDialog({
                title: translate('No menu to display on the selected day'),
                buttonText: translate('Accept'),
                variant: 'notification',
            });
            return;
        }

        setSelectedDay(day);
        setSelectedMenu(menuDay);
    };

    if (!isPickupStationUser) return <></>;

    return (
        <div className={classes.container}>
            {days?.map((day) => {
                const dayLabel = translate(`Days.${day}`);
                const hasItemInDay = items?.some((item) => item.pickupTime && getDayFromNumberDay(moment.tz(item.pickupTime, timeZone ?? TimeZones.AMERICA_MONTERREY).isoWeekday()) === day);
                const isSelectedDay = selectedDay === day;
                const creditsByDay = customerEmployee?.days?.find((gift) => gift.day === day);
                const availableCredits = BigNumber(creditsByDay?.gift?.credits ?? 0)
                    .minus(creditsByDay?.gift?.usedCredits ?? 0)
                    .toNumber();

                return (
                    <Button key={day} text onClick={() => handleSelectMenuDay(day)} classes={{ button: classNames(classes.button, { [classes.selectedButtonDay]: isSelectedDay }) }}>
                        <div>{upperCase(dayLabel.slice(0, 3))}</div>
                        {hasItemInDay && <CheckOutlineIcon title={dayLabel} color={isSelectedDay ? 'white' : hasItemInDay ? theme.palette.primary.main : 'transparent'} />}
                        {!hasItemInDay && <Text style={{ color: isSelectedDay ? 'white' : '#8A95A5' }}>{formatAsCurrencyNumber(availableCredits)}</Text>}
                    </Button>
                );
            })}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'fixed',
        left: 0,
        top: '10vh',
        width: '100%',
        height: '8vh',
        padding: '1.5vw',
        color: '#8A95A5',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(2vw, 1fr))',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#F6F8F9',
    },
    button: {
        fontSize: '2.5vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: 12,
        fontFamily: theme.typography.bold,
        color: '#8A95A5',
        paddingTop: '1vh',
        paddingBottom: '1vh',
        paddingLeft: '2vh',
        paddingRight: '2vh',
        borderRadius: 20,
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    selectedButtonDay: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },
}));
