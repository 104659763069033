import { BigNumber } from 'bignumber.js';
import { CartItemVm } from 'src/types/CartItemVm';
import { MenuItemId, PromotionId } from 'src/types/Id';
import { PromotionVm } from 'src/types/PromotionVm';
import { Payment, Request } from 'src/utils/cart/createPaymentDistribution';
import { sum } from 'src/utils/reduce/sum';

export function calculateBuyOneGetOnePromotionDiscount(payment: Payment, request: Request, promotion: PromotionVm): void {
    if (!promotion.menuItemsQuantityToGet) return;

    const orderItems = getOrderItemsAffectedByPromotion(request, promotion);
    if (!orderItems.length) return;

    const promotionDiscount = getPromotionDiscount(request, promotion);

    const currentUsedPromotions = payment.usedPromotions ?? [];
    payment.usedPromotions = currentUsedPromotions.concat(getUsedPromotions(request, promotion));
    payment.promotionsDiscount = BigNumber(payment.promotionsDiscount ?? 0)
        .plus(promotionDiscount)
        .toString();
    payment.total = BigNumber(payment.total)
        .minus(promotionDiscount ?? 0)
        .toString();
}

function getOrderItemsAffectedByPromotion(request: Request, promotion: PromotionVm): Array<CartItemVm> {
    const orderItems = request.orderItems.filter((orderItem) => promotion.menuItemIds.includes(orderItem.menuItemId));

    const orderItemsSortedByPrice = orderItems.sort((orderItemA, orderItemB) => {
        return BigNumber(orderItemA.promoUnitPrice ?? orderItemA.unitPrice)
            .minus(orderItemB.promoUnitPrice ?? orderItemB.unitPrice)
            .toNumber();
    });

    return orderItemsSortedByPrice;
}

function getPromotionDiscount(request: Request, promotion: PromotionVm): string {
    if (!promotion.menuItemsQuantityToGet) return '0';

    const orderItems = getOrderItemsAffectedByPromotion(request, promotion);
    const orderItemsQuantity = orderItems
        .map((orderItem) => orderItem.quantity)
        .reduce(sum, BigNumber(0))
        .toNumber();

    let promotionDiscount = '0';

    const timesPromotionUsed = Math.floor(orderItemsQuantity / promotion.menuItemsQuantityToGet);
    const freeItemsPerUsage = BigNumber(promotion.menuItemsQuantityToGet)
        .minus(promotion.menuItemsQuantityToPay ?? 0)
        .toNumber();
    let promotionFreeItems = BigNumber(timesPromotionUsed).multipliedBy(freeItemsPerUsage).toNumber();

    let currentOrderItemIndex = 0;
    while (promotionFreeItems > 0) {
        const orderItemQuantityFree = getAffectedOrderItemsQuantity(orderItems[currentOrderItemIndex], promotionFreeItems);

        const orderItemPriceDiscounted = BigNumber(orderItems[currentOrderItemIndex].promoUnitPrice ?? orderItems[currentOrderItemIndex].unitPrice).multipliedBy(orderItemQuantityFree);
        promotionDiscount = BigNumber(promotionDiscount).plus(orderItemPriceDiscounted).toString();

        currentOrderItemIndex++;
        promotionFreeItems -= orderItemQuantityFree;
    }

    return promotionDiscount;
}

function getUsedPromotions(request: Request, promotion: PromotionVm): Array<UsedPromotion> {
    if (!promotion.menuItemsQuantityToGet) return [];

    const orderItems = getOrderItemsAffectedByPromotion(request, promotion);
    const orderItemsQuantity = orderItems
        .map((orderItem) => orderItem.quantity)
        .reduce(sum, BigNumber(0))
        .toNumber();

    const timesPromotionUsed = Math.floor(orderItemsQuantity / promotion.menuItemsQuantityToGet);
    let promotionAffectedQuantityItems = BigNumber(timesPromotionUsed).multipliedBy(promotion.menuItemsQuantityToGet).toNumber();

    let currentOrderItemIndex = 0;
    const usedPromotions: Array<UsedPromotion> = [];
    while (promotionAffectedQuantityItems > 0) {
        const affectedOrderItemQuantity = getAffectedOrderItemsQuantity(orderItems[currentOrderItemIndex], promotionAffectedQuantityItems);

        usedPromotions.push({
            promotionId: promotion.promotionId,
            menuItemId: orderItems[currentOrderItemIndex].menuItemId,
            cartItemKey: orderItems[currentOrderItemIndex].key,
        });

        currentOrderItemIndex++;
        promotionAffectedQuantityItems -= affectedOrderItemQuantity;
    }

    return usedPromotions;
}

function getAffectedOrderItemsQuantity(orderItem: CartItemVm, orderItemsQuantityToAffect: number): number {
    if (BigNumber(orderItem.quantity).isLessThanOrEqualTo(orderItemsQuantityToAffect)) {
        return orderItem.quantity;
    }
    return orderItemsQuantityToAffect;
}

type UsedPromotion = {
    promotionId: PromotionId;
    menuItemId: MenuItemId;
    cartItemKey?: string;
};
